import type { Apps } from '@experiences/interfaces';
import { get } from '@experiences/util';

export const appsUrl = `/api/orchestrator/apps`;

export function getApps(accessToken: string, accountLogicalName: string) {
    return get<Apps[]>(appsUrl, {
        accessToken,
        urlParams: { accountLogicalName },
    });
}
