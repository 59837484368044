import type { IOrchestratorUser } from '@experiences/interfaces';
import { get } from '@experiences/util';

export function getCurrentOrchestratorUser(accessToken: string, tenantName: string, accountLogicalName: string) {
    return get<IOrchestratorUser>('/api/orchestrator/users', {
        accessToken,
        urlParams: {
            accountLogicalName,
            tenantName,
        },
    });
}
