import {
    HomePageEvent,
    logEvent,
} from '@experiences/telemetry';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {
    makeStyles,
    useTheme,
} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { persona } from '../../store/selectors';
import type { CarouselSlideProps } from './types/CarouselSlideProps';

const useStyles = makeStyles(() => createStyles({
    closeButton: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'start',
        height: '32px',
    },
}));

const CloseButton = ({
    idx, id, currSlide, setOpen, fullHeight = false,
}: CarouselSlideProps) => {
    const { formatMessage: translate } = useIntl();
    const dominantPersona = useSelector(persona);
    const theme = useTheme();

    const handleClick = useCallback(() => {
        logEvent('Carousel close button clicked', {
            name: HomePageEvent.CarouselCloseButtonClicked,
            code: '[IN-HP-51]',
        }, {
            Slide: idx + 1,
            SlideId: id,
            Persona: dominantPersona,
        });
        setOpen(false);
        localStorage.setItem('cloudRPA_carousel_enabled', 'false');

    }, [ dominantPersona, id, idx, setOpen ]);

    return (
        <IconButton
            sx={fullHeight ? {
                position: 'fixed',
                top: '15px',
                right: `calc(15px - ${String(idx * 101 + '%')})`,
                zIndex: '5',
                width: '20px',
                height: '20px',
                borderRadius: '8px',
                backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
                '&:hover': { backgroundColor: theme.palette.semantic.colorBackgroundDisabled },
            } : undefined}
            tabIndex={idx === currSlide ? 0 : -1}
            data-cy={`carousel-close-button-${idx}`}
            title={translate({ id: 'CLIENT_CLOSE' })}
            aria-label={translate({ id: 'CLIENT_CLOSE' })}
            size="small"
            onClick={handleClick}
        >
            <CloseIcon />
        </IconButton>
    );
};

export const CarouselCloseButton = ({
    idx, id, currSlide, setOpen, fullHeight = false,
}: CarouselSlideProps) => {
    const classes = useStyles();
    return (fullHeight ?
        <CloseButton
            fullHeight={fullHeight}
            id={id}
            idx={idx}
            currSlide={currSlide}
            setOpen={setOpen} />
        :
        <div
            className={classes.closeButton}
            data-cy='carousel-close-button-wrapper'
        >
            <CloseButton
                fullHeight={fullHeight}
                id={id}
                idx={idx}
                currSlide={currSlide}
                setOpen={setOpen} />
        </div>);
};
