import {
    useEffect,
    useState,
} from 'react';

import {
    decodeUriParams,
    encodeUriParams,
} from '../static/PathUtil';

function getSearchParams() {
    return new URLSearchParams(window.location.search);
}

export function useQueryState<T>(
    key: string,
    initialValue: T,
    reviver?: (k: string, value: string) => void
): [T, (value: T) => void] {

    const [ item, setItem ] = useState(() => {
        const queryStringValue = getSearchParams().get(key);

        if (queryStringValue) {
            try {
                const params = decodeUriParams<T>(queryStringValue, reviver);

                return params;
            } catch (e) {
                return initialValue;
            }
        }

        return initialValue;

    });

    useEffect(() => {
        const encodedValue = encodeUriParams(item as any);

        const searchParams = getSearchParams();
        searchParams.set(key, encodedValue);

        // using pushState instead of react-router to avoid re-rendering
        window.history.pushState({}, '', `?${searchParams.toString()}`);
    }, [ item, key ]);

    return [ item, setItem ];
}
