/* eslint-disable max-len */
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { IPagination } from '@experiences/interfaces';
import {
    logEvent,
    portalTelemetry,
    UsersEvent,
} from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    getDisplayName,
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PersonOutlined from '@mui/icons-material/PersonOutline';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import {
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
    ApTooltip,
} from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { UserType } from '../../common/constants/UsersConstant';
import useSimpleGroup from '../../common/hooks/SimpleGroup';
import { useCheckAADEnabled } from '../../common/hooks/useCheckAADEnabled';
import { useCheckSSOEnabled } from '../../common/hooks/useCheckSSOEnabled';
import { useLicenseExpired } from '../../common/hooks/useLicenseExpired';
import { useLoginProvider } from '../../common/hooks/useLoginProvider';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import type { IUserCIS } from '../../common/interfaces/cis/user';
import ExternalLicenseIcon from '../../images/icons/ExternalLicenseIcon';
import {
    checkIfOnlyUserInGroup,
    groupUrl,
} from '../../services/identity/GroupService';
import { createInviteUrl } from '../../services/identity/UserInvite';
import getUsersInPartition, {
    getUsersInPartitionWithLicenses,
    userPartitionUrl,
} from '../../services/identity/UserPartitionService';
import { deleteUsers } from '../../services/identity/UserService';
import {
    accountGlobalId,
    accountType,
    companyName,
    EnableUserLicensingSelector,
    isAdminSelector,
    isHostModeSelector,
    isUnlicensedSelector,
    organizationLanguage,
    userGlobalId,
} from '../../store/selectors';
import {
    SpacingToken,
    UiStack,
} from '../common/UiStack';
import { UserGroup } from '../common/UserGroups';
import type { IUserLicenses } from './interfaces/userLicense';
import ResendInvitationDialogBody from './subcomponents/ResendInvitationDialogBody';

type IUserWithLicenses = IUserCIS & Partial<IUserLicenses>;

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    userNameCell: { display: 'flex' },
    adminChip: {
        fontSize: '11px',
        fontWeight: 'bold',
        marginLeft: '8px',
        backgroundColor: theme.palette.semantic.colorBackgroundHigh,
        height: '18px',
        marginTop: '1px',
        '&:hover': { backgroundColor: theme.palette.semantic.colorBackgroundHigh },
        color: theme.palette.semantic.colorForegroundDeEmp,
    },
    azureDescription: {
        marginTop: '8px',
        marginBottom: '8px',
    },
    resendButton: {
        marginLeft: '8px',
        fontWeight: 600,
        color: theme.palette.semantic.colorPrimary,
        backgroundColor: 'transparent',
        borderWidth: '0px',
        cursor: 'pointer',
        '&:hover': { textDecoration: 'underline' },
    },
    licenseIcon: {
        width: '12px',
        height: '12px',
    },
    truncate: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
}));

const UsersCISPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const { getErrorMessage } = useGetErrorInfo();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const userId = useSelector(userGlobalId);
    const isAdmin = useSelector(isAdminSelector);
    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);
    const partitionGlobalId = useSelector(accountGlobalId);
    const orgLanguage = useSelector(organizationLanguage);
    const orgName = useSelector(companyName);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const isHostMode = useSelector(isHostModeSelector);
    const subscriptionPlan = useSelector(accountType);

    const {
        loading: groupLoading, getGroupsForUser,
    } = useSimpleGroup(true);

    const isSSOConnectionEnabled = useCheckSSOEnabled();
    const isLicenseExpired = useLicenseExpired();
    const isAADConnectionEnabled = useCheckAADEnabled();
    const createDialog = useShowDialog();
    const loginProvider = useLoginProvider();
    const setErrorMessage = useCentralErrorSetter();

    const {
        data: lastAdminInOrg, isValidating,
    } = useSWR(
        !process.buildConfigs.disableUsersLeaveOrg && isAdmin && !isSSOConnectionEnabled
            ? {
                url: `${groupUrl}/:id/checkIfOnlyUserInGroup`,
                partitionGlobalId,
                userGlobalId: userId,
                groupId: UserGroup.Administrators,
            }
            : null,
        checkIfOnlyUserInGroup,
    );

    const [ loading, setLoading ] = useState(false);
    const [ disabledEmails, setDisabledEmails ] = React.useState<string[]>([]);

    const dataUrl = useMemo(
        () => (EnableUserLicensing && isAdmin ? `${userPartitionUrl}/licenses` : `${userPartitionUrl}/users`),
        [ EnableUserLicensing, isAdmin ],
    );
    const [ pagination, setPagination ] = useState<IPagination>({
        top: 25,
        skip: 0,
        searchTerm: '',
        sortBy: 'Name',
        sortOrder: 'desc',
    });

    const {
        data, mutate, isLoading: loadingUsers,
    } = useSWR(
        {
            url: dataUrl,
            pagination,
            partitionGlobalId,
        },
        EnableUserLicensing && isAdmin ? getUsersInPartitionWithLicenses : getUsersInPartition
    );

    useEffect(() => {
        setLoading(groupLoading || isValidating);
    }, [ groupLoading, isValidating ]);

    const deleteUsersAsync = useCallback(
        async (users: IUserCIS[], deleteCurrentUser: boolean = false) => {
            try {
                if (users.length > 0) {
                    const userIds = users.map(user => user.id);
                    createNotification(translate({ id: 'CLIENT_USER_DELETED' }), notificationType.INPROGRESS);
                    await deleteUsers(partitionGlobalId, userIds, deleteCurrentUser, isHostMode);

                    createNotification(
                        translate({ id: 'CLIENT_SUCESSFULLY_DELETED_USERS' }),
                        notificationType.SUCCESS
                    );
                    logEvent('User(s) deleted', {
                        name: UsersEvent.Delete,
                        code: 'INV-USERS-05',
                    }, {
                        'SubscriptionType': subscriptionPlan,
                        'NoOfDeletedUsers': (users.length).toString(),
                    });
                    mutate();
                }
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            }
        },
        [ createNotification, translate, partitionGlobalId, isHostMode, subscriptionPlan, mutate, setErrorMessage, getErrorMessage ],
    );

    const openDeleteDialog = useCallback(
        async (items?: IUserCIS[] | IUserCIS) => {
            if (!items) {
                return;
            }
            const users: IUserCIS[] = Array.isArray(items) ? items : [ items ];

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_USER' }),
                body: `${translate(
                    { id: 'CLIENT_SHOULD_DELETE_USERS' },
                    { 0: users.length === 1 ? getDisplayName(users[0]) : translate({ id: 'CLIENT_SELECTED_USERS_TEXT' }) },
                )} ${translate({ id: 'CLIENT_MULTIPLE_CHECKED_USER_DELETE' })}`,
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
            });
            if (proceed) {
                const filteredUsers = users.filter(user => user.id !== userId);
                deleteUsersAsync(filteredUsers);
            }
        },
        [ translate, createDialog, deleteUsersAsync, userId ],
    );

    const openLeaveOrgDialog = useCallback(
        async (user: IUserCIS) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_LEAVE_ORG' }),
                body: `${translate({ id: 'CLIENT_SHOULD_LEAVE_ORG' }, { name: orgName })}${translate({ id: 'CLIENT_SINGLE_USER_DELETE_SUFFIX' })}`,
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_CONFIRM' }),
            });
            if (proceed) {
                try {
                    await deleteUsersAsync([ user ], true);
                    createNotification(translate({ id: 'CLIENT_USER_LEAVE_ORG' }), notificationType.INPROGRESS);
                    navigate('/portal_/logout');
                } catch (error) {
                    createNotification(translate({ id: 'CLIENT_USER_LEAVE_FAILED' }), notificationType.ERROR);
                }
            }
        },
        [ createDialog, translate, orgName, deleteUsersAsync, createNotification, navigate ],
    );

    const openAdminLeaveOrgDialog = useCallback(
        async (user: IUserCIS) => {
            if (!lastAdminInOrg) {
                openLeaveOrgDialog(user);
            } else {
                await createDialog({
                    title: translate({ id: 'CLIENT_UNABLE_LEAVE_ORG' }),
                    body: translate({ id: 'CLIENT_LEAVE_ORG_MIN_ONE_ADMIN' }),
                    icon: 'error',
                    primaryButtonText: 'OK',
                });
            }
        },
        [ translate, createDialog, openLeaveOrgDialog, lastAdminInOrg ],
    );

    const openResendInviteDialog = useCallback(async (user: IUserCIS) => {
        const inviteUrl = createInviteUrl(
            window.location.origin,
            partitionGlobalId,
            user.email,
            loginProvider,
            orgName,
        );

        await createDialog({
            title: translate({ id: 'CLIENT_RESEND_INVITE' }),
            customDialogContent: ResendInvitationDialogBody,
            customDialogContentProps: {
                user,
                orgLanguage,
                orgName,
                disabledEmails,
                setDisabledEmails,
                loginProvider,
                inviteUrl,
            },
        });
    }, [ createDialog, translate, partitionGlobalId, disabledEmails, orgLanguage, orgName, loginProvider ]);

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const ShowInviteUserButton = !DisableFeatureFedRamp;

    const EnableUserInvite = useMemo(
        () =>
            !isUnlicensedMode && !process.buildConfigs.disableUserInvite && ShowInviteUserButton && !DisableFeatureFedRamp,
        [ DisableFeatureFedRamp, ShowInviteUserButton, isUnlicensedMode ],
    );

    const handleInviteUser = useCallback(() => {
        navigate(`${getRoute(RouteNames.Users)}/invite`);
    }, [ getRoute, navigate ]);

    const handleBulkInviteUsers = useCallback(() => {
        portalTelemetry.trackTrace({
            message: `Clicked on Upload CSV button`,
            severityLevel: SeverityLevel.Information,
        });
        navigate(`${getRoute(RouteNames.Users)}/bulkinvite`);
    }, [ getRoute, navigate ]);

    const getLicenseEntry = useCallback((user: IUserWithLicenses) => {
        const text = user.useExternalLicense
            ? translate({ id: 'CLIENT_GROUPS_EXTERNAL_LICENSE' })
            : user.userBundleCodes?.map(ubl => translate({ id: `CLIENT_${ubl}` })).join(', ');

        const tooltip = user.inheritedFromGroup ? 'CLIENT_INHERIT_FROM_GROUP' : 'CLIENT_ALLOCATED_TO_USER';

        const Icon = user.useExternalLicense
            ? ExternalLicenseIcon
            : user.inheritedFromGroup
                ? PeopleOutlineOutlinedIcon
                : PersonOutlined;

        return {
            text,
            tooltip: text ? translate({ id: tooltip }) : '',
            Icon: text ? Icon : null,
        };
    }, [ translate ]);

    const onGridChange = useCallback(({
        filter, sort, pageIndex, pageSize, searchTerm,
    }: any) => {
        const newPaginationState: IPagination = {
            searchTerm: searchTerm ?? '',
            top: pageSize,
            skip: pageIndex * pageSize,
        };
        if (sort?.[0]?.field) {
            newPaginationState.sortBy = sort[0].field;
            newPaginationState.sortOrder = sort[0].direction === 'asc' ? 'asc' : 'desc';
        }
        setPagination(newPaginationState);
    }, [ setPagination ]);

    const onSortChange = useCallback((sort: any) => {
        setPagination(prev => ({
            ...prev,
            sortBy: sort.direction ? sort.field : undefined,
            sortOrder: sort.direction ? sort.direction : undefined,
        }));
    }, [ setPagination ]);

    const gridRowActions = useMemo(() => {
        const actions: JSX.Element[] = [];

        if (isAdmin && EnableUserLicensing && !isUnlicensedMode) {
            actions.push(
                <ApDataGridRowButton<IUserWithLicenses>
                    id='allocate-license'
                    key='allocate-license'
                    label={translate({ id: 'CLIENT_EDIT_USER_EXPLICIT_ALLOCATION' })}
                    icon='badge'
                    dataCy='edit-user-allocations-button'
                    onClick={(item) => navigate(getRoute(RouteNames.UsersUserEditLicenseAllocation), {
                        state: {
                            user: item,
                            previousLocation: location.pathname,
                        },
                    })}
                    disabled={isLicenseExpired}
                />
            );
        }

        if (isAdmin) {
            actions.push(<ApDataGridRowButton<IUserWithLicenses>
                id='edit'
                key="edit"
                label={translate({ id: 'CLIENT_EDIT' })}
                icon='edit'
                dataCy='edit-user-button'
                onClick={(item) => navigate(`${getRoute(RouteNames.Users)}/edit`, { state: { user: item } })}
            />);
        }

        if (isAdmin) {
            actions.push(<ApDataGridRowButton<IUserWithLicenses>
                id='delete'
                key='delete'
                label={translate({ id: 'CLIENT_DELETE' })}
                icon='delete'
                dataCy='delete-user-button'
                onClick={openDeleteDialog}
            />);
        }

        if (!process.buildConfigs.disableUsersLeaveOrg) {
            actions.push(
                <ApDataGridRowButton<IUserWithLicenses>
                    id='leave-org'
                    key='leave-org'
                    label={translate({ id: 'CLIENT_LEAVE_ORG' })}
                    icon='exit_to_app'
                    dataCy='leave-org-button'
                    disabled={row => row.id !== userId}
                    onClick={isAdmin ? openAdminLeaveOrgDialog : openLeaveOrgDialog}
                />
            );
        }

        return (
            <ApDataGridRowActions>
                {actions}
            </ApDataGridRowActions>
        );
    }, [
        EnableUserLicensing,
        getRoute,
        isAdmin,
        isLicenseExpired,
        isUnlicensedMode,
        navigate,
        openAdminLeaveOrgDialog,
        openDeleteDialog,
        openLeaveOrgDialog,
        translate,
        userId,
    ]);

    return (
        <>
            {isAADConnectionEnabled && (
                <UiText className={classes.azureDescription}>
                    {translate({
                        id:
              DisableFeatureFedRamp || process.buildConfigs.isOnPrem
                  ? 'CLIENT_AZURE_USERS_DESCRIPTION_NO_INVITE'
                  : 'CLIENT_AZURE_USERS_DESCRIPTION',
                    })}
                </UiText>
            )}
            <ApDataGridWrapper
                data={data?.results ?? []}
                refresh={mutate}
                loading={loading || loadingUsers}
                selectable
                onChange={onGridChange}
                sortChange={onSortChange}
                dataCy="users-grid">

                <ApDataGridHeader<IUserWithLicenses> search>
                    <ApDataGridHeaderButton<IUserWithLicenses>
                        id='delete-users'
                        key='delete-users'
                        type='action'
                        buttonType='mat-flat-button'
                        visible={isAdmin}
                        color="warn"
                        text={translate({ id: 'CLIENT_DELETE' })}
                        label={translate({ id: 'CLIENT_DELETE' })}
                        icon='delete'
                        onClick={openDeleteDialog}
                        dataCy='delete-users-button'
                    />
                    <ApDataGridHeaderButton<IUserWithLicenses>
                        id='add-user'
                        key='add-user'
                        type='main'
                        buttonType='mat-flat-button'
                        visible={isAdmin && (process.buildConfigs.name === 'msi' || process.buildConfigs.name === 'k8s')}
                        color='primary'
                        text={translate({ id: 'CLIENT_ADD_USER' })}
                        label={translate({ id: 'CLIENT_ADD_USER' })}
                        icon='add'
                        onClick={handleBulkInviteUsers}
                        dataCy='add-user-button'
                    />
                    <ApDataGridHeaderButton<IUserWithLicenses>
                        id='bulk-invite-users'
                        key='bulk-invite-users'
                        type='main'
                        buttonType='mat-stroked-button'
                        visible={isAdmin && EnableUserInvite}
                        color='primary'
                        text={translate({ id: 'CLIENT_BULK_INVITE' })}
                        label={translate({ id: 'CLIENT_BULK_INVITE' })}
                        icon="add_to_photos"
                        onClick={handleBulkInviteUsers}
                        dataCy='bulk-invite-users-button'
                    />
                    <ApDataGridHeaderButton<IUserWithLicenses>
                        id='invite-users'
                        key='invite-users'
                        type='main'
                        buttonType='mat-flat-button'
                        visible={isAdmin && EnableUserInvite}
                        color='primary'
                        text={translate({ id: 'CLIENT_INVITE_USER' })}
                        label={translate({ id: 'CLIENT_INVITE_USER' })}
                        icon='add'
                        onClick={handleInviteUser}
                        dataCy='invite-users-button'
                    />
                </ApDataGridHeader>

                {!!process.buildConfigs.enableUsernameColumn && (
                    <ApDataGridColumn<IUserWithLicenses>
                        property="UserName"
                        title={translate({ id: 'CLIENT_USERNAME' })}
                        width={25}
                        sortable
                        render={(item) => <>
                            <div className={classes.userNameCell}>
                                <ApTooltip
                                    content={`${item.userName} ${item.groupIDs?.find(groupId => groupId === UserGroup.Administrators) ? translate({ id: 'CLIENT_ORG_ADMIN' }) : ''}`}>
                                    {item.userName}
                                </ApTooltip>
                                {!isHostMode && item.groupIDs?.find(groupId => groupId === UserGroup.Administrators) && (
                                    <Chip
                                        className={classes.adminChip}
                                        label={translate({ id: 'CLIENT_ORG_ADMIN' })}
                                    />
                                )}
                            </div>
                        </>}
                    />
                )}
                {!!process.buildConfigs.enableUsernameColumn && (
                    <ApDataGridColumn<IUserWithLicenses>
                        property="Name"
                        title={translate({ id: 'CLIENT_NAME' })}
                        width="20"
                        sortable
                        render={(item) =>
                            <ApTooltip content={getDisplayName(item)}>
                                {getDisplayName(item)}
                            </ApTooltip>}
                    />
                )}

                {!process.buildConfigs.enableUsernameColumn && (
                    <ApDataGridColumn<IUserWithLicenses>
                        property="UserName"
                        title={translate({ id: 'CLIENT_NAME' })}
                        width={25}
                        sortable
                        render={(item) => <>
                            <div className={classes.userNameCell}>
                                <ApTooltip
                                    content={`${getDisplayName(item)} ${item.groupIDs?.find(groupId => groupId === UserGroup.Administrators) ? translate({ id: 'CLIENT_ORG_ADMIN' }) : ''}`}>
                                    {getDisplayName(item)}
                                </ApTooltip>
                                {item.groupIDs?.find(groupId => groupId === UserGroup.Administrators) && (
                                    <Chip
                                        className={classes.adminChip}
                                        label={translate({ id: 'CLIENT_ORG_ADMIN' })}
                                    />
                                )}
                            </div>
                        </>}
                    />
                )}

                <ApDataGridColumn<IUserWithLicenses>
                    property="Email"
                    title={translate({ id: 'CLIENT_EMAIL' })}
                    width={25}
                    sortable
                    render={(item) => <ApTooltip content={item.email}>
                        {item.email}
                    </ApTooltip>}
                />

                {!isHostMode && (
                    <ApDataGridColumn<IUserWithLicenses>
                        property="Groups"
                        title={translate({ id: 'CLIENT_GROUPS' })}
                        width={25}
                        render={(item) => {
                            const groups = getGroupsForUser(item.groupIDs)?.join(', ');
                            return groups ? (
                                <ApTooltip content={groups}>
                                    <span className={classes.truncate}>
                                        {groups}
                                    </span>
                                </ApTooltip>
                            ) : (
                                <span className={classes.truncate}>
                                    {translate({ id: 'CLIENT_NO_GROUPS_FOUND' })}
                                </span>
                            );

                        }}
                    />
                )}

                {EnableUserLicensing && isAdmin && !isUnlicensedMode && (
                    <ApDataGridColumn<IUserWithLicenses>
                        property="userBundleLicenses"
                        title={translate({ id: 'CLIENT_LICENSES' })}
                        width={25}
                        render={(item) => {
                            const {
                                text, tooltip, Icon,
                            } = getLicenseEntry(item);
                            return (
                                <ApTooltip content={tooltip}>
                                    <UiStack
                                        className={classes.truncate}
                                        align='center'
                                        gap={SpacingToken.Micro}>
                                        {Icon && <Icon className={clsx(classes.licenseIcon, classes.icon)} />}
                                        <span
                                            className={classes.truncate}
                                            data-cy="user-license">
                                            {text}
                                        </span>
                                    </UiStack>
                                </ApTooltip>
                            );
                        }}
                    />
                )}

                <ApDataGridColumn<IUserWithLicenses>
                    property="LastLoginTime"
                    title={translate({ id: 'CLIENT_LAST_ACTIVE' })}
                    width={25}
                    sortable
                    render={(item) => {
                        if (!item.lastLoginTime || new Date(item.lastLoginTime).getTime() <= 0) {
                            if (!process.buildConfigs.disableUserInvite &&
                                item.type !== UserType.DirectoryUser &&
                                !DisableFeatureFedRamp) {
                                return (
                                    <span>
                                        {isAdmin ? translate({ id: 'CLIENT_PENDING' }) : translate({ id: 'CLIENT_PENDING_INVITATION' })}
                                        {isAdmin && (
                                            <ApTooltip content={translate({ id: 'CLIENT_RESEND' })}>
                                                <button
                                                    className={classes.resendButton}
                                                    data-cy="resend-invite-button"
                                                    onClick={() => openResendInviteDialog(item)}
                                                >
                                                    {translate({ id: 'CLIENT_RESEND' })}
                                                </button>
                                            </ApTooltip>
                                        )}
                                    </span>
                                );
                            }
                            return <></>;
                        }

                        return (
                            <FormattedDate
                                value={new Date(item.lastLoginTime)}
                                year="numeric"
                                month="short"
                                day="numeric" />
                        );
                    }}
                />

                {gridRowActions}

                <ApDataGridFooter
                    length={data?.totalCount ?? 0}
                    pageSizes={[ 5, 10, 25, 50 ]}
                />

            </ApDataGridWrapper>
        </>

    );
};

export default UsersCISPageComponent;
