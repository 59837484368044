import { useFeatureFlagValue } from '@experiences/feature-flags';
import { logTrace } from '@experiences/telemetry';
import React from 'react';

type ErrorBoundaryState = {
    hasError: boolean;
};

class ErrorBoundary extends React.Component<{ children: React.ReactNode; flagName: string }, ErrorBoundaryState> {
    state: ErrorBoundaryState = { hasError: false };

    static getDerivedStateFromError(): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logTrace('Error rendering feature guarded component', {
            name: this.props.flagName,
            code: this.props.flagName,
            isError: true,
        }, {
            Error: error,
            ErrorInfo: errorInfo,
        });
    }

    render() {
        if (this.state.hasError) {
            return null;
        }
        return this.props.children;
    }
}

export type UiFeatureGuardProps = {
    flagName: string;
    children: React.ReactNode;
    invert?: boolean;
};

export const UiFeatureGuard: React.FC<UiFeatureGuardProps> = ({
    flagName, children, invert = false,
}) => {
    const isEnabled = useFeatureFlagValue(flagName);
    if (invert ? !isEnabled : isEnabled) {
        return (
            <ErrorBoundary flagName={flagName}>
                {children}
            </ErrorBoundary>
        );
    }
    return null;
};
