import { portalTelemetry } from '@experiences/telemetry';
import {
    UiBackground,
    UiLogo,
    UiSessionId,
    UiText,
} from '@experiences/ui-common';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import xss from 'xss';

import { ERROR_MAX_STRING_LENGTH } from '../common/constants/Constant';

const useStyles = makeStyles(theme =>
    createStyles({
        paperDefault: {
            width: 380,
            overflow: 'auto',
            marginTop: '100px',
            padding: '0px 24px',
            backgroundColor: theme.palette.semantic.colorBackgroundRaised,
        },
        content: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            marginTop: '42px',
            paddingBottom: '24px',
        },
        headerIcon: {
            height: '48px',
            width: '48px',
            marginTop: '16px',
            color: theme.palette.semantic.colorErrorIcon,
        },
        headerText: {
            marginTop: '16px',
            fontWeight: 600,
            fontSize: '18px',
        },
        headerSubText: {
            textAlign: 'center',
            marginTop: '8px',
            overflowWrap: 'break-word',
            paddingBottom: '20px',
        },
        showMore: {
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' },
        },
        button: {
            width: '280px',
            margin: '16px 0',
        },
    }),
);

const AuthError: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    // eslint-disable-next-line ban/ban
    const navigate = useNavigate();
    const location = useLocation();

    const [ showMore, setShowMore ] = useState(false);

    const [ errorMessage, fullErrorMessage ] = useMemo(() => {
        const error = location.state?.error;

        if (error?.message) {
            if (error.message.length >= ERROR_MAX_STRING_LENGTH) {
                setShowMore(true);
            }

            return [ error.message.substring(0, ERROR_MAX_STRING_LENGTH), error.message ];
        }

        return [ undefined, undefined ];
    }, [ location.state ]);

    const handleShowMore = useCallback(() => {
        setShowMore(false);
    }, []);

    useEffect(() => {
        portalTelemetry.trackTrace({
            message: errorMessage ?? 'unknown auth error',
            severityLevel: SeverityLevel.Error,
        });
    }, [ errorMessage ]);

    return (
        <UiBackground center>
            <Paper className={classes.paperDefault}>
                <div
                    className={classes.content}
                    data-cy="unhandled-error-content">
                    <UiLogo />
                    <CancelIcon className={classes.headerIcon} />
                    <UiText className={classes.headerText}>
                        {translate({ id: 'CLIENT_OOPS_SOMETHING_WENT_WRONG' })}
                    </UiText>
                    {showMore ? (
                        <>
                            <UiText
                                className={classes.headerSubText}
                                data-cy="error-message">
                                {xss(errorMessage || '')}
                            </UiText>
                            <UiText
                                role="button"
                                className={classes.showMore}
                                onClick={handleShowMore}
                                data-cy="show-more-button">
                                {translate({ id: 'CLIENT_SHOW_MORE' })}
                            </UiText>
                        </>
                    ) : (
                        <UiText
                            className={classes.headerSubText}
                            data-cy="full-error-message">
                            {xss(fullErrorMessage || errorMessage || translate({ id: 'CLIENT_OOPS_ERROR_DESCRIPTION' }))}
.
                        </UiText>
                    )}
                    <UiText>
                        {translate({ id: 'CLIENT_SIGNIN_AGAIN' })}
                    </UiText>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => navigate('/')}
                        data-cy="unhandled-error-login-button"
                    >
                        {translate({ id: 'CLIENT_SIGN_IN' })}
                    </Button>
                    <UiSessionId />
                </div>
            </Paper>
        </UiBackground>
    );
};

export default AuthError;
