import { useCentralErrorSetter } from '@experiences/error';
import { portalTelemetry } from '@experiences/telemetry';
import { UiLoader } from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import RefreshTokenPopUp from '../../component/common/RefreshTokenPopUp';
import RevokeRefreshTokenConfirmation from '../../component/common/RevokeRefreshTokenConfirmation';
import {
    Auth0UserManagerSettings,
    useRequestUserToken,
} from '../../component/tenants/subcomponents/token/RequestUserToken';
import revokeRefreshToken from '../../store/action/RevokeRefreshToken';

const useApiAccessInstanceModal = () => {
    const { formatMessage: translate } = useIntl();
    const setErrorMessage = useCentralErrorSetter();
    const createDialog = useShowDialog();

    const [ showApiModal, setShowApiModal ] = useState(false);
    const [ revokeConfirmation, setRevokeConfirmation ] = useState(false);
    const [ showRevokeSpinner, setShowRevokeSpinner ] = useState(false);
    const [ apiAccessModalLoading, setApiAccessModalLoading ] = useState(false);

    const [ authState, setAuthState ] = useState<{ tenantName?: string; refreshToken?: string }>({
        tenantName: undefined,
        refreshToken: undefined,
    });

    const { getRefreshToken } = useRequestUserToken();

    const openRevokeSuccessModal = useCallback(async () => {
        setShowApiModal(false);
        setRevokeConfirmation(false);
        setShowRevokeSpinner(false);

        await createDialog({
            title: translate({ id: 'CLIENT_REFRESH_TOKENS_REVOKED' }),
            body: translate({ id: 'CLIENT_REVOKE_REFRESH_TOKEN_SUCCESSFULL' }),
            icon: 'success',
        });

    }, [ setRevokeConfirmation, createDialog, translate ]);

    const openRevokeErrorModal = useCallback(async () => {
        setRevokeConfirmation(false);
        setShowRevokeSpinner(false);

        await createDialog({
            title: translate({ id: 'CLIENT_ERROR' }),
            body: translate({ id: 'CLIENT_REFRESH_TOKEN_ERROR' }),
            icon: 'error',
        });
    }, [ setRevokeConfirmation, createDialog, translate ]);

    const openRevokeConfirmationModal = useCallback(
        () => (
            <RevokeRefreshTokenConfirmation
                close={() => setRevokeConfirmation(false)}
                revokeTokenHandler={async () => {
                    setShowRevokeSpinner(true);
                    await revokeRefreshToken(authState.refreshToken!, openRevokeSuccessModal, openRevokeErrorModal);
                }}
            />
        ),
        [ authState, openRevokeSuccessModal, openRevokeErrorModal ],
    );

    const openApiAccessInstanceModal = useCallback(() => {
        if (showApiModal && authState.refreshToken && authState.tenantName) {
            portalTelemetry.trackTrace({
                message: 'Succesfully retrieved refresh token',
                severityLevel: SeverityLevel.Information,
            });

            const closeApiAccess = () => {
                setAuthState({});
                setErrorMessage(null);
                setShowApiModal(!showApiModal);
            };

            return (
                <RefreshTokenPopUp
                    clientId={Auth0UserManagerSettings.client_id}
                    tenantName={authState.tenantName}
                    refreshToken={authState.refreshToken}
                    close={closeApiAccess}
                    handleRevoke={() => setRevokeConfirmation(true)}
                />
            );
        }

        return null;
    }, [ authState, setErrorMessage, showApiModal ]);

    const ApiAccessInstanceModalComponent = useCallback(
        () => (
            <>
                {showApiModal && openApiAccessInstanceModal()}
                {revokeConfirmation && openRevokeConfirmationModal()}
                {showRevokeSpinner || apiAccessModalLoading && <UiLoader type="backdrop" />}
            </>
        ),
        [
            apiAccessModalLoading,
            openApiAccessInstanceModal,
            openRevokeConfirmationModal,
            revokeConfirmation,
            showApiModal,
            showRevokeSpinner,
        ],
    );

    const setCurrentTenant = useCallback(async (tenantName: string) => {
        try {
            setApiAccessModalLoading(true);

            const user = await getRefreshToken();

            if (!user) {
                throw new Error('No user returned!');
            }

            setAuthState({
                tenantName,
                refreshToken: user.refresh_token,
            });

            setApiAccessModalLoading(false);
            setShowApiModal(true);
        } catch (error) {
            portalTelemetry.trackTrace({
                message: 'Error in retrieving refresh token.',
                severityLevel: SeverityLevel.Error,
            });

            setApiAccessModalLoading(false);

            await createDialog({
                title: translate({ id: 'CLIENT_ERROR' }),
                body: translate({ id: 'CLIENT_RETRIEVE_REFRESH_TOKEN_ERROR' }),
                icon: 'error',
            });
        }
    }, [ createDialog, getRefreshToken, translate ]);

    return {
        ApiAccessInstanceModalComponent,
        setCurrentTenant,
    };
};

export default useApiAccessInstanceModal;
