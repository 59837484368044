import type { TaskOdata } from '@experiences/interfaces';
import { get } from '@experiences/util';

export const taskUrl = `/api/orchestrator/tasks`;

export function getTask(accessToken: string, tenantName: string | null, accountLogicalName: string, filter: string) {
    return get<TaskOdata>(taskUrl, {
        accessToken,
        urlParams: {
            tenantName,
            filter,
            accountLogicalName,
        },
    });
}
