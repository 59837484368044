import type { Token } from '@experiences/interfaces';
import { get } from '@experiences/util';
import jwtDecode from 'jwt-decode';

export const userSubsUrl = `/api/identity/Setting`;

export interface GlobalSetting {
    id: number;
    key: string;
    partitionGlobalId: string;
    userId: string;
    value: string;
}

export function getGlobalSettings(accessToken: string, key: string[]) {
    const decodeToken = jwtDecode<Token>(accessToken);

    const keyMap = key.map((k) => `key=${k}`).join('&');
    const appsHiddenSetting = `${userSubsUrl}?partitionGlobalId=${decodeToken.prt_id}&${keyMap}`;
    return get<GlobalSetting[]>(appsHiddenSetting, { accessToken });
}
