import { NavCustomizationSetting } from '@experiences/constants';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import type {
    Event,
    TemplateItem,
    WidgetContext,
    WidgetTemplate,
} from '@experiences/interfaces';

import type { GlobalSetting } from '../services/getGlobalSettings';
import { getGlobalSettings } from '../services/getGlobalSettings';
import { getTemplates } from '../services/getTemplates';
import { postEventsCollect } from '../services/postEventsCollect';

export function filterTemplatesByGlobalSettings(globalSettings: GlobalSetting[]) {
    if (globalSettings && globalSettings.length === 0) {
        return true;
    }

    return globalSettings &&
            globalSettings.length > 0 &&
            globalSettings.every((setting) => setting.value === 'false');
}

export const TemplatesWidget: WidgetTemplate<TemplateItem> = {
    name: 'Templates',
    titleKey: 'CLIENT_TEMPLATE_WIDGET_TITLE',
    emptyMsgKey: () => 'CLIENT_TEMPLATE_WIDGET_EMPTY',
    selection: async (ctx: WidgetContext) => {
        const enterpriseFF = getFeatureFlagValue(Features.EnableTemplateWidgetForEnterprise.name);
        const globalSettings = await getGlobalSettings(ctx.accessToken,
            [
                NavCustomizationSetting.MarketplaceHidden,
                NavCustomizationSetting.StudioWebHidden,
            ]);

        const isGlobalSettingsEnabled = filterTemplatesByGlobalSettings(globalSettings);
        return ctx.accountType === 'ENTERPRISE' ? enterpriseFF && isGlobalSettingsEnabled : isGlobalSettingsEnabled;
    },
    fetch: async (ctx: WidgetContext) => {
        const sendRealTimeEvents = getFeatureFlagValue(Features.EnableSRSTemplatesRealTimeEventsCollection.name);

        const templates = await getTemplates(
            ctx.accessToken,
            ctx.persona,
            ctx.language,
            ctx.accountLogicalName,
        );

        if (sendRealTimeEvents) {
            const event: Event = {
                'eventName': 'Templates.Rendered',
                'metadata': {
                    'CloudUserId': ctx.userId,
                    'CloudOrganizationId': ctx.organizationGuid,
                    'CloudTenantId': ctx.tenant,
                    'Model': 'StaticTemplate',
                    'RecommendationSource': 'UiPath.AutomationCloud',
                    'ZoneId': 'ZN-01',
                    'TemplateIds': templates.map((template) => template.id).join(','),
                },
            };
            postEventsCollect([ event ], ctx);
        }

        return templates;
    },
    type: 'Card',
    cardRows: 2,
    transform: (data: TemplateItem[]) => data.map((item) => {
        const categoryKeyFallBack = item.category === 'template' ?
            'CLIENT_TEMPLATE_STUDIO_CATEGORY' :
            'CLIENT_TEMPLATE_MARKETPLACE_CATEGORY';

        return {
            data: item,
            category: item.pillText,
            categoryKey: item.pillText != null ? undefined : categoryKeyFallBack,
            header: item.title,
            description: item.description,
            icons: [ item.logos.url ],
            actions: [
                {
                    id: 'try-it',
                    nameKey: 'CLIENT_ACTION_NAME_TRYIT',
                    type: 'text',
                },
            ],
        };
    }),
    clickHandler: (data: TemplateItem, ctx: WidgetContext) => {
        const sendRealTimeEvents = getFeatureFlagValue(Features.EnableSRSTemplatesRealTimeEventsCollection.name);

        if (sendRealTimeEvents) {
            const event: Event = {
                'eventName': 'Templates.Clicked',
                'metadata': {
                    'ClickedItem': data.id,
                    'CloudUserId': ctx.userId,
                    'CloudOrganizationId': ctx.organizationGuid,
                    'CloudTenantId': ctx.tenant,
                    'Model': 'StaticTemplate',
                    'RecommendationSource': 'UiPath.AutomationCloud',
                    'ZoneId': 'ZN-01',
                },
            };
            postEventsCollect([ event ], ctx);
        }

        window.open(getResourceUrl(data, ctx), '_blank');
    },
    actionHandles: [
        {
            id: 'try-it',
            handle: (data: TemplateItem, ctx: WidgetContext) => {
                window.open(getResourceUrl(data, ctx), '_blank');
            },
        },
    ],
};

function getResourceUrl(data: TemplateItem, ctx: WidgetContext) {
    if (data.category === 'template' && window && window.location) {
        return `${window.location.origin}/${ctx.accountLogicalName}/studio_/templates/${data.actionLink}`;
    }

    return data.actionLink;
}
