import { GlobalStyles } from '@experiences/theme';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    useLocalizedLinks,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { KerberosSetupLinks } from '../../../common/constants/KerberosLinks';
import * as RouteNames from '../../../common/constants/RouteNames';
import { secretPlaceholder } from '../../../common/constants/SecretConstant';
import { isValidDomain } from '../../../util/DomainUtil';
import validateDownLevelLogonName from '../../../util/validators/DownLevelLogonNameValidator';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import { useSecuritySettingsADFormViewModel } from './SecuritySettingsADFormViewModel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        section: { marginTop: '20px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '8px' },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        switchFormControl: { width: '100%' },
        warningText: {
            color: theme.palette.semantic.colorWarningText,
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '20px',
            paddingRight: '8px',
        },
        labelWithIcon: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        infoIcon: {
            marginLeft: '8px',
            color: theme.palette.semantic.colorInfoForeground,
            width: '20px',
        },
        switches: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
}));

const SecuritySettingsADForm: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const getLocalizedLink = useLocalizedLinks();

    const {
        isAD,
        organizationName,
        methods: {
            control,
            formState: {
                isSubmitting, errors,
            },
            watch,
            handleSubmit,
        },
        onSubmit,
        showPasswordPlaceholder,
    } = useSecuritySettingsADFormViewModel();

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.SecuritySettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            link: RouteNames.AuthSettingsConfigureSaml,
            name: translate({ id: 'CLIENT_AD_SSO_CONFIGURATION' }),
        },
    ], [ organizationName, translate ]);

    const KerberosLinkText = useMemo(() => translate(
        { id: 'CLIENT_KERBEROS_AUTH_TOOLTIP' },
        {
            link: (
                <Link
                    target="_blank"
                    rel="noreferrer"
                    href={getLocalizedLink(
                        KerberosSetupLinks,
                    )}
                >
                    {translate({ id: 'CLIENT_LEARN_MORE' })}
                </Link>
            ),
        },
    ) as string, [ getLocalizedLink, translate ]);

    return <UiPageContainer
        breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadCrumbLinks} />}
        maxWidth="900px"
        position='center'>
        <UiForm
            onSubmit={handleSubmit(onSubmit)}
            actions={
                <div className={classes.actions}>
                    <Button
                        className={classes.cancelButton}
                        onClick={() => navigate(getRoute(RouteNames.SecuritySettings))}
                        color="primary">
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        type="submit"
                        loading={isSubmitting}
                        variant="contained"
                        data-cy="configure-ad-submit-button"
                    >
                        {translate({ id: 'CLIENT_TEST_AND_SAVE' })}
                    </UiProgressButton>
                </div>
            }
            centerChild
        >
            <div className={classes.switches}>
                {!isAD && <Controller
                    name="ldaps"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        (!process.buildConfigs.showKerberosAuth
                            || watch('useKerberosAuth') === false)
                            && field.value
                                    }
                                    disabled={
                                        process.buildConfigs.showKerberosAuth
                            && watch('useKerberosAuth') === true
                                    }
                                    onChange={e => field.onChange(e.target.checked)}
                                    color="primary"
                                    data-cy="configure-ad-ldaps-switch"
                                />
                            }
                            label={
                                <div className={classes.labelWithIcon}>
                                    <div className={classes.switchLabel}>
                                        {translate({ id: 'CLIENT_USE_LDAP_OVER_SSL' })}
                                    </div>
                                    <Tooltip
                                        arrow
                                        title={translate({ id: 'CLIENT_LDAPS_WARNING' })}>
                                        <InfoOutlinedIcon
                                            className={classes.infoIcon}
                                            tabIndex={0}
                                            aria-hidden="false"
                                            aria-label={translate({ id: 'CLIENT_LDAPS_WARNING' })}
                                        />
                                    </Tooltip>
                                </div>
                            }
                        />
                    )}
                />}
                {process.buildConfigs.showKerberosAuth && (
                    <Controller
                        name="useKerberosAuth"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={field.value}
                                        onChange={e => field.onChange(e.target.checked)}
                                        color="primary"
                                        data-cy="configure-ad-kerberos-switch"
                                    />
                                }
                                label={
                                    <div className={classes.labelWithIcon}>
                                        <div className={classes.switchLabel}>
                                            {translate({ id: 'CLIENT_USE_KERBEROS_AUTH' })}
                                        </div>
                                        <Tooltip
                                            arrow
                                            title={KerberosLinkText}
                                        >
                                            <InfoOutlinedIcon
                                                className={classes.infoIcon}
                                                tabIndex={0}
                                                aria-hidden="false"
                                                aria-label={KerberosLinkText}
                                            />
                                        </Tooltip>
                                    </div>
                                }
                            />
                        )}
                    />
                )}
                {(!process.buildConfigs.showKerberosAuth || watch('useKerberosAuth') === false)
                    && !isAD && watch('ldaps') === false && (
                    <>
                        <PortalAlertBar
                            status="warning"
                            cancelable={false}
                            data-cy="ad-warning-1"
                        >
                            <UiText className={classes.warningText}>
                                {translate({ id: 'CLIENT_RECOMMENDED_ACTION_WARNING_1' })}
                            </UiText>
                        </PortalAlertBar>
                        <div style={{ marginBottom: '4px' }} />
                    </>
                )}
                {process.buildConfigs.showKerberosAuth && watch('useKerberosAuth') === false && (
                    <PortalAlertBar
                        status="warning"
                        cancelable={false}
                        data-cy="ad-warning-2">
                        <UiText className={classes.warningText}>
                            {translate({ id: 'CLIENT_RECOMMENDED_ACTION_WARNING_2' })}
                        </UiText>
                    </PortalAlertBar>
                )}
                {(!watch('useKerberosAuth') || isAD) && (
                    <>
                        <div className={classes.section}>
                            <Controller
                                name="domain"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: { validDomain: value => !value || isValidDomain(value) },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={translate({ id: 'CLIENT_DEFAULT_DOMAIN' })}
                                        variant="outlined"
                                        autoComplete="off"
                                        fullWidth
                                        InputProps={{ className: 'Tall' }}
                                        inputProps={{ 'aria-labelledby': 'defaultDomainLabel' }}
                                        error={!!errors.domain}
                                        helperText={
                                            (errors.domain?.type === 'required' &&
                    translate(
                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                        { 0: translate({ id: 'CLIENT_DEFAULT_DOMAIN' }) },
                    )) ||
                (errors.domain?.type === 'validDomain' &&
                    translate({ id: 'CLIENT_INVALID_DOMAIN_ERROR' }))
                                        }
                                        data-cy="configure-ad-domain"
                                    />
                                )}
                            />
                        </div>
                        {!isAD && <div className={classes.section}>
                            <Controller
                                name="username"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: { valid: value => value && validateDownLevelLogonName(value) },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={translate({ id: 'CLIENT_USERNAME' })}
                                        variant="outlined"
                                        autoComplete="off"
                                        fullWidth
                                        InputProps={{ className: 'Tall' }}
                                        inputProps={{ 'aria-labelledby': 'usernameLabel' }}
                                        error={!!errors.username}
                                        helperText={
                                            (errors.username?.type === 'required' &&
                                                translate(
                                                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                                    { 0: translate({ id: 'CLIENT_USERNAME' }) },
                                                )) ||
                                            (errors.username?.type === 'valid' &&
                                                translate({ id: 'CLIENT_INVALID_DOWNLEVEL_LOGON_NAME_ERROR' }))
                                        }
                                        data-cy="configure-ad-username"
                                    />
                                )}
                            />
                        </div>}
                        {!isAD && <div className={classes.section}>
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={translate({ id: 'CLIENT_USER_PASSWORD' })}
                                        variant="outlined"
                                        type="password"
                                        autoComplete="new-password"
                                        fullWidth
                                        InputProps={{ className: 'Tall' }}
                                        inputProps={{ 'aria-labelledby': 'userPasswordLabel' }}
                                        placeholder={showPasswordPlaceholder ? secretPlaceholder : undefined}
                                        error={!!errors.password}
                                        helperText={
                                            errors.password?.type === 'required' &&
                                            translate(
                                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                                { 0: translate({ id: 'CLIENT_USER_PASSWORD' }) },
                                            )
                                        }
                                        data-cy="configure-ad-password"
                                    />
                                )}
                            />
                        </div>}
                    </>
                )}
            </div>
        </UiForm>
    </UiPageContainer>;
};

export default SecuritySettingsADForm;
