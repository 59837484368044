import React from 'react';

import type { SpacingToken } from './util';
import {
    alignMap,
    calcSpacingPx,
    justifyMap,
} from './util';

type StackProps = {
    direction?: 'row' | 'column';
    align?: 'start' | 'end' | 'center' | 'stretch' | 'baseline';
    justify?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
    wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
    grow?: number;
    gap?: SpacingToken | number;
    w?: number | string;
    h?: number | string;
    p?: SpacingToken | number | string;
    pt?: SpacingToken | number;
    pb?: SpacingToken | number;
    pl?: SpacingToken | number;
    pr?: SpacingToken | number;
    m?: SpacingToken | number | string;
    mt?: SpacingToken | number;
    mb?: SpacingToken | number;
    ml?: SpacingToken | number;
    mr?: SpacingToken | number;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

const UiStackComponent: React.FC<StackProps> = ({
    children,
    ...props
}) => {
    const {
        align,
        justify,
        wrap,
        grow,
        gap,
        w,
        h,
        p,
        pt,
        pb,
        pl,
        pr,
        m,
        mt,
        mb,
        ml,
        mr,
        direction = 'row',
        ...rest
    } = props;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: direction,
                alignItems: align ? alignMap[align] : undefined,
                justifyContent: justify ? justifyMap[justify] : undefined,
                flexWrap: wrap,
                flexGrow: grow,
                width: calcSpacingPx(w),
                height: calcSpacingPx(h),
                gap: calcSpacingPx(gap),
                padding: calcSpacingPx(p),
                ...(pt !== undefined && { paddingTop: calcSpacingPx(pt) }),
                ...(pb !== undefined && { paddingBottom: calcSpacingPx(pb) }),
                ...(pl !== undefined && { paddingLeft: calcSpacingPx(pl) }),
                ...(pr !== undefined && { paddingRight: calcSpacingPx(pr) }),
                margin: calcSpacingPx(m),
                ...(mt !== undefined && { marginTop: calcSpacingPx(mt) }),
                ...(mb !== undefined && { marginBottom: calcSpacingPx(mb) }),
                ...(ml !== undefined && { marginLeft: calcSpacingPx(ml) }),
                ...(mr !== undefined && { marginRight: calcSpacingPx(mr) }),
            }}
            {...rest}>
            {children}
        </div>
    );
};

UiStackComponent.displayName = 'UiStack';
export const UiStack = UiStackComponent;
