import type { WidgetContext } from '@experiences/interfaces';
import {
    HomePageEvent,
    logEvent,
} from '@experiences/telemetry';
import { UiText } from '@experiences/ui-common';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/styles';
import Tokens, { FontVariantToken } from '@uipath/apollo-core';
import React from 'react';
import { useIntl } from 'react-intl';

interface WidgetHeaderProps {
    id: string;
    title: string;
    preview?: boolean;
    subtitle?: string;
    seeAllText?: string;
    seeAllUrl?: string;
    createUrl?: string;
    // eslint-disable-next-line react/no-unused-prop-types
    context: WidgetContext;
}
export const WidgetHeader = ({
    id, title, preview, seeAllText, createUrl, subtitle, seeAllUrl,
}: WidgetHeaderProps) => {
    const { formatMessage: translate } = useIntl();
    const theme = useTheme();

    return (
        <Box sx={{
            paddingTop: '32px',
            paddingBottom: '24px',
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
            }}>
                <Box sx={{ display: 'flex' }}>
                    <UiText
                        data-testid={`${id}-header-title`}
                        role="heading"
                        aria-level={2}
                        aria-label={title}
                        style={{
                            fontSize: '20px',
                            fontFamily: Tokens.FontFamily.FontTitle,
                            fontWeight: 600,
                            lineHeight: '24px',
                            color: theme.palette.semantic.colorForeground,
                        }}>
                        <span>
                            {title}
                        </span>
                    </UiText>
                    {preview && <Chip
                        data-testid={`${id}-preview-chip`}
                        size="small"
                        className='mini'
                        sx={{
                            marginLeft: '12px',
                            marginTop: '4px',
                        }}
                        label={translate({ id: 'CLIENT_PREVIEW' })} />}
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: '16px',
                }}>
                    {seeAllText && <Link
                        color="primary"
                        data-testid={`${id}-header-see-all-link`}
                        href={seeAllUrl}
                        onClick={() => {
                            logEvent(`${id} widget see all clicked`, {
                                name: HomePageEvent.WidgetHeaderSeeAllClicked,
                                code: '[IN-HP-11]',
                            }, {
                                SeeAllUrl: seeAllUrl,
                                Widget: id,
                            });
                        }}
                        role='link'
                        sx={{ textDecoration: 'none' }}
                        data-cy={`${id}-see-all`}>
                        <UiText style={{
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: 600,
                            color: theme.palette.semantic.colorPrimary,
                            cursor: 'pointer',
                            textDecoration: 'none',
                        }}>
                            {seeAllText}
                        </UiText>
                    </Link>}
                    {createUrl && <Button
                        color="primary"
                        data-testid={`${id}-header-create-btn`}
                        aria-labelledby='create-btn-text'
                        data-cy={`${id}-create`}
                        onClick={() => {
                            logEvent(`${id} widget create clicked`, {
                                name: HomePageEvent.WidgetHeaderCreateClicked,
                                code: '[IN-HP-12]',
                            }, {
                                createUrl,
                                widget: id,
                            });

                            window.location.href = createUrl;
                        }}
                        sx={{
                            height: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                        variant="contained">
                        <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px' }} />
                        <UiText
                            id='create-btn-text'
                            variant={FontVariantToken.fontSizeMBold}
                            style={{ color: theme.palette.semantic.colorForegroundInverse }}>
                            {translate({ id: 'CLIENT_CREATE_USER_BTN' })}
                        </UiText>
                    </Button>}
                </Box>
            </Box>
            {subtitle && <UiText data-testid={`${id}-subtitle`}>
                {subtitle}
            </UiText>}
        </Box>
    );
};
