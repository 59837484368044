import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    UiSelect,
    UiText,
} from '@experiences/ui-common';
import {
    getEnvVariableValue,
    useFormKeysValidation,
} from '@experiences/util';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import { AllowedDomainsAndAttributeMappingSaml } from '../../../common/constants/documentation/SAMLDocumentationLinks.default';
import {
    certificateLocationUsageOptions,
    Saml2BindingType,
    samlBindingTypeOptions,
} from '../../../common/constants/ExternalIdentityProviderConstant';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { AttributeType } from '../../../common/interfaces/cis/attribute';
import type { ISamlFormData } from '../../../common/interfaces/cis/saml';
import AttributeMapping from '../subcomponents/AttributeMapping';

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '40px',
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        header: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '24px',
        },
        field: { marginTop: '24px' },
        fieldMini: { marginTop: '4px' },
        advancedFieldsButton: {
            width: 'fit-content',
            marginTop: '24px',
        },
        fieldLabel: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: '5px',
        },
        formSection: {
            display: 'flex',
            flexDirection: 'column',
        },
        switchLabel: { lineHeight: '20px' },
        labelWithHelp: {
            display: 'flex',
            flexDirection: 'row',
        },
        labelMargin: { marginTop: '24px' },
        sectionLabel: {
            fontWeight: 600,
            lineHeight: '20px',
        },
        infoIcon: {
            width: '18px',
            height: '18px',
            marginLeft: '4px',
            marginTop: '1px',
            cursor: 'pointer',
        },
        tooltip: { display: 'flex' },
        tooltipIcon: {
            width: '16px',
            height: '16px',
            marginLeft: '4px',
        },
    }),
);

const SAMLAttributeMappingPageComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const HideSAMLServiceCertificateUsage = useFeatureFlagValue(Features.HideSAMLServiceCertificateUsage.name);

    const getLocalizedLink = useDocumentationLinks();

    const {
        register,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useFormContext<ISamlFormData>();

    const [ enableCustomIdentifier, setEnableCustomIdentifier ] = useState(() =>
        !!watch('attributes')?.find(obj => obj.attributeType === AttributeType.IDENTIFIER));
    const [ showAdvancedFields, setShowAdvancedFields ] = useState(() =>
        !!watch('attributes')?.find(obj => obj.attributeType === AttributeType.IDENTIFIER)
    );

    const activeKeys = useMemo(() => {
        if (enableCustomIdentifier) {
            return [ 'attributes' ];
        }
        return [ 'attributes', 'ProvisioningSetting.AllowedDomains' ];
    }, [ enableCustomIdentifier ]);

    useFormKeysValidation(activeKeys);

    const bindingTypes = useMemo(() => {
        const options: any = { ...samlBindingTypeOptions };
        delete options[Saml2BindingType.Artifact];

        return options;
    }, []);

    const handleEnableCustomIdentifier = useCallback((checked: boolean) => {
        setEnableCustomIdentifier(checked);
        if (checked) {
            setValue('attributes', [
                {
                    attributeType: AttributeType.IDENTIFIER,
                    value: '',
                    disableRemove: true,
                },
                {
                    attributeType: AttributeType.EMAIL,
                    value: '',
                    disableRemove: true,
                },
            ]);
            setValue('ProvisioningSetting.AllowedDomains', '');
        } else {
            setValue('attributes', [
                {
                    attributeType: AttributeType.DISPLAYNAME,
                    value: '',
                    disableRemove: true,
                },
            ]);
        }
    }, [ setValue ]);

    return <>
        <PortalAlertBar cancelable={false}>
            <UiText>
                <FormattedMessage
                    id='CLIENT_SAML_ATTRIBUTE_MAPPING_ALERT'
                    values={{
                        b: (chunks: React.ReactNode[]) => <b>
                            {chunks}
                        </b>,
                    }} />
            </UiText>
        </PortalAlertBar>
        <Grid
            className={classes.container}
            sx={{ gap: '80px' }}>
            <Box className={classes.column}>
                <UiText className={classes.header}>
                    {translate({ id: 'CLIENT_SAML_IDP_STEP_3' })}
                </UiText>
                <AttributeMapping type="saml" />
            </Box>
            <Box className={classes.column}>
                {!enableCustomIdentifier &&
                <>
                    <Box className={classes.labelWithHelp}>
                        <UiText className={classes.sectionLabel}>
                            {translate({ id: 'CLIENT_ALLOWED_DOMAINS' })}
                        </UiText>
                        <Tooltip
                            arrow
                            title={
                                <Box className={classes.tooltip}>
                                    {translate({ id: 'CLIENT_ALLOWED_DOMAINS_HELP_TEXT' })}
                                    <OpenInNewIcon className={classes.tooltipIcon} />
                                </Box>
                            }
                        >
                            <Link
                                href={getLocalizedLink({
                                    articleSlug: AllowedDomainsAndAttributeMappingSaml,
                                    product: getEnvVariableValue('DOCUMENTATION_PRODUCT'),
                                    version: getEnvVariableValue('DOCUMENTATION_VERSION'),
                                })}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={translate({ id: 'CLIENT_ALLOWED_DOMAINS_HELP_TEXT' })}
                            >
                                <HelpOutlineIcon className={classes.infoIcon} />
                            </Link>
                        </Tooltip>
                    </Box>
                    <TextField
                        InputLabelProps={{ id: 'allowedDomainsLabel' }}
                        inputProps={{
                            'aria-labelledby': 'allowedDomainsLabel',
                            ...register('ProvisioningSetting.AllowedDomains', { required: !enableCustomIdentifier }),
                        }}
                        variant="outlined"
                        fullWidth
                        error={!!errors.ProvisioningSetting?.AllowedDomains}
                        helperText={
                            (errors.ProvisioningSetting?.AllowedDomains?.type === 'required'
                            && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                            || translate({ id: 'CLIENT_ALLOWED_DOMAINS_HELPER_TEXT' })
                        }
                        data-cy="configure-jit-allowed-domains-field"
                    />
                </>}
                {!showAdvancedFields && <Button
                    className={classes.advancedFieldsButton}
                    variant="text"
                    onClick={() => setShowAdvancedFields(true)}
                    data-cy="saml-show-advanced-settings-button">
                    {translate({ id: 'CLIENT_SHOW_ADVANCED_SETTINGS' })}
                </Button>}
                {showAdvancedFields && <Box className={classes.formSection}>
                    <FormControlLabel
                        className={classes.field}
                        control={
                            <Switch
                                checked={enableCustomIdentifier}
                                onChange={e => handleEnableCustomIdentifier(e.target.checked)}
                                color="primary"
                                data-cy="enable-custom-identifier-toggle"
                            />
                        }
                        label={
                            <UiText className={classes.switchLabel}>
                                {translate({ id: 'CLIENT_ENABLE_CUSTOM_UNIQUE_IDENTIFIER' })}
                            </UiText>
                        }
                    />
                    {enableCustomIdentifier && <FormHelperText>
                        <span style={{ color: 'red' }}>
                            *
                        </span>
                        {translate({ id: 'CLIENT_UNIQUE_IDENTIFIER_DISCLAIMER' })}
                    </FormHelperText>}
                    <Box className={clsx(classes.labelWithHelp, classes.labelMargin)}>
                        <UiText className={classes.sectionLabel}>
                            {translate({ id: 'CLIENT_SAML_BINDING_TYPE' })}
                        </UiText>
                        <Tooltip
                            arrow
                            title={translate({ id: 'CLIENT_SAML_BINDING_TYPE_TOOLTIP' })}
                        >
                            <InfoOutlinedIcon className={classes.infoIcon} />
                        </Tooltip>
                    </Box>
                    <UiSelect
                        control={control}
                        className={classes.fieldMini}
                        name="Saml2BindingType"
                        error={!!errors.Saml2BindingType}
                        options={bindingTypes}
                        required
                        helperText={errors.Saml2BindingType?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                        fullWidth
                        dataCy="configure-saml2-binding-type"
                    />
                    {!HideSAMLServiceCertificateUsage
                        && <>
                            <Box className={clsx(classes.labelWithHelp, classes.labelMargin)}>
                                <UiText className={classes.sectionLabel}>
                                    {translate({ id: 'CLIENT_SERVICE_CERTIFICATE_USAGE' })}
                                </UiText>
                                <Tooltip
                                    arrow
                                    title={translate({ id: 'CLIENT_SAML_SERVICE_CERTIFICATE_USAGE_TOOLTIP' })}
                                >
                                    <InfoOutlinedIcon className={classes.infoIcon} />
                                </Tooltip>
                            </Box>
                            <UiSelect
                                control={control}
                                name="ServiceCertificateUsage"
                                error={!!errors.ServiceCertificateUsage}
                                options={certificateLocationUsageOptions}
                                fullWidth
                                dataCy="edit-certificate-locations-service-usage"
                                required
                                helperText={
                                    errors.ServiceCertificateUsage?.type === 'required'
                                        && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                                }
                                className={classes.fieldMini}
                            />
                        </>}
                    <Controller
                        name="AllowUnsolicitedAuthnResponse"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                className={classes.field}
                                control={
                                    <Switch
                                        checked={field.value}
                                        onChange={e => field.onChange(e.target.checked)}
                                        color="primary"
                                        data-cy="configure-saml-allow-unsolicited-authn-response"
                                    />
                                }
                                label={
                                    <Box className={classes.labelWithHelp}>
                                        <UiText className={classes.sectionLabel}>
                                            {translate({ id: 'CLIENT_ALLOW_UNSOLICITED_AUTHENTICATION_RESPONSE' })}
                                        </UiText>
                                        <Tooltip
                                            arrow
                                            title={translate({ id: 'CLIENT_SAML_UNSOLICITED_AUTHENTICATION_TOOLTIP' })}
                                        >
                                            <InfoOutlinedIcon className={classes.infoIcon} />
                                        </Tooltip>
                                    </Box>
                                }
                            />
                        )}
                    />
                </Box>}
            </Box>
        </Grid>
    </>;
};

export default SAMLAttributeMappingPageComponent;
