import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import type { Theme } from '@mui/material/styles';
import type { StyledComponent } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles<Theme, { disabled: boolean }>((theme) => createStyles({
    wrapper: {
        position: 'relative',
        display: 'inline',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    deleteButtonContained: disabled => ({
        color: theme.palette.getContrastText(theme.palette.semantic.colorErrorIcon),
        backgroundColor: disabled
            ? `${theme.palette.semantic.colorForegroundDisable} !important`
            : `${theme.palette.semantic.colorErrorIcon} !important`,
        borderColor: disabled
            ? `${theme.palette.semantic.colorForegroundDisable} !important`
            : `${theme.palette.semantic.colorErrorIcon} !important`,
        '&:hover': {
            backgroundColor: `${theme.palette.semantic.colorErrorText} !important`,
            borderColor: `${theme.palette.semantic.colorErrorText} !important`,
        },
    }),
    deleteButtonOutlined: {
        border: '1px solid',
        color: `${theme.palette.semantic.colorErrorText} !important`,
        '&:hover': {
            backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important`,
            borderColor: `${theme.palette.semantic.colorErrorText} !important`,
        },
        borderColor: `${theme.palette.semantic.colorErrorText} !important`,
    },
    disabled: {
        color: `${theme.palette.semantic.colorForegroundDisable} !important`,
        borderColor: `${theme.palette.semantic.colorForegroundDisable} !important`,
        '&:hover': {
            backgroundColor: `${theme.palette.semantic.colorBackgroundDisabled} !important`,
            borderColor: `${theme.palette.semantic.colorForegroundDisable} !important`,
        },
    },
}));

type IUiDeleteButtonProps = ButtonProps & {
    loading?: boolean;
    innerButtonClass?: string;
};

export const UiDeleteButton: StyledComponent<IUiDeleteButtonProps> = ({
    loading, disabled = false, variant, className, innerButtonClass, ...rest
}) => {
    const classes = useStyles({ disabled });

    return (
        <div className={clsx(classes.wrapper, className)}>
            <Button
                {...rest}
                aria-label="ui-delete-button"
                className={clsx(
                    {
                        [classes.deleteButtonContained]: variant === 'contained',
                        [classes.deleteButtonOutlined]: variant === 'outlined',
                        [classes.disabled]: disabled,
                    },
                    innerButtonClass,
                )}
                disabled={loading ?? disabled}
            />
            {loading && <CircularProgress
                size={24}
                className={classes.buttonProgress}
                aria-label="ui-progress-button-loading"
                data-cy="ui-progress-button-loading" />}
        </div>
    );
};
