import {
    getEnvVariableValue,
    UiStorage,
} from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { portalTelemetry } from './portal';

export interface TelemetryEvent {
    name: string;
    code: string;
    action?: string;
    isError?: boolean;
}

export function logEvent(
    message: string,
    event: TelemetryEvent,
    customDimensions?: Object) {

    portalTelemetry.trackEvent(
        {
            name: event.name,
            properties: {
                Message: `${event.code} - ${message}`,
                IsError: event.isError,
                Code: event.code,
            },
        },
        {
            ...getCustomDimensions(),
            ...customDimensions,
        },
    );
}

export function logTrace(
    message: string,
    event: TelemetryEvent,
    customDimensions?: Object) {

    portalTelemetry.trackTrace(
        {
            message: `${event.name} - ${message}`,
            severityLevel: event.isError ? SeverityLevel.Error : SeverityLevel.Information,
        },
        {
            ...getCustomDimensions(),
            ...customDimensions,
        },
    );
}

export function getCustomDimensions() {
    return {
        ApplicationName: getEnvVariableValue('TELEMETRY_APPLICATION_NAME') ?? 'UiPath.AutomationCloud',
        LaunchType: 'AutomationCloud',
        CloudOrganizationId: UiStorage.getItem('lastUsedOrganizationId'),
        CloudUserId: UiStorage.getItem('PORTAL_CURRENT_USERID'),
        CloudTenantId: UiStorage.getItem('PORTAL_CURRENT_TENANT'),
        AccountName: UiStorage.getItem('account'),
        TenantName: UiStorage.getItem('PORTAL_CURRENT_TENANT'),
        BrowserVersion: navigator?.userAgent,
        ScreenWidth: window?.screen?.width,
        ProductLanguage: UiStorage.getItem('PORTAL_LOC_CONTEXT') ?? 'en',
        ScreenHeight: window?.screen?.height,
        ProductVersion: window.env?.CLOUDRPA_VERSION,
        EntryPoint: UiStorage.getItem('entrypointUrl'),
        SessionId: UiStorage.getItem('UiPathSessionId'),
    };
}
