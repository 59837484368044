import { UiSelect } from '@experiences/ui-common';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    useFieldArray,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { IFieldAttribute } from '../../../common/interfaces/cis/attribute';
import { AttributeType } from '../../../common/interfaces/cis/attribute';

const useStyles = makeStyles(theme =>
    createStyles({
        list: { background: 'none' },
        listItem: {
            padding: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            marginBottom: '8px',
            alignItems: 'start',
        },
    }),
);

const AttributeMapping: React.FC<{ type: 'aad' | 'saml'; edit?: boolean }> = ({
    type, edit = false,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const {
        control,
        formState: { errors },
        register,
        getValues,
    } = useFormContext<{ attributes: IFieldAttribute[] }>();

    const {
        fields, append, remove,
    } = useFieldArray({
        control,
        name: 'attributes',
    });

    const addMapping = () => {
        append({} as IFieldAttribute);
    };

    const removeMapping = (index: number) => {
        remove(index);
    };

    // Other options to be added later
    const attributeTypeOptions: Record<string, string> = useMemo(() => {
        const options: Record<string, string> = {};

        if (!edit) {
            options[AttributeType.DISPLAYNAME] = 'CLIENT_DISPLAY_NAME';
            options[AttributeType.FIRSTNAME] = 'CLIENT_FIRST_NAME';
            options[AttributeType.LASTNAME] = 'CLIENT_LAST_NAME';
            options[AttributeType.EMAIL] = 'CLIENT_EMAIL';
        }

        options[AttributeType.BUSINESS] = 'CLIENT_ATTRIBUTE_BUSINESS';

        if (!edit) {
            options[AttributeType.JOB] = 'CLIENT_ATTRIBUTE_JOB';
            options[AttributeType.LOCATION] = 'CLIENT_ATTRIBUTE_LOCATION';
            options[AttributeType.DEPARTMENT] = 'CLIENT_DEPARTMENT';
            options[AttributeType.CITY] = 'CLIENT_CITY';
            options[AttributeType.IDENTIFIER] = 'CLIENT_UNIQUE_IDENTIFIER';
        }

        return options;
    }, [ edit ]);

    const currentOptions = useCallback((option: AttributeType | undefined) => {
        const currentAttributes = getValues();
        if (Object.keys(currentAttributes).length === 0) {
            currentAttributes.attributes = [];
        }
        const inUse: AttributeType[] = currentAttributes.attributes.map((obj: IFieldAttribute) => obj.attributeType);
        const filtered = Object.keys(attributeTypeOptions)
            .filter(key => key === option || !inUse.includes(key as AttributeType))
            .reduce((cur: any, key: AttributeType | string | number) =>
                Object.assign(cur, { [key]: attributeTypeOptions[key] }), {});
        return filtered;
    }, [ attributeTypeOptions, getValues ]);

    const isAddDisabled = fields.length >= Object.keys(attributeTypeOptions).length;

    return <>
        <List className={classes.list}>
            {fields.map((field, i) => (
                <ListItem
                    key={field.id}
                    className={classes.listItem}
                    data-cy={`attribute-list-item-${i}`}>
                    <Box>
                        { i === 0 && <InputLabel id={`attribute-value-${i}`}>
                            {translate({ id: type === 'aad' ? 'CLIENT_ATTRIBUTE_FIELD_AAD' : 'CLIENT_ATTRIBUTE_FIELD_SAML' })}
                        </InputLabel>}
                        <TextField
                            required
                            sx={{ width: '210px' }}
                            inputProps={register(`attributes.${i}.value`, { required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) })}
                            error={!!errors.attributes?.[i]?.value}
                            helperText={errors.attributes?.[i]?.value?.message}
                            defaultValue={field.value}
                            variant="outlined"
                            data-cy={`attributes-value-${i}`}
                            aria-labelledby={`attribute-value-${i}`}
                        />
                    </Box>
                    <ArrowForwardIcon sx={i === 0 ? { margin: '44px 0px 8px 0px' } : { margin: '8px 0px' }} />
                    <Box sx={{ minWidth: '210px' }}>
                        <UiSelect
                            required
                            name={`attributes.${i}.attributeType`}
                            control={control}
                            error={!!errors.attributes?.[i]?.attributeType}
                            helperText={errors.attributes?.[i]?.attributeType && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            inputLabel={i === 0 ? translate({ id: 'CLIENT_ATTRIBUTE_CUSTOM' }) : undefined}
                            placeholder={translate({ id: 'CLIENT_SELECT' })}
                            options={currentOptions(getValues(`attributes.${i}.attributeType`))}
                            defaultValue={field.attributeType}
                            fullWidth
                            disabled={field.disableRemove}
                            dataCy={`ui-select-attribute-type-${i}`}
                        />
                    </Box>
                    <IconButton
                        sx={i === 0 ? { marginTop: '36px' } : undefined}
                        onClick={() => removeMapping(i)}
                        disabled={field.disableRemove}
                        data-cy={`remove-mapping-button-${i}`}
                    >
                        <DeleteOutlineIcon />
                    </IconButton>
                </ListItem>
            ))}
        </List>

        <Button
            sx={{ width: 'fit-content' }}
            disabled={isAddDisabled}
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => addMapping()}
            data-cy="add-mapping-button">
            {translate({ id: 'CLIENT_ADD_NEW_MAPPING' })}
        </Button>
    </>;
};

export default AttributeMapping;
