import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import { useFormKeysValidation } from '@experiences/util';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import {
    makeStyles,
    useTheme,
} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import validateCertificate from '../helpers/ValidateSAMLCert';
import useSAMLConfigureIDPViewModel from './SAMLConfigureIDPViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '40px',
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        header: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '24px',
        },
        copyText: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '16px',
        },
        copyHeader: {
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '12px',
        },
        metadataText: { marginBottom: '20px' },
        copyIcon: { fontSize: '16px' },
        fetchSection: { display: 'flex' },
        field: { marginTop: '24px' },
        fetchButton: {
            marginLeft: '20px',
            marginTop: '36px',
        },
        orDivider: {
            marginTop: '16px',
            marginBottom: '16px',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        manualFieldsButton: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        fieldLabel: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: '5px',
        },
    }),
);

const SAMLConfigureIDPPageComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const theme = useTheme();

    const {
        loading,
        methods: {
            register,
            control,
            formState: { errors },
            watch,
        },
        metadataStatus,
        metadataUrls,
        fetchData,
        getFetchHelperText,
    } = useSAMLConfigureIDPViewModel();

    const [ linksCopied, setLinksCopied ] = useState({
        metadataUrl: false,
        acsUrl: false,
        entityId: false,
    });

    const [ singleSignOnServiceUrl, identityProviderEntityId, signingCertificateText ] = watch([
        'SingleSignOnServiceUrl',
        'IdentityProviderEntityId',
        'SigningCertificateLocation.CertificateText',
    ]);

    const metadataUrlRequired = useMemo(() =>
        !singleSignOnServiceUrl || !identityProviderEntityId || !signingCertificateText,
    [ singleSignOnServiceUrl, identityProviderEntityId, signingCertificateText ]);

    const [ showManualFields, setShowManualFields ] = useState(!metadataUrlRequired);

    useEffect(() => {
        setShowManualFields(!metadataUrlRequired);
    }, [ metadataUrlRequired ]);

    const activeKeys = useMemo(() => {
        if (metadataUrlRequired) {
            return [ 'IdentityProviderMetadataUrl' ];
        }
        return [
            'SingleSignOnServiceUrl',
            'IdentityProviderEntityId',
            'SigningCertificateLocation.CertificateText',
        ];
    }, [ metadataUrlRequired ]);

    useFormKeysValidation(activeKeys);

    if (loading) {
        return <CircularProgress
            size={24}
            className={classes.buttonProgress}
            aria-label="ui-progress-button-loading"
            data-cy="ui-progress-button-loading" /> ;
    }

    return (
        <Grid
            className={classes.container}
            sx={{ gap: '80px' }}>
            <Box className={classes.column}>
                <UiText className={classes.header}>
                    {translate({ id: 'CLIENT_SAML_IDP_STEP_1' })}
                </UiText>
                {process.buildConfigs.showMetadataLink && <Box className={classes.copyText}>
                    <Box className={classes.copyHeader}>
                        <UiText variant={FontVariantToken.fontSizeMBold}>
                            {translate({ id: 'CLIENT_METADATA_URL' })}
                        </UiText>
                        <CopyToClipboard
                            text={metadataUrls.metadataUrl}
                            onCopy={() => setLinksCopied({
                                metadataUrl: true,
                                acsUrl: false,
                                entityId: false,
                            })}>
                            <Tooltip title={translate({
                                id: linksCopied.metadataUrl
                                    ? 'CLIENT_COPIED'
                                    : 'CLIENT_COPY',
                            })}>
                                <IconButton>
                                    {linksCopied.metadataUrl
                                        ? <CheckCircle
                                            className={classes.copyIcon}
                                            color="success" />
                                        : <ContentCopyOutlined
                                            className={classes.copyIcon}
                                            color="primary" />}
                                </IconButton>
                            </Tooltip>
                        </CopyToClipboard>
                    </Box>
                    <UiText className={classes.metadataText}>
                        {metadataUrls.metadataUrl}
                    </UiText>
                </Box>}
                <Box className={classes.copyText}>
                    <Box className={classes.copyHeader}>
                        <UiText variant={FontVariantToken.fontSizeMBold}>
                            {translate({ id: 'CLIENT_ASSERTION_CONSUMER_SERVICE_URL' })}
                        </UiText>
                        <CopyToClipboard
                            text={metadataUrls.assertionConsumerUrl}
                            onCopy={() => setLinksCopied({
                                metadataUrl: false,
                                acsUrl: true,
                                entityId: false,
                            })}>
                            <Tooltip title={translate({
                                id: linksCopied.acsUrl
                                    ? 'CLIENT_COPIED'
                                    : 'CLIENT_COPY',
                            })}>
                                <IconButton>
                                    {linksCopied.acsUrl
                                        ? <CheckCircle
                                            className={classes.copyIcon}
                                            color="success" />
                                        : <ContentCopyOutlined
                                            className={classes.copyIcon}
                                            color="primary" />}
                                </IconButton>
                            </Tooltip>
                        </CopyToClipboard>
                    </Box>
                    <UiText className={classes.metadataText}>
                        {metadataUrls.assertionConsumerUrl}
                    </UiText>
                </Box>
                <Box className={classes.copyText}>
                    <Box className={classes.copyHeader}>
                        <UiText variant={FontVariantToken.fontSizeMBold}>
                            {translate({ id: 'CLIENT_ENTITY_ID' })}
                        </UiText>
                        <CopyToClipboard
                            text={metadataUrls.entityId}
                            onCopy={() => setLinksCopied({
                                metadataUrl: false,
                                acsUrl: false,
                                entityId: true,
                            })}>
                            <Tooltip title={translate({
                                id: linksCopied.entityId
                                    ? 'CLIENT_COPIED'
                                    : 'CLIENT_COPY',
                            })}>
                                <IconButton>
                                    {linksCopied.entityId
                                        ? <CheckCircle
                                            className={classes.copyIcon}
                                            color="success" />
                                        : <ContentCopyOutlined
                                            className={classes.copyIcon}
                                            color="primary" />}
                                </IconButton>
                            </Tooltip>
                        </CopyToClipboard>
                    </Box>
                    <UiText className={classes.metadataText}>
                        {metadataUrls.entityId}
                    </UiText>
                </Box>
            </Box>
            <Box className={classes.column}>
                <UiText className={classes.header}>
                    {translate({ id: 'CLIENT_SAML_IDP_STEP_2' })}
                </UiText>
                <Box className={classes.fetchSection}>
                    <TextField
                        required={metadataUrlRequired}
                        label={translate({ id: 'CLIENT_METADATA_URL' })}
                        InputLabelProps={{ id: 'metadataUrlLabel' }}
                        inputProps={{
                            'aria-labelledby': 'metadataUrlLabel',
                            ...register('IdentityProviderMetadataUrl', { required: metadataUrlRequired && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) }),
                        }}
                        placeholder={translate({ id: 'CLIENT_ENTER_URL_HERE' })}
                        fullWidth
                        error={!!metadataStatus.error || !!errors.IdentityProviderMetadataUrl}
                        helperText={getFetchHelperText}
                        data-cy="metadata-url-field"
                        disabled={metadataStatus.loading}
                    />
                    <UiProgressButton
                        variant="outlined"
                        onClick={fetchData}
                        className={classes.fetchButton}
                        data-cy="metadata-url-fetch-now-button"
                        loading={metadataStatus.loading}
                    >
                        {translate({ id: 'CLIENT_FETCH' })}
                    </UiProgressButton>
                </Box>
                {!showManualFields && <>
                    <Avatar
                        className={classes.orDivider}
                        sx={{
                            bgColor: theme.palette.semantic.colorBackgroundGray,
                            color: theme.palette.semantic.colorForegroundDeEmp,
                            fontSize: '12px',
                            fontWeight: 600,
                            lineHeight: '16px',
                            height: '28px',
                            width: '28px',
                        }}>
                        {translate({ id: 'CLIENT_OR' })}
                    </Avatar>
                    <Button
                        className={classes.manualFieldsButton}
                        variant="text"
                        onClick={() => setShowManualFields(true)}
                        data-cy="saml-show-manual-fields-button">
                        {translate({ id: 'CLIENT_ENTER_SAML_DETAILS_MANUALLY' })}
                    </Button>
                </>}
                {showManualFields && <>
                    <TextField
                        required
                        label={translate({ id: 'CLIENT_SIGN_ON_URL' })}
                        InputLabelProps={{ id: 'signOnUrlLabel' }}
                        inputProps={{
                            'aria-labelledby': 'signOnUrlLabel',
                            ...register('SingleSignOnServiceUrl', {
                                required: true,
                                validate: p => !!p.trim(),
                            }),
                        }}
                        placeholder={translate({ id: 'CLIENT_ENTER_URL_HERE' })}
                        variant="outlined"
                        fullWidth
                        className={classes.field}
                        error={!!errors.SingleSignOnServiceUrl}
                        helperText={
                            errors.SingleSignOnServiceUrl?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                        }
                        data-cy="single-sign-on-service-url"
                        disabled={metadataStatus.loading}
                    />
                    <TextField
                        required
                        label={translate({ id: 'CLIENT_IDENTITY_PROVIDER_ENTITY_ID' })}
                        InputLabelProps={{ id: 'identityProviderEntityLabel' }}
                        inputProps={{
                            'aria-labelledby': 'identityProviderEntityLabel',
                            ...register('IdentityProviderEntityId', {
                                required: true,
                                validate: p => !!p.trim(),
                            }),
                        }}
                        placeholder={translate({ id: 'CLIENT_ENTER_ID_HERE' })}
                        variant="outlined"
                        fullWidth
                        className={classes.field}
                        error={!!errors.IdentityProviderEntityId}
                        helperText={
                            errors.IdentityProviderEntityId?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                        }
                        data-cy="identity-provider-entity-id-field"
                        disabled={metadataStatus.loading}
                    />
                    <Controller
                        name="SigningCertificateLocation.CertificateText"
                        control={control}
                        rules={{
                            required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }),
                            validate: { invalid: async p => (!!p.trim() && (await validateCertificate(p.trim()))) },
                        }}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                required
                                error={!!errors.SigningCertificateLocation?.CertificateText}
                                fullWidth
                                className={classes.field}
                                inputProps={{ 'aria-labelledby': 'signingCertificateThumbprintLabel' }}
                                helperText={
                                    (
                                        (errors.SigningCertificateLocation?.CertificateText?.type === 'required'
                                            && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                                        || (errors.SigningCertificateLocation?.CertificateText?.type === 'invalid'
                                            && translate({ id: 'CLIENT_SIGNING_CERTIFICATE_INVALID' }))
                                        || <FormHelperText>
                                            <span style={{ color: 'red' }}>
                                                *
                                            </span>
                                            {translate({ id: 'CLIENT_SAML_CERT_HELPER_TEXT' })}
                                        </FormHelperText>
                                    )
                                }
                                label={
                                    <div className={classes.fieldLabel}>
                                        {translate({ id: 'CLIENT_SIGNING_CERTIFICATE_THUMBPRINT' })}
                                        <Tooltip
                                            title={translate({ id: 'CLIENT_PASTE_THE_CERTIFICATE' })}>
                                            <PortalCustomIcon
                                                name='info'
                                                size='15px' />
                                        </Tooltip>
                                    </div>
                                }
                                multiline
                                rows={8}
                                data-cy="signing-certificate-field"
                                disabled={metadataStatus.loading}
                                variant="outlined"
                            />}
                    />
                </>}
            </Box>
        </Grid>
    );
};

export default SAMLConfigureIDPPageComponent;
