import type {
    CatalogResponse,
    Persona,
} from '@experiences/interfaces';
import { get } from '@experiences/util';

export async function getRecommendations(
    accessToken: string,
    accountLogicalName: string,
    persona: Persona,
    locale: string,
    zoneId: string) {
    return await get<CatalogResponse>('/api/catalog/getRecommendations', {
        accessToken,
        urlParams: {
            accountLogicalName,
            persona,
            locale,
            zoneId,
        },
    });
}
