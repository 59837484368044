import type {
    ActionHandle,
    Header,
    Row,
    WidgetAction,
    WidgetContext,
} from '@experiences/interfaces';
import {
    HomePageEvent,
    logEvent,
} from '@experiences/telemetry';
import { ApExpSimpleTable } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useContext,
    useEffect,
} from 'react';

import { WidgetFrameContext } from '../context/WidgetFrameContext';

export interface UiTableWidgetProps<T> {
    id: string;
    rows: Array<Row<T>>;
    headers: Header[];
    columns: number;
    actions?: WidgetAction[];
    actionHandles?: Array<ActionHandle<T>>;
    clickHandler?: (data: T, ctx: WidgetContext) => void;
}

const UiTableWidget = <T extends object>({
    rows, id, headers, columns, clickHandler, actionHandles, actions,
}: UiTableWidgetProps<T>) => {
    const top5 = rows.slice(0, 5);
    const tableRef = React.createRef<HTMLApExpSimpleTableElement>();
    const context = useContext(WidgetFrameContext);

    const actionEventHandler = useCallback((event: any) => {
        const action = actionHandles?.find((a => a.id === event.detail.actionId));
        if (action) {
            logEvent('table action clicked', {
                name: HomePageEvent.TableActionClicked,
                code: '[IN-HP-08]',
            }, {
                ActionName: action.id,
                Persona: context.persona,
            });

            if (event?.detail?.rowData?.data) {
                action.handle(event.detail.rowData.data, context);
                return;
            }

            logEvent('table action failed to get rowData', {
                name: HomePageEvent.TableActionClicked,
                code: '[IN-HP-09]',
                isError: true,
            }, {
                ActionName: action.id,
                Persona: context.persona,
                rowData: event.detail.rowData,
            });
        }
    }, [ actionHandles, context ]);

    const cellTextClicked = useCallback((event: any) => {
        logEvent('table row clicked', {
            name: HomePageEvent.TableRowClicked,
            code: '[IN-HP-10]',
        }, {
            rowData: event.detail.rowData,
            Persona: context.persona,
        });

        if (event?.detail?.rowData?.data && clickHandler) {
            clickHandler(event.detail.rowData.data, context);
            return;
        }

        logEvent('table row clicked failed to execute', {
            name: HomePageEvent.TableRowClicked,
            code: '[IN-HP-11]',
            isError: true,
        }, {
            rowData: event.detail.rowData,
            Persona: context.persona,
        });
    }, [ clickHandler, context ]);

    useEffect(() => {
        const { current } = tableRef;

        if (!current) {
            return;
        }

        current.addEventListener('actionClicked', actionEventHandler);
        current.addEventListener('cellTextClicked', cellTextClicked);

        return () => {
            current.removeEventListener('actionClicked', actionEventHandler);
            current.removeEventListener('cellTextClicked', cellTextClicked);
        };
    }, [ actionEventHandler, cellTextClicked, tableRef ]);

    return (
        <ApExpSimpleTable
            ref={tableRef}
            data-testid={`simple-table-${id}`}
            table-id={id}
            rows={JSON.stringify(top5)}
            headers={JSON.stringify(headers)}
            actions={JSON.stringify(actions)}
            columns={columns}
        />
    );
};

export default UiTableWidget;
