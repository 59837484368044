import { TelemetryNamespace } from '@experiences/constants';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import type { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import type {
    IEventTelemetry,
    ITelemetryItem,
    ITraceTelemetry,
} from '@microsoft/applicationinsights-web';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { sanitizeUrl } from '@uipath/portal-shell-util';
import ContextTagKeys from 'applicationinsights/out/Declarations/Contracts/Generated/ContextTagKeys';
import { createBrowserHistory } from 'history';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import type { TrackMap } from './events/TrackMap';
import {
    getTelemetryClientSeverityLevel,
    getTracker,
} from './tracker';

const emailPiiRegex = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+/g;
const emailPiiMask = '<PII_EMAIL>';

// For pageview events that has token, we don't want to log them as they have the token.
// calls to /unregistered are cluttering up app insights instances, so we don't log those either
const AI_FILTER_URI_LIST = [ 'token', 'unregistered' ];

let appInsights: ApplicationInsights;

function removeAuthHeader(data: any) {
    delete data?.error?.config?.headers?.Authorization;
}

const getTelemetryInfo = () => ({
    EnableTelemetryClientWeb: getFeatureFlagValue(Features.EnableTelemetryClientWeb.name),
    disableTelemetry: window.env?.APPLICATION_INSIGHTS_ENABLED === 'false' || (
        !window.env?.APPLICATION_INSIGHTS_CONNECTION_STRING && !window.env?.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY),
});

export function initializeTelemetryPortal() {
    const {
        EnableTelemetryClientWeb, disableTelemetry,
    } = getTelemetryInfo();

    if (EnableTelemetryClientWeb) {
        const userLocale = localStorage.getItem('PORTAL_LOC_CONTEXT') ?? document.documentElement.lang;
        const isLocal = window.location.origin.includes('localhost');
        const tracker = getTracker();

        tracker.initialize({
            isDevelopment: isLocal,
            language: userLocale,
            isPageViewTrackingEnabled: true,
            isPageViewPerformanceTrackingEnabled: true,
            pageViewTrackingOptions: {
                name: document.title,
                uri: sanitizeUrl(location.href),
            },
        });
        window.addEventListener('popstate', () => tracker.trackNavigation({
            targetRoute: location.pathname,
            invocationSource: 'history',
        }));
    } else {
        const reactPlugin = new ReactPlugin();

        const browserHistory = createBrowserHistory();
        const diagnosticTelemetryEnabled = process.buildConfigs.diagnosticTelemetry;

        appInsights = new ApplicationInsights({
            config: {
                disableTelemetry,
                instrumentationKey: disableTelemetry ? 'no-key' : window.env?.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
                connectionString: disableTelemetry ? 'no-key' : window.env?.APPLICATION_INSIGHTS_CONNECTION_STRING,
                extensions: [ reactPlugin ],
                enableAutoRouteTracking: true,
                extensionConfig: { [reactPlugin.identifier]: { history: browserHistory } },
                disableExceptionTracking: !diagnosticTelemetryEnabled,
                disableCorrelationHeaders: !diagnosticTelemetryEnabled,
            },
        });

        appInsights.loadAppInsights();

        const tagKeys = new ContextTagKeys();

        appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
            try {
                if (item?.name?.endsWith('Pageview')) {
                    const refUri = item?.baseData && item.baseData['refUri'];
                    if (refUri && AI_FILTER_URI_LIST.some(uri => refUri.toString().indexOf(uri) > -1)) {
                        return false;
                    }
                }

                item.baseData = item.baseData ?? {};
                item.baseData.properties = item.baseData.properties || {};
                item.baseData.properties['Canary'] = window.env?.CANARY;
                item.baseData.properties['ScaleUnitIndex'] = window.env?.SCALE_UNIT_INDEX;
                item.baseData.properties['ScaleUnitId'] = window.env?.SCALE_UNIT_ID;
                item.baseData.properties['Region'] = window.env?.REGION;

                item.tags = item.tags ?? [];
                item.tags[tagKeys.cloudRole] = `portal-fe-${window.env.ENVIRONMENT}-${window.env.REGION}-${window.env.SCALE_UNIT_INDEX}`;

                removeAuthHeader(item.data);
                return;
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                return false;
            }
        });

        appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
            if (window.env?.APPLICATION_INSIGHTS_CLUSTER_ID && item.baseData) {
                item.baseData.properties = item.baseData.properties || {};
                item.baseData.properties['ClusterId'] = window.env?.APPLICATION_INSIGHTS_CLUSTER_ID;
                item.baseData.properties['ApplicationName'] = window.env?.APPLICATION_INSIGHTS_APPLICATION_NAME;
                item.baseData.properties['ProductVersion'] = window.env?.CLOUDRPA_VERSION;
            }
        });

        appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
            try {
                const itemJson = JSON.stringify(item);
                const isEmailPiiFound = itemJson.search(emailPiiRegex) > -1;
                if (isEmailPiiFound) {
                    const sanitizedItemString = itemJson.replace(emailPiiRegex, emailPiiMask);
                    const itemObj = JSON.parse(sanitizedItemString);
                    Object.assign(item, itemObj);
                }
                return;
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                return false;
            }
        });

        // @ts-ignore
        appInsights.context.application = {
            build: window.env?.ENVIRONMENT,
            ver: window.env?.CLOUDRPA_VERSION,
        };
    }
}

function trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
    const {
        EnableTelemetryClientWeb, disableTelemetry,
    } = getTelemetryInfo();
    const tracker = getTracker();

    if (disableTelemetry) {
        return;
    }
    if (EnableTelemetryClientWeb) {
        tracker.trackEvent((event.name) as keyof TrackMap, omitBy({
            ...customProperties,
            ...event.properties,
        }, isNil));
    } else {
        appInsights.trackEvent(event, customProperties);
    }

}
function trackTrace(trace: ITraceTelemetry, customProperties?: ICustomProperties) {
    const {
        EnableTelemetryClientWeb, disableTelemetry,
    } = getTelemetryInfo();
    const tracker = getTracker();

    if (disableTelemetry) {
        return;
    }
    if (EnableTelemetryClientWeb) {
        tracker.trackTrace(
            trace.message,
            trace.severityLevel ? getTelemetryClientSeverityLevel(trace.severityLevel) : undefined,
            customProperties);
    } else {
        appInsights.trackTrace(trace, customProperties);
    }
}

function flush() {
    const {
        EnableTelemetryClientWeb, disableTelemetry,
    } = getTelemetryInfo();

    if (disableTelemetry) {
        return;
    }
    if (!EnableTelemetryClientWeb) {
        appInsights.flush();
    }
}

const methods = {
    trackEvent,
    trackTrace,
    flush,
};

(window as any).addEventListener(TelemetryNamespace.Portal.TrackTrace, (event: CustomEvent) => {
    trackTrace({ message: event.detail.message }, event.detail.customProperties);
});

export { methods as portalTelemetry };
