import { AccountLicense } from '@experiences/constants';
import { useGetErrorInfo } from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    UiSelect,
    UiText,
} from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import {
    deleteLogsExportDetails,
    exportLogsUrl,
    getLogsExportDetails,
    postLogsExportDetails,
} from '../../../services/orchestrator/OrchLogsExportService';
import {
    accountType,
    isAdminSelector,
} from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import {
    FormActionButtons,
    FormErrorHandler,
} from './LogExportFormComponents';

interface IConfigureRobotLogsData {
    blobStorageType: 'AzureBlob' | 'AwsS3' | 'GcpStorage' | undefined;
    blobConnectionString: string;
    blobContainerName: string;
    blobRegionName: string;
}

const useStyles = makeStyles(theme =>
    createStyles({
        input: {
            marginTop: '20px',
            paddingLeft: '12px',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        formButtons: { display: 'flex' },
        cancelButton: { marginRight: '10px' },
        breakAll: { wordBreak: 'break-all' },
    }),
);

const OrchLogExportComponent: React.FC<{
    tenantId: string;
    tenantName: string;
}> = ({
    tenantId,
    tenantName,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ submitError, setSubmitError ] = useState('');
    const createNotification = useUiSnackBar();
    const LogExportInGov = useFeatureFlagValue(Features.LogExportInGov.name);

    const {
        open, close,
    } = useModalState(RouteNames.TenantServices.replace(':tenantId', tenantId));

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();

    const isAdmin = useSelector(isAdminSelector);
    const currentAccountType = useSelector(accountType);

    const createDialog = useShowDialog();

    const {
        data: blobFields,
        isValidating,
        error,
        mutate,
    } = useSWR(
        tenantId ? {
            url: exportLogsUrl,
            tenantKey: tenantId,
        } : null,
        getLogsExportDetails,
        { shouldRetryOnError: false }, // prevents loading screen showing if there is no config set up
    );

    const [ showBlobFields, setShowBlobFields ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: {
            errors, dirtyFields, isDirty, isValid,
        },
    } = useForm<IConfigureRobotLogsData>({
        mode: 'onSubmit',
        defaultValues: {
            blobStorageType: undefined,
            blobConnectionString: '',
            blobContainerName: '',
            blobRegionName: '',
        },
    });

    const watchStorageType = watch('blobStorageType');

    const defaultBlobStorageType = useMemo(() => {
        if (blobFields?.storageType === 'AwsS3') {
            return 'AwsS3';
        } else if (blobFields?.storageType === 'GcpStorage') {
            return 'GcpStorage';
        } else if (blobFields?.storageType === 'AzureBlob') {
            return 'AzureBlob';
        }
        return undefined;
    }, [ blobFields ]);

    const defaultBlobContainerName = useMemo(() => {
        switch (defaultBlobStorageType) {
            case 'AzureBlob':
                return blobFields?.connectionDetail?.ContainerName || 'uipathrobotlogs';
            case 'AwsS3':
                return blobFields?.connectionDetail?.BucketName || '';
            case 'GcpStorage':
                return blobFields?.connectionDetail?.Bucket || '';
            default:
                return '';
        }
    }, [ blobFields, defaultBlobStorageType ]);

    const isSubmitDisabled = useMemo(() => {
        if (watchStorageType === defaultBlobStorageType && !isDirty) {
            return true;
        }
        return !isValid && !isDirty;
    }, [ watchStorageType, defaultBlobStorageType, isDirty, isValid ]);

    useEffect(() => {
        if (!isValidating && !error) {
            setShowBlobFields(!!defaultBlobStorageType);
            reset({
                blobStorageType: defaultBlobStorageType,
                blobConnectionString: blobFields?.connectionDetail?.ConnectionString || '',
                blobContainerName: defaultBlobContainerName,
                blobRegionName: blobFields?.connectionDetail?.RegionSystemName || '',
            });
        }
    }, [ blobFields, isValidating, error, reset, defaultBlobStorageType, defaultBlobContainerName ]);

    useEffect(() => {
        if (dirtyFields.blobStorageType && watchStorageType) {
            if (watchStorageType !== blobFields?.storageType) {
                reset({
                    blobStorageType: watchStorageType,
                    blobConnectionString: '',
                    blobContainerName: watchStorageType === 'AzureBlob' ? 'uipathrobotlogs' : '',
                    blobRegionName: '',
                });
            } else {
                reset({
                    blobStorageType: watchStorageType,
                    blobConnectionString: blobFields?.connectionDetail?.ConnectionString || '',
                    blobContainerName: defaultBlobContainerName,
                    blobRegionName: blobFields?.connectionDetail?.RegionSystemName || '',
                });
            }
        }
    }, [ reset, watchStorageType, blobFields, defaultBlobContainerName, dirtyFields.blobStorageType ]);

    const storageAccounts = useMemo(
        () => ({
            gcp: window.env?.LOG_EXPORT_GCP_ACCOUNT_NAME,
            aws: window.env?.LOG_EXPORT_AWS_ACCOUNT_NAME,
        }),
        [],
    );

    const deleteConfig = useCallback(async () => {
        if (!defaultBlobStorageType || !tenantId) {
            setShowBlobFields(showFields => !showFields);
            return;
        }

        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_LOGS_DELETE' }),
            body: translate({ id: 'CLIENT_LOGS_DELETE_DETAILS' }),
            icon: 'warning',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
        });
        if (proceed) {
            try {
                await deleteLogsExportDetails(tenantId || '');
                createNotification(translate({ id: 'CLIENT_LOGS_DELETE_SUCCESS' }), notificationType.SUCCESS);
                setShowBlobFields(showFields => !showFields);
                mutate(
                    {
                        containerName: '',
                        tenantKey: blobFields?.tenantKey,
                        storageType: undefined,
                        connectionDetail: {},
                    },
                    true,
                );
                reset({
                    blobStorageType: undefined,
                    blobConnectionString: '',
                    blobContainerName: '',
                    blobRegionName: '',
                });
            } catch (error) {
                createNotification(translate({ id: 'CLIENT_LOGS_DELETE_ERROR' }), notificationType.SUCCESS);
            }
        }
    }, [
        defaultBlobStorageType,
        tenantId,
        createDialog,
        translate,
        createNotification,
        mutate,
        blobFields?.tenantKey,
        reset,
    ]);

    const onSubmit = useCallback(
        async (data: IConfigureRobotLogsData) => {
            setLoading(true);
            let proceed = true;
            if (data.blobStorageType !== 'AzureBlob') {
                proceed = await createDialog({
                    title: translate({ id: 'CLIENT_GRANT_ACCESS' }),
                    body:
                        data.blobStorageType === 'AwsS3'
                            ? translate({ id: 'CLIENT_AWS_MESSAGE' }, { 0: storageAccounts.aws })
                            : translate({ id: 'CLIENT_GCP_MESSAGE' }, { 0: storageAccounts.gcp }),
                    primaryButtonText: translate({ id: 'CLIENT_GRANTED_ACCESS' }),
                });
            }
            if (proceed) {
                try {
                    await postLogsExportDetails({
                        tenantKey: tenantId || '',
                        connectionString: data.blobConnectionString,
                        containerName: data.blobContainerName,
                        storageType: data.blobStorageType,
                        RegionSystemName: data.blobRegionName,
                    });
                    createNotification(translate({ id: 'CLIENT_LOGS_CONFIG_SUCCESS' }, { 0: tenantName }), notificationType.SUCCESS);
                    setLoading(false);
                    close(true);
                } catch (error) {
                    const errorObject = await getErrorObject(error);
                    setSubmitError(errorObject.response?.data || (await getErrorMessage(errorObject)));
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        },
        [
            close,
            createNotification,
            tenantId,
            tenantName,
            translate,
            createDialog,
            storageAccounts,
            getErrorMessage,
            getErrorObject,
        ],
    );

    const storageTypes = useMemo(() => {
        const types: any = { AzureBlob: 'CLIENT_AZURE_BLOB' };
        types['AwsS3'] = 'CLIENT_AWS_S3';
        types['GcpStorage'] = 'CLIENT_GCP';
        return types;
    }, []);

    const govStorageTypes = useMemo(() => {
        const types: any = { AzureBlob: 'CLIENT_AZURE_BLOB' };
        return types;
    }, []);

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_CONFIGURATION' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            loading={isValidating}
        >
            <FormErrorHandler
                error={error}
                submitError={submitError}
                setSubmitError={setSubmitError}
                currentAccountType={currentAccountType}
            />
            {isAdmin &&
                AccountLicense[currentAccountType] !== AccountLicense.COMMUNITY &&
                !submitError &&
                (!error || error.response?.status === 404) && (
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <FormActionButtons
                            showActions={showBlobFields}
                            classes={classes}
                            loading={loading}
                            onSubmit={handleSubmit(onSubmit)}
                            isSubmitDisabled={isSubmitDisabled}
                            close={close}
                        />
                    }
                    isDrawer
                >
                    <div className={classes.input}>
                        <UiText className={classes.inputLabel}>
                            {translate({ id: 'CLIENT_ROBOT_LOGS_EXPORT' })}
                        </UiText>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showBlobFields}
                                    onClick={() => {
                                        if (showBlobFields) {
                                            deleteConfig();
                                        } else {
                                            setShowBlobFields(showFields => !showFields);
                                        }
                                    }}
                                />
                            }
                            label={translate({ id: 'CLIENT_SEND_LOGS' })}
                            data-cy="logs-switch"
                        />
                    </div>
                    {showBlobFields && (
                        <>
                            <div className={classes.input}>
                                <UiSelect
                                    control={control}
                                    name="blobStorageType"
                                    inputLabel={translate({ id: 'CLIENT_BLOB_STORAGE_TYPE' })}
                                    error={!!errors.blobStorageType}
                                    helperText={
                                        errors.blobStorageType?.type === 'required' &&
                                            translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                                    }
                                    options={LogExportInGov ? govStorageTypes : storageTypes}
                                    required
                                    fullWidth
                                    dataCy="logs-storage-type"
                                />
                            </div>
                            {watchStorageType && (
                                <>
                                    {watchStorageType === 'AzureBlob' && (
                                        <div className={classes.input}>
                                            <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                                                {translate({ id: 'CLIENT_BLOB_CONNECTION_STRING' })}
                                            </UiText>
                                            <Controller
                                                control={control}
                                                rules={{ required: watchStorageType === 'AzureBlob' }}
                                                name="blobConnectionString"
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        type="password"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputProps={{ className: 'Tall' }}
                                                        error={!!errors.blobConnectionString}
                                                        helperText={
                                                            errors.blobConnectionString?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                                                        }
                                                        data-cy="logs-connection-string"
                                                    />
                                                )}
                                            />
                                        </div>
                                    )}

                                    <div className={classes.input}>
                                        <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                                            {watchStorageType === 'AzureBlob'
                                                ? translate({ id: 'CLIENT_BLOB_CONTAINER_NAME' })
                                                : translate({ id: 'CLIENT_BLOB_BUCKET_NAME' })}
                                        </UiText>
                                        <Controller
                                            control={control}
                                            rules={{ required: watchStorageType !== 'AzureBlob' }}
                                            name="blobContainerName"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{ className: 'Tall' }}
                                                    error={!!errors.blobContainerName}
                                                    helperText={
                                                        (errors.blobContainerName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                                                        || (watchStorageType === 'AzureBlob' && translate({ id: 'CLIENT_DEFAULT_CONTAINER_NAME_HELPER' }))
                                                    }
                                                    data-cy="logs-container-name"
                                                />
                                            )}
                                        />
                                    </div>

                                    {watchStorageType === 'AwsS3' && (
                                        <div className={classes.input}>
                                            <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                                                {translate({ id: 'CLIENT_BLOB_REGION_NAME' })}
                                            </UiText>
                                            <Controller
                                                control={control}
                                                rules={{ required: watchStorageType === 'AwsS3' }}
                                                name="blobRegionName"
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        variant="outlined"
                                                        fullWidth
                                                        InputProps={{ className: 'Tall' }}
                                                        error={!!errors.blobRegionName}
                                                        helperText={
                                                            errors.blobRegionName?.type === 'required'
                                                                ? translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                                                                : `${translate({ id: 'CLIENT_BLOB_REGION_HELPER' })} us-west-1`
                                                        }
                                                        data-cy="logs-region-name"
                                                    />
                                                )}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </UiForm>
            )}
        </UiDrawer>
    );
};

export default OrchLogExportComponent;
