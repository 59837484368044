interface IServiceMap {
    id: string;
    name: string;
    orgService?: boolean;
    customIcon?: string;
}

const serviceMap: IServiceMap[] = [
    {
        id: 'orchestrator',
        name: 'Orchestrator',
    },
    {
        id: 'portal',
        name: 'Cloud Portal',
    },
    {
        id: 'aifabric',
        name: 'AI Center',
    },
    {
        id: 'actions',
        name: 'Actions',
    },
    {
        id: 'apps',
        name: 'Apps',
        orgService: true,
    },
    {
        id: 'processes',
        name: 'Process Launcher',
    },
    {
        id: 'chatbots',
        name: 'Chatbots',
    },
    {
        id: 'dataservice',
        name: 'Data Service',
    },
    {
        id: 'aifabric',
        name: 'AI Center',
    },
    {
        id: 'aiservices',
        name: 'AI Services',
        customIcon: 'aifabric',
    },
    {
        id: 'testmanager',
        name: 'Test Manager',
    },
    {
        id: 'admin',
        name: 'Admin',
    },
    {
        id: 'automationhub',
        name: 'Automation Hub',
    },
    {
        id: 'insights',
        name: 'Insights',
    },
    {
        id: 'taskmining',
        name: 'Task Mining',
    },
    {
        id: 'processmining',
        name: 'Process Mining',
    },
    {
        id: 'automationstore',
        name: 'Automation Store',
    },
    {
        id: 'connections',
        name: 'Integration Service',
        customIcon: 'integrations',
    },
    {
        id: 'du',
        name: 'Document Understanding',
    },
    {
        id: 'reinfer',
        name: 'Communications Mining',
    },
    {
        id: 'studio',
        name: 'Studio Web',
        orgService: true,
    },
    {
        id: 'roboticsops',
        name: 'Automation Ops',
        orgService: true,
    },
];

export function getOrganizationServiceIds() {
    return serviceMap.filter(service => service.orgService).map(service => service.id);
}

export function getFriendlyName(id: string, useIdDefault = true) {
    const friendlyName = serviceMap.find(service => service.id === id)?.name;
    if (friendlyName) {
        return friendlyName;
    }

    return useIdDefault ? id : 'Service';
}

export function getIconName(id: string) {
    const customIcon = serviceMap.find(service => service.id === id)?.customIcon;
    if (customIcon) {
        return customIcon;
    }
    return id;
}
