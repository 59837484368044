import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { makeStyles } from '@mui/styles';
import {
    ApStepper,
    ApStepperStep,
} from '@uipath/portal-shell-react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px',
        marginLeft: '-8px',
        marginRight: '-8px',
        backgroundColor: 'unset',
        marginBottom: '24px',
    },
    optionalLabel: {
        fontSize: '10px',
        lineHeight: '16px',
        color: theme.palette.semantic.colorForeground,
    },
}));

const SecuritySettingsSAMLFormStepper: React.FC<{ activeStep: number }> = ({ activeStep }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const EnableSAMLUXRefactor = useFeatureFlagValue(Features.EnableSAMLUXRefactor.name);

    const steps = useMemo(
        () => EnableSAMLUXRefactor
            ? [
                { label: 'CLIENT_BEFORE_YOU_BEGIN' },
                { label: 'CLIENT_CONFIGURE_IDP' },
                { label: 'CLIENT_ATTRIBUE_MAPPING' },
            ]
            : [
                { label: 'CLIENT_GENERAL_DETAILS' },
                { label: 'CLIENT_PROVISIONING_SETTINGS' },
                { label: 'CLIENT_ADVANCED_DETAILS' },
            ],
        [ EnableSAMLUXRefactor ],
    );

    return (
        <ApStepper
            data-cy='saml-form-stepper'
            activeStep={activeStep}
            className={classes.root}>
            {steps.map((step, index) => (
                <ApStepperStep
                    key={index}
                    label={translate({ id: step.label })} />

            ))}
        </ApStepper>
    );
};

export default SecuritySettingsSAMLFormStepper;
