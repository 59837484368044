import { getTracker } from '@experiences/telemetry';
import { AuthContext } from '@experiences/util';
import { useSignoutRedirect } from '@uipath/auth-react';
import React, { useCallback } from 'react';

import useUserInfo from '../hooks/UserInfo';

const IdentityAuthProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const {
        token, email,
    } = useUserInfo();
    const signoutRedirect = useSignoutRedirect();

    const logout = useCallback(
        (returnUrl?: string) => {
            signoutRedirect({ post_logout_redirect_uri: returnUrl });
        },
        [ signoutRedirect ],
    );

    const tracker = getTracker();

    tracker.setMetadata(
        { IsTestAccount: email?.split('@')[1] === 'uipath-qa.com' }
    );
    tracker.trackLogin({ authenticationMethod: 'Auto' });
    tracker.trackApplicationStart();
    return (
        <AuthContext.Provider
            value={{
                token,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default IdentityAuthProvider;

