import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import isString from 'lodash/isString';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { deleteDirectoryIntegrationSetting } from '../../../services/identity/AuthenticationSettingService';
import { deleteDirectoryConnection } from '../../../services/identity/DirectoryConnectionService';
import {
    accountGlobalId,
    isHostModeSelector,
} from '../../../store/selectors';
import { UiDeleteButton } from '../../common/UiDeleteButton/UiDeleteButton';

const useStyles = makeStyles((theme) =>
    createStyles({
        button: { marginRight: '10px' },
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        buttonInner: { width: '100px' },
        cancelButton: { marginRight: '10px' },
        deleteSSOConfirmation: { maxWidth: '466px' },
    }),
);

const DeleteSSOConfigurationDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, connectionId, refreshCallback,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const partitionGlobalId = useSelector(accountGlobalId);
    const isHostMode = useSelector(isHostModeSelector);

    const [ loading, setLoading ] = useState(false);
    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const deleteAndClose = useCallback(async () => {
        try {
            setLoading(true);
            if (isHostMode) {
                await deleteDirectoryConnection(connectionId);

            } else {
                await deleteDirectoryIntegrationSetting(partitionGlobalId);
            }
            closeDialog();
            setLoading(false);
            refreshCallback();
        } catch (error) {
            setLoading(false);
            const errorObject = await getErrorObject(error);
            const data = errorObject.response?.data;
            const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
            setErrorMessage(errorResponse);
        }
    }, [
        closeDialog,
        connectionId,
        getErrorMessage,
        getErrorObject,
        isHostMode,
        partitionGlobalId,
        refreshCallback,
        setErrorMessage,
    ]);

    return (
        <div className={classes.deleteSSOConfirmation}>
            <UiText data-cy="delete-sso-warning">
                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_DELETE_TEXT_LINE_1_REVAMP' })}
            </UiText>
            <br />
            <UiText data-cy="delete-sso-ask">
                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_DELETE_TEXT_LINE_2' })}
            </UiText>

            <div className={classes.buttonContainer}>
                <Button
                    className={classes.cancelButton}
                    color="primary"
                    data-cy="delete-sso-cancel-button"
                    onClick={() => closeDialog()}
                >
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>
                <UiDeleteButton
                    loading={loading}
                    variant="contained"
                    data-cy="delete-sso-confirmation-button"
                    onClick={() => deleteAndClose()}
                >
                    {translate({ id: 'CLIENT_DELETE' })}
                </UiDeleteButton>
            </div>
        </div>
    );
};

export default DeleteSSOConfigurationDialogBody;
