import {
    logEvent,
    UsersEvent,
} from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import {
    UiDialog,
    UiProgressButton,
    UiText,
    useUiDataContext,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React, {
    createRef,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import { ManagingUsersBulkAddingLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import useSimpleGroup from '../../../common/hooks/SimpleGroup';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useLoginProvider } from '../../../common/hooks/useLoginProvider';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import {
    accountGlobalId,
    accountType,
    companyName,
    organizationLanguage,
} from '../../../store/selectors';
import {
    parseFile,
    processParsedData,
} from '../../../util/BulkInviteUtils';
import { UiGrid } from '../../common/UiGrid';
import type { BulkInviteUserData } from '../interfaces/user';

const useStyles = makeStyles(theme => GlobalStyles(theme));

const BulkInviteUserComponent: React.FC = () => {

    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const getLocalizedLinks = useDocumentationLinks();

    const orgLanguage = useSelector(organizationLanguage);
    const orgName = useSelector(companyName);
    const accountId = useSelector(accountGlobalId);
    const subscriptionPlan = useSelector(accountType);

    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();

    const { setData } = useUiDataContext<{ refresh: boolean }>();

    const {
        loading: groupLoading, groups,
    } = useSimpleGroup(true);
    const loginProvider = useLoginProvider();

    const [ loading, setLoading ] = useState(false);
    const [ showErrorSummary, setShowErrorSummary ] = useState(false);

    const inputRef = createRef<HTMLInputElement>();
    const [ file, setFile ] = useState<File | null>(null);
    const [ fileErrorId, setFileErrorId ] = useState<string>('');
    const [ badEntries, setBadEntries ] = useState<BulkInviteUserData[]>([]);

    const [ progressMessage, setProgressMessage ] = useState<string>('');
    const [ total, setTotal ] = useState<number>(0);
    const [ completed, setCompleted ] = useState<number>(0);

    const close = useCallback(
        (refresh?: boolean) =>
            navigate(getRoute(RouteNames.Users),
                { state: refresh ? { refresh: true } : undefined }),
        [ navigate, getRoute ],
    );

    const uploadFile = useCallback(
        async (fileData: File) => {
            setFileErrorId('');
            setCompleted(0);
            setTotal(0);
            setLoading(true);

            if (!fileData?.name?.endsWith('.csv')) {
                setFileErrorId('CLIENT_INVALID_CSV');
                setShowErrorSummary(true);
                setLoading(false);
                return;
            }

            setProgressMessage(translate({ id: 'CLIENT_PARSING_FILE' }, { 0: fileData.name }));
            const parsedData = await parseFile(fileData, groups);
            if (!parsedData || parsedData.length === 0) {
                setFileErrorId('CLIENT_ERROR_PARSING_CSV');
                setShowErrorSummary(true);
                setLoading(false);
                return;
            }

            const badData = parsedData.filter(data => data.errors.length > 0);
            setTotal(parsedData.length);
            setCompleted(c => c + badData.length);

            if (badData.length > 0) {
                setShowErrorSummary(true);
            }
            if (parsedData.length === badData.length) {
                setBadEntries(badData);
                setLoading(false);
                return;
            }

            const failedUsers = await processParsedData(
                parsedData,
                setCompleted,
                accountId,
                orgLanguage,
                orgName,
                loginProvider,
            );

            logEvent('Invited Users', {
                name: UsersEvent.BulkInvite,
                code: 'INV-BULK-05',
            }, {
                'SubscriptionType': subscriptionPlan,
                'NoOfAddedUsers': (parsedData.length - failedUsers.length).toString(),
                'GroupMembership': parsedData.map((el) => [ el.email, el.groupNames ]),
            });

            if (badData.length > 0 || failedUsers.length > 0) {
                setBadEntries([ ...badData, ...failedUsers ]);
                setShowErrorSummary(true);
                setLoading(false);
                setData({ refresh: true });
            } else {
                createNotification(translate({ id: 'CLIENT_IMPORT_SUCCESSFUL' }), notificationType.SUCCESS);
                setData({ refresh: true });
                close(true);
            }
        },
        [
            translate,
            groups,
            accountId,
            orgLanguage,
            orgName,
            loginProvider,
            subscriptionPlan,
            setData,
            createNotification,
            close,
        ],
    );

    useEffect(() => {
        if (file) {
            uploadFile(file);
            setFile(null);
        }
    }, [ file, uploadFile, setFile ]);

    useEffect(() => {
        if (loading) {
            setProgressMessage(translate({ id: 'CLIENT_IMPORTING_USERS_PROGRESS' }, {
                0: completed,
                1: total,
            }));
        }
    }, [ completed, total, translate, loading ]);

    const dialogActions = useMemo(() => {
        if (loading) {
            return [];
        }

        const actions = [];
        if (showErrorSummary) {
            actions.push(
                <Button
                    key="ok"
                    variant="contained"
                    data-cy="button-ok"
                    onClick={() => close(true)}>
          OK
                </Button>,
            );
        } else {
            actions.push(
                <Button
                    key="cancel"
                    data-cy="button-cancel"
                    onClick={() => close(showErrorSummary)}
                    color="primary">
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>,
                <UiProgressButton
                    loading={groupLoading}
                    key="upload"
                    variant="contained"
                    data-cy="button-upload"
                    onClick={() => inputRef.current?.click()}
                >
                    {translate({ id: 'CLIENT_UPLOAD_FILE' })}
                </UiProgressButton>,
            );
        }

        return actions;
    }, [ showErrorSummary, close, groupLoading, inputRef, translate, loading ]);

    return (
        <>
            <input
                ref={inputRef}
                type="file"
                style={{ display: 'none' }}
                accept=".csv"
                onChange={e => e.target.files?.[0] && setFile(e.target.files[0])}
                data-cy="file-input"
            />
            <Backdrop
                open={loading}
                style={{ zIndex: 1300 }}>
                <CircularProgress
                    style={{
                        color: Tokens.Colors.ColorWhite,
                        marginRight: '20px',
                    }}
                    size={44}
                    variant="indeterminate"
                />
                <UiText style={{
                    fontSize: '24px',
                    color: Tokens.Colors.ColorWhite,
                }}>
                    {progressMessage}
                </UiText>
            </Backdrop>
            <UiDialog
                title={
                    showErrorSummary && !loading
                        ? translate({ id: 'CLIENT_ERROR_SUMMARY' })
                        : translate({ id: 'CLIENT_UPLOAD_CSV' })
                }
                close={() => close(false)}
                dialogProps={{
                    open: !loading,
                    maxWidth: 'xl',
                    sx: { minWidth: '700px' },
                    PaperProps: { style: { minWidth: showErrorSummary ? '700px' : undefined } },
                }}
                actions={dialogActions}
            >
                {showErrorSummary ? (
                    <>
                        <UiText style={{ marginBottom: '24px' }}>
                            {fileErrorId
                                ? translate({ id: fileErrorId })
                                : translate({ id: 'CLIENT_BULK_ERROR_SUMMARY' }, {
                                    0: badEntries.length,
                                    1: total,
                                })}
                        </UiText>
                        {!fileErrorId && (
                            <div style={{ height: '500px' }}>
                                <UiGrid<BulkInviteUserData>
                                    data-cy="error-summary-grid"
                                    tableHeight="500px"
                                    tableId="bulk-invite-error-summary"
                                    columns={[
                                        {
                                            accessor: 'email',
                                            Header: translate({ id: 'CLIENT_EMAIL' }),
                                            Cell: ({ row }) => {
                                                const email = row.original.email;
                                                return (
                                                    <Tooltip
                                                        arrow
                                                        placement="bottom-start"
                                                        title={email}>
                                                        <span>
                                                            {email}
                                                        </span>
                                                    </Tooltip>
                                                );
                                            },
                                            width: 200,
                                            disableSortBy: true,
                                        },
                                        {
                                            accessor: 'groupNames',
                                            Header: translate({ id: 'CLIENT_GROUP_S' }),
                                            Cell: ({ row }) => {
                                                const groups = row.original.groupNames.join(', ');
                                                return (
                                                    <Tooltip
                                                        arrow
                                                        placement="bottom-start"
                                                        title={groups}>
                                                        <span>
                                                            {groups}
                                                        </span>
                                                    </Tooltip>
                                                );
                                            },
                                            width: 200,
                                            disableSortBy: true,
                                        },
                                        {
                                            accessor: 'errors',
                                            Header: translate({ id: 'CLIENT_FAILED_REASON' }),
                                            Cell: ({ row }) => {
                                                const errors = row.original.errors.map(id => translate({ id })).join(' ');
                                                let errorsTooltip = errors;
                                                if (row.original.errors.find(err => err === 'CLIENT_EXISTING_OR_SYSTEM_ERROR')) {
                                                    errorsTooltip = `${errors} ${translate({ id: 'CLIENT_RECHECK_EXISTING' })}`;
                                                }
                                                return (
                                                    <Tooltip
                                                        arrow
                                                        placement="bottom-start"
                                                        title={errorsTooltip}>
                                                        <span>
                                                            {errors}
                                                        </span>
                                                    </Tooltip>
                                                );
                                            },
                                            width: 400,
                                            disableSortBy: true,
                                        },
                                    ]}
                                    data={badEntries}
                                />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <UiText>
                            {translate({ id: 'CLIENT_CSV_INFO' })}
                        </UiText>
                        <a
                            className={classes.a}
                            href={getLocalizedLinks({ articleSlug: ManagingUsersBulkAddingLink })}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {translate({ id: 'CLIENT_CSV_DOCS' })}
                        </a>
                    </>
                )}
            </UiDialog>
        </>
    );
};

export default BulkInviteUserComponent;
