import { Entitlements } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { ICard } from '@experiences/interfaces';
import { PermissionType } from '@experiences/interfaces';
import {
    OrgConsentEvent,
    PortalTagManagementEvent,
} from '@experiences/telemetry';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import { ReactComponent as AccountsAndGroupIcon } from '../../images/accountsAndGroupsAdmin.svg';
import { ReactComponent as AiTrustLayerAdmin } from '../../images/aiTrustLayerAdmin.svg';
import { ReactComponent as AuditLogsAdmin } from '../../images/auditLogsAdmin.svg';
import { ReactComponent as ExternalAppsAdmin } from '../../images/externalAppsAdmin.svg';
import { ReactComponent as LicensesAdmin } from '../../images/licensesAdmin.svg';
import { ReactComponent as MailSettingsAdmin } from '../../images/mailSettingsAdmin.svg';
import { ReactComponent as SecurityAdmin } from '../../images/securityAdmin.svg';
import { ReactComponent as SettingsAdmin } from '../../images/settingsAdmin.svg';
import { ReactComponent as TagsAdmin } from '../../images/tagsAdmin.svg';
import { ReactComponent as UserConsentAdmin } from '../../images/userConsentAdmin.svg';
import {
    getKmsConfig,
    kmsUrl,
} from '../../services/identity/KeyManagementService';
import {
    entitlementsUrl,
    isEntitled,
} from '../../services/licensing/EntitlementsService';
import {
    getPermissions,
    tagsUrl,
} from '../../services/orchestrator/TagsService.msi';
import {
    accountGlobalId,
    accountType,
    isHostModeSelector,
    isOrgAdminSelector,
} from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import { UiCard } from '../common/UiCard';
import UiCardGrid from '../common/UiCardGrid/UiCardGrid';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import EncryptionDowngradeWarningDialogBody
    from '../securitySettings/subcomponents/Encryption/EncryptionDowngradeWarningDialogBody';
import { hasPermission } from '../tags/addedit/properties/TenantTagsUtil';

const useStyles = makeStyles(() =>
    createStyles({
        cardIcon: {
            width: '30px',
            marginRight: '4px',
        },
    }),
);

const OrganizationAdminHome: React.FC = () => {
    const classes = useStyles();
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();
    const { formatMessage: translate } = useIntl();
    const { logEvent } = useTelemetryHelper();

    const isHost = useSelector(isHostModeSelector);
    const isOrgAdmin = useSelector(isOrgAdminSelector);
    const AccountType = useSelector(accountType);
    const accountId = useSelector(accountGlobalId);
    const createDialog = useShowDialog();

    const EnableCustomerManagedKeyEntitlement = useFeatureFlagValue(Features.EnableCustomerManagedKeyEntitlement.name);
    const EnableGovernanceModuleFederation = useFeatureFlagValue(Features.EnableGovernanceModuleFederation.name);
    const EnableOrgConsent = useFeatureFlagValue(Features.EnableAdminOrgConsent.name);

    const { data: hasEntitlement } = useSWR(
        EnableCustomerManagedKeyEntitlement ? {
            url: entitlementsUrl,
            entitlement: Entitlements.CustomerManagedKey,
        } : null,
        isEntitled,
    );

    const { data: kmsConfig } = useSWR(
        {
            url: kmsUrl,
            partitionGlobalId: accountId,
        },
        getKmsConfig,
    );

    const { data: permissions } = useSWR(
        process.buildConfigs.showForMSI ?
            {
                url: `${tagsUrl}/permissions`,
                accountLogicalName: '',
                tenantName: '',
                selectedAccountId: accountId,
            } : null,
        getPermissions,
    );

    useEffect(() => {
        if (EnableCustomerManagedKeyEntitlement && hasEntitlement != null && !hasEntitlement && kmsConfig?.keyType === 1) {
            createDialog({
                title: translate({ id: 'CLIENT_ENCRYPTION_DOWNGRADE_WARNING_TITLE' }),
                customDialogContent: EncryptionDowngradeWarningDialogBody,
                icon: 'warning',
            });
        }
    }, [ EnableCustomerManagedKeyEntitlement, createDialog, hasEntitlement, kmsConfig?.keyType, translate ]);

    const handleTagsClick = useCallback(() => {
        logEvent(PortalTagManagementEvent.CardClick);
        return getRoute(RouteNames.TenantTagsLabelsMsi);
    }, [ getRoute, logEvent ]);

    const handleOrgConsentClick = useCallback(() => {
        logEvent(OrgConsentEvent.CardClick);
        return getRoute(RouteNames.UserConsent);
    }, [ getRoute, logEvent ]);

    const cardList: ICard[] = useMemo(() => [
        {
            title: isHost ? 'CLIENT_USERS' : 'CLIENT_ACCOUNTS_AND_GROUPS',
            titleIcon: <AccountsAndGroupIcon />,
            description: isHost ? 'CLIENT_USERS_DESCRIPTION' : 'CLIENT_ACCOUNTS_AND_GROUPS_DESCRIPTION',
            onClickLink: getRoute(RouteNames.Users),
            disabled: !isOrgAdmin,
            dataCy: 'org-settings-users-card',
            alt: translate({ id: isHost ? 'CLIENT_USERS' : 'CLIENT_ACCOUNTS_AND_GROUPS' }),
        },
        {
            title: 'CLIENT_LICENSES',
            titleIcon: <LicensesAdmin />,
            description: isHost ? 'CLIENT_HOST_LICENSES_DESCRIPTION' : 'CLIENT_LICENSES_DESCRIPTION',
            onClickLink: getRoute(RouteNames.Licensing),
            chipText: AccountType ? `CLIENT_${AccountType.toUpperCase()}_PLAN_ADMIN` : 'CLIENT_UNLICENSED',
            dataCy: 'org-settings-licenses-card',
            disabled: !isOrgAdmin,
            invisible: process.buildConfigs.hideForMSI,
            alt: translate({ id: 'CLIENT_LICENSES' }),
        },
        {
            title: 'CLIENT_SECURITY',
            titleIcon: <SecurityAdmin />,
            description: isHost ? 'CLIENT_HOST_SECURITY_DESCRIPTION' : 'CLIENT_SECURITY_DESCRIPTION',
            onClickLink: getRoute(RouteNames.SecuritySettings),
            disabled: !isOrgAdmin,
            dataCy: 'org-settings-security-card',
            alt: translate({ id: 'CLIENT_SECURITY' }),
        },
        ...((EnableGovernanceModuleFederation) ? [
            {
                title: 'CLIENT_AI_TRUST_LAYER',
                titleIcon: <AiTrustLayerAdmin />,
                description: 'CLIENT_AI_TRUST_LAYER_DESCRIPTION',
                onClickLink: getRoute(RouteNames.AiTrustLayerSetting),
                disabled: !isOrgAdmin,
                dataCy: 'org-settings-ai-trust-layer-card',
                alt: translate({ id: 'CLIENT_AI_TRUST_LAYER' }),
            },
        ] : []),
        {
            title: 'CLIENT_AUDIT_LOG',
            titleIcon: <AuditLogsAdmin />,
            description: 'CLIENT_AUDIT_LOG_DESCRIPTION',
            onClickLink: getRoute(RouteNames.AuditLogs),
            disabled: !isOrgAdmin,
            dataCy: 'org-settings-audit-logs-card',
            alt: translate({ id: 'CLIENT_AUDIT_LOG' }),
        },
        {
            title: 'CLIENT_EXTERNAL_APPLICATIONS',
            titleIcon: <ExternalAppsAdmin />,
            description: 'CLIENT_EXTERNAL_APPLICATIONS_DESCRIPTION',
            onClickLink: getRoute(RouteNames.ExternalApplications),
            dataCy: 'org-settings-external-apps-card',
            disabled: !isOrgAdmin,
            invisible: isHost,
            alt: translate({ id: 'CLIENT_EXTERNAL_APPLICATIONS' }),
        },
        ...((process.buildConfigs.showForMSI && !isHost && permissions) ? [
            {
                title: 'CLIENT_TAGS',
                titleIcon: <TagsAdmin />,
                description: 'CLIENT_TENANT_HOME_TAGS_DESCRIPTION',
                onClickLink: handleTagsClick,
                dataCy: 'tenant-home-tags-card',
                alt: 'Tags',
                disabled: !hasPermission(permissions, [ PermissionType.View ]) || !isOrgAdmin,
                showWarning: !hasPermission(permissions, [ PermissionType.View ]),
                warningTooltip: translate({ id: 'CLIENT_TENANT_TAGS_PERMISSIONS_RESTRICTION' }, { permissions: 'Tags.View' }),
            },
        ] : []),
        {
            title: 'CLIENT_MAIL_SETTINGS',
            titleIcon: <MailSettingsAdmin />,
            description: 'CLIENT_MAIL_SETTINGS_DESCRIPTION',
            onClickLink: getRoute(RouteNames.EmailSettings),
            disabled: !isOrgAdmin,
            dataCy: 'org-settings-smtp-settings-card',
            alt: translate({ id: 'CLIENT_MAIL_SETTINGS' }),
        },
        ...(EnableOrgConsent ? [
            {
                title: 'CLIENT_USER_CONSENT',
                titleIcon: <UserConsentAdmin />,
                description: 'CLIENT_USER_CONSENT_DESCRIPTION',
                onClickLink: handleOrgConsentClick,
                disabled: !isOrgAdmin,
                dataCy: 'org-settings-user-consent-card',
                alt: translate({ id: 'CLIENT_USER_CONSENT' }),
            },
        ] : []),
        {
            title: 'CLIENT_SETTINGS',
            titleIcon: <SettingsAdmin />,
            description: isHost ? 'CLIENT_HOST_SETTINGS_DESCRIPTION' : 'CLIENT_SETTINGS_DESCRIPTION',
            onClickLink: getRoute(isHost ? RouteNames.LoginCustomization : RouteNames.OrganizationSettings),
            disabled: !isOrgAdmin,
            dataCy: 'org-settings-global-settings-card',
            invisible: !isHost && process.buildConfigs.hideForMSI,
            alt: translate({ id: 'CLIENT_SETTINGS' }),
        },
    ], [
        isHost,
        getRoute,
        isOrgAdmin,
        translate,
        AccountType,
        EnableGovernanceModuleFederation,
        EnableOrgConsent,
        permissions,
        handleTagsClick,
        handleOrgConsentClick,
    ]);

    return (
        <UiPageContainer>
            <UiCardGrid
                maxCardWidth="338px"
                style={{ marginTop: '20px' }}>
                {cardList.map((card, i) =>
                    !card.invisible &&
                    <UiCard
                        key={i}
                        title={card.title}
                        titleIcon={typeof card.titleIcon === 'string'
                            ? <img
                                className={classes.cardIcon}
                                src={card.titleIcon}
                                alt='accountsAndGroups'
                                style={{ width: '40px' }} />
                            : card.titleIcon}
                        description={card.description}
                        chipText={card.chipText}
                        onClickLink={() => {
                            let route;
                            if (typeof card.onClickLink === 'string') {
                                route = card.onClickLink;
                            } else {
                                route = card.onClickLink();
                            }
                            navigate(route);
                        }}
                        style={{
                            height: '224px',
                            maxWidth: '338px',
                        }}
                        disabled={card.disabled}
                        dataCy={card.dataCy} />,
                )}
            </UiCardGrid>
        </UiPageContainer>
    );
};

export default OrganizationAdminHome;
