import type {
    Persona,
    StudioTemplate,
    StudioWebProject,
    TemplateItem,
    Templates,
} from '@experiences/interfaces';
import {
    get,
    post,
} from '@experiences/util';

export const templatesContentfulUrl = `/api/content/templates/getTemplatesCMSData`;

export async function getStudioTemplates(accessToken: string, accountLogicalName: string) {
    return await get<StudioTemplate[]>(`/api/studio/getTemplates`, {
        accessToken,
        urlParams: { accountLogicalName },
    });
}

export async function createStudioProject(accessToken: string, accountLogicalName: string, templateId: string) {
    return await post<StudioWebProject>(`/api/studio/createTemplateProject`, {
        body: {
            accountLogicalName,
            templateId,
        },
        accessToken,
    });
}

export async function getContentfulTemplates(accessToken: string, language: string, persona: Persona) {
    return await get<Templates>(templatesContentfulUrl, {
        accessToken,
        urlParams: {
            language,
            persona,
        },
    });
}

export async function getTemplates(
    accessToken: string,
    persona: Persona,
    language: string,
    accountLogicalName: string,
) {

    const [ studioTemplates, contentfulData ] = await Promise.all([
        getStudioTemplates(accessToken, accountLogicalName),
        getContentfulTemplates(accessToken, language, persona),
    ]);

    return prepareTemplates(contentfulData, studioTemplates);
}

export function prepareTemplates(contentfulData: Templates, studioTemplates: StudioTemplate[]) {
    const templateItems = contentfulData
        ?.homePageTemplateWidgetList
        ?.templateWidgetsCollection?.items;

    const hydratedTemplates: TemplateItem[] = [];

    templateItems?.forEach((templateItem) => {
        if (templateItem.category === 'marketplace') {
            hydratedTemplates.push(templateItem);
            return;
        }

        // get sharedId of studio template for matching template name
        const studioTemplate = studioTemplates.find((template) => template.name.toLowerCase() === templateItem.templateName.toLowerCase());

        if (studioTemplate) {
            templateItem.actionLink = studioTemplate.sharedId;
            hydratedTemplates.push(templateItem);
        }
    });

    return hydratedTemplates;
}

