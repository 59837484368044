import { NavCustomizationSetting } from '@experiences/constants';
import type {
    Apps,
    WidgetContext,
    WidgetTemplate,
} from '@experiences/interfaces';

import { getApps } from '../services/getApps';
import { getGlobalSettings } from '../services/getGlobalSettings';

export const AppsWidget: WidgetTemplate<Apps> = {
    name: 'Apps',
    titleKey: 'CLIENT_APPS_WIDGET_TITLE',
    subtitleKey: 'CLIENT_APPS_WIDGET_SUBTITLE',
    emptyMsgKey: () => 'CLIENT_APPS_WIDGET_EMPTY',
    seeAllTextKey: 'CLIENT_SEE_ALL_APPS',
    selection: async (ctx: WidgetContext) => {
        const globalSettings = await getGlobalSettings(ctx.accessToken, [ NavCustomizationSetting.AppsHidden ]);

        if (globalSettings && globalSettings.length === 0) {
            return Promise.resolve(true);
        }

        return Promise.resolve(globalSettings &&
                                globalSettings.length > 0 &&
                                globalSettings[0].value !== 'true');
    },
    getSeeAllUrl: (ctx: WidgetContext) => `${window.location.origin}/${ctx.accountLogicalName}/apps_/default/edit`,
    fetch: (ctx: WidgetContext) => getApps(ctx.accessToken, ctx.accountLogicalName),
    type: 'Card',
    cardRows: 1,
    transform: (data: Apps[]) => data.map((item) => ({
        data: item,
        category: 'App',
        header: item.model.title,
        description: item.model.description,
        icon: 'apps',
        isSubtitleTime: true,
        subtitleTimePrefixKey: 'CLIENT_APPS_LAST_PUBLISHED',
        subtitle: item.latestPublishedDate,
        actions: [
            {
                id: 'run',
                nameKey: 'CLIENT_ACTION_NAME_RUN',
                ariaLabelKey: 'CLIENT_ACTION_NAME_RUN_ARIA_LABEL',
                icon: 'play_circle',
                type: 'icon',
            },
            {
                id: 'copyUrl',
                nameKey: 'CLIENT_ACTION_NAME_COPYLINK',
                icon: 'link',
                type: 'overflow',
            },
        ],
    })),
    clickHandler: (data: Apps, ctx: WidgetContext) => {
        window.location.assign(`${window.location.origin}/${ctx.accountLogicalName}/apps_/default/run/production/${data.model.systemName}?origin=portalHome`);
    },
    actionHandles: [
        {
            id: 'run',
            handle: (data: Apps, ctx: WidgetContext) => {
                window.location.assign(`${window.location.origin}/${ctx.accountLogicalName}/apps_/default/run/production/${data.model.systemName}?origin=portalHome`);
            },
        },
        {
            id: 'copyUrl',
            handle: (data: Apps, ctx: WidgetContext) => {
                navigator.clipboard.writeText(`${window.location.origin}/${ctx.accountLogicalName}/apps_/default/run/production/${data.model.systemName}?origin=portalHome`);
            },
        },
    ],
};
