import type {
    Event,
    WidgetContext,
} from '@experiences/interfaces';
import { post } from '@experiences/util';

export async function postEventsCollect(
    events: Event[],
    ctx: WidgetContext) {

    return await post('/api/catalog/postEventsCollect', {
        body: { events },
        accessToken: ctx.accessToken,
        urlParams: {
            accountLogicalName: ctx.accountLogicalName,
            cloudOrganizationId: ctx.organizationGuid,
        },
    });
}
