import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import Cookies from 'js-cookie';
import React from 'react';
import { useIntl } from 'react-intl';

import { UiCopyButton } from '../UiCopyButton';
import { UiText } from '../UiText/UiText';

const useStyles = makeStyles(() =>
    createStyles({
        sessionIdCopy: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        sessionIdDialog: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
);

export const UiSessionId: React.FC<{
    style?: React.CSSProperties;
}> = ({ style }) => {

    const classes = useStyles();

    const { formatMessage: translate } = useIntl();

    const sessionId = Cookies.get('UiPathSessionId');

    return (
        sessionId ? <div
            style={{
                alignItems: 'center',
                ...style,
            }}
            className={classes.sessionIdDialog}>
            <UiText>
                {translate({ id: 'CLIENT_SESSION_ID' }) + ':'}
            </UiText>
            <div className={classes.sessionIdCopy}>
                <UiText
                    variant={FontVariantToken.fontSizeMBold}
                    role="heading"
                    aria-level={6}>
                    {sessionId}
                </UiText>
                <UiCopyButton
                    textToCopy={sessionId}
                    aria-label={translate({ id: 'CLIENT_COPY_SESSION_ID' })}
                    data-cy="sessionId-copy-button"
                    data-testid="sessionId-copy-button"
                />
            </div>
        </div> : <></>
    );
};
