import { ERROR_FIELD } from '@experiences/constants';
import type {
    Card,
    CardType,
    Row,
    WidgetItem,
    WidgetTemplate,
} from '@experiences/interfaces';
import {
    HomePageEvent,
    logEvent,
} from '@experiences/telemetry';
import { UiText } from '@experiences/ui-common';
import { useUserReadableTime } from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import * as React from 'react';
import { useIntl } from 'react-intl';
import useSWRImmutable from 'swr/immutable';

import UiCardsWidget from '../components/UiCardsWidget';
import { UiLoadingWidget } from '../components/UiLoadingWidget';
import UiTableWidget from '../components/UiTableWidget';
import { UiWidgetHeader } from '../components/UiWidgetHeader';
import { WidgetFrameContext } from '../context/WidgetFrameContext';

const useStyles = makeStyles(() =>
    createStyles({
        createNew: {
            width: '250px',
            height: '40px',
        },
    }),
);

export interface WidgetRenderProps<T extends {}> {
    template: WidgetTemplate<T>;
}

export const WidgetRender = <T extends {}>({ template }: WidgetRenderProps<T>) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { userReadableTime } = useUserReadableTime();
    const context = React.useContext(WidgetFrameContext);

    const {
        data: widgetData, error, isValidating,
    } = useSWRImmutable(template.name, () => {

        // only fetch data if the widget has full context
        // if any of the key context is absent we fallback to empty data
        // and hide the widget.
        if (template.fetch &&
            context.tenant &&
            context.accessToken &&
            context.accountLogicalName) {
            return Promise.all([
                template.fetch(context),
                template.selection ? template.selection(context) :
                    Promise.resolve(true),
            ]);
        }

        logEvent(`Failed fetch due to missing context`, {
            name: HomePageEvent.WidgetRenderFetch,
            code: '[IN-HP-19]',
            isError: true,
        }, {
            Widget: template.name,
            tenant: context.tenant,
            accountLogicalName: context.accountLogicalName,
        });

        return Promise.all([ Promise.resolve([]), Promise.resolve(false) ]);
    }, { shouldRetryOnError: false });

    const createCard = (item: WidgetItem<T>, type: CardType) => ({
        ...(type === 'Allocation' ? {
            header: '',
            description: '',
            total: item.total,
            allocated: item.allocated,
            isSubtitleTime: false,
        } : {
            header: item.header,
            description: item.description,
            icon: item.icon,
            isSubtitleTime: item.isSubtitleTime,
            subtitleTimePrefixKey: item.subtitleTimePrefixKey,
            icons: item.icons,
            iconType: item.iconType,
            clickable: item.clickable,
            actions: item.actions?.map((action) => ({
                ...action,
                name: translate({ id: action.nameKey }),
                ariaLabel: action.ariaLabelKey ? translate({ id: action.ariaLabelKey }) : undefined,
            })),
        }),
        category: item.categoryKey ? translate({ id: item.categoryKey }) : item.category,
        tabCategory: item.tabCategory,
        type,
        subtitle: item.subtitle,
        data: item.data,
    });

    const items = React.useMemo(() => {
        if (widgetData && widgetData.length === 2 && widgetData[0] && widgetData[0].length > 0) {
            const [ data ] = widgetData;
            return template.transform(data);
        }

        return [];
    }, [ template, widgetData ]);

    if (isValidating) {
        return <div data-testid={`widget-factory-${template.name}`}>
            <div data-testid="loading">
                <UiWidgetHeader template={template} />
                <UiLoadingWidget
                    type={template.type}
                />
            </div>
        </div>;
    }

    if (error) {
        logEvent(`error while fetching data`, {
            name: HomePageEvent.WidgetRenderFetch,
            code: '[IN-HP-13]',
            isError: true,
        }, { Widget: template.name });
        return null;
    }

    if (!widgetData) {
        return null;
    }

    const [ data, isWidgetEnabled ] = widgetData;

    if (!isWidgetEnabled) {
        logEvent(`widget disabled by selector`, {
            name: HomePageEvent.WidgetRenderSelection, // tODO
            code: '[IN-HP-14]',
        }, { Widget: template.name });
        return null;
    }

    if (data === undefined || data.length === 0 || items.filter(item => item.header !== ERROR_FIELD).length === 0) {
        logEvent(`widget is empty`, {
            name: HomePageEvent.WidgetRenderDataEmpty, //
            code: '[IN-HP-15]',
        }, { Widget: template.name });

        return <div data-testid={`widget-factory-${template.name}`}>
            <UiWidgetHeader
                template={template}
                isDataEmpty={data === undefined || data.length === 0 || items.filter(item => item.header !== ERROR_FIELD).length === 0} />
            {
                template.showEmptyStateButton?.(context) &&
                template.emptyStateButtonText &&
                template.emptyStateButtonHandler &&
                <Button
                    className={classes.createNew}
                    variant='outlined'
                    onClick={() => {
                        template.emptyStateButtonHandler?.(context);
                    }}>
                    {translate({ id: template.emptyStateButtonText })}
                </Button>
            }
        </div>;
    }

    if (template.type === 'Card') {
        const cards: Array<Card<T>> = items.map((item) => createCard(item, 'Normal'));

        return <div data-testid={`widget-factory-${template.name}`}>
            <UiWidgetHeader
                template={template} />

            <UiCardsWidget
                data-testid="cards-widget"
                id={template.name}
                cards={cards}
                tabs={template.tabs}
                fixed
                defaultTab={template.defaultTab}
                cardHeight={template.cardHeight}
                actionHandles={template.actionHandles}
                clickHandler={template.clickHandler}
                rows={template.cardRows} />
        </div>;
    }

    if (template.type === 'Presentation') {
        const cards: Array<Card<T>> = items.map((item) => createCard(item, 'Large'));

        return <div data-testid={`widget-factory-${template.name}`}>
            <UiCardsWidget
                data-testid="presentation-cards-widget"
                id={template.name}
                fixed={false}
                cards={cards}
                tabs={template.tabs}
                defaultTab={template.defaultTab}
                cardHeight={template.cardHeight}
                actionHandles={template.actionHandles}
                clickHandler={template.clickHandler} />
        </div>;
    }

    if (template.type === 'Allocation') {
        const cards: Array<Card<T>> = items.map((item) => createCard(item, 'Allocation'));

        return <div data-testid={`widget-factory-${template.name}`}>
            <UiWidgetHeader
                template={template} />
            <UiCardsWidget
                data-testid="allocation-cards-widget"
                id={template.name}
                fixed={false}
                cards={cards}
                cardHeight={template.cardHeight}
                tabs={template.tabs}
                defaultTab={template.defaultTab} />
        </div>;
    }

    if (template.type === 'Table' && template.tableColumns) {
        const rows: Array<Row<T>> = items.map((item) => ({
            title: item.header,
            icon: item.iconUrl,
            detail: item.description,
            subDetail: item.isSubtitleTime ? userReadableTime(item.subtitle) : item.subtitle,
            statusText: item.statusKey ? translate({ id: item.statusKey }) : item.statusText ?? '',
            statusType: item.statusType ?? 'info',
            actions: item.actions,
            data: item.data,
        }));

        const headers = template.tableHeaders?.map((header) => ({
            name: translate({ id: header.nameKey }),
            colStyle: header.colStyle,
        }));

        return <div data-testid={`widget-factory-${template.name}`}>
            <UiWidgetHeader
                template={template} />
            <UiTableWidget
                data-testid="table-widget"
                id={template.name}
                clickHandler={template.clickHandler}
                columns={template.tableColumns}
                headers={headers ?? []}
                actionHandles={template.actionHandles}
                actions={template.tableActions?.map((action) => ({
                    ...action,
                    name: translate({ id: action.nameKey }),
                }))}
                rows={rows} />
        </div>;
    }

    return (
        <UiText data-testid="system-error">
            {translate({ id: 'CLIENT_SYSTEM_ERROR' })}
        </UiText>
    );
};
