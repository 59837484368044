import { useLocalization } from '@experiences/locales';
import { ApplicationEvent } from '@experiences/telemetry';
import Cookies from 'js-cookie';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
    generatePath,
    Navigate,
    useParams,
} from 'react-router-dom';

import ProtectedRoute from '../../auth/ProtectedRoute';
import * as RouteNames from '../../common/constants/RouteNames';
import {
    accountGlobalId,
    accountLogicalName,
    countrySelector,
    isAdminSelector,
    isHostModeSelector,
    userGlobalId,
} from '../../store/selectors';
import {
    useDeviceInfo,
    useTelemetryHelper,
} from '../../telemetry/TelemetryHelper';

export const AccountAdmin = () => {
    const isHostMode = useSelector(isHostModeSelector);
    const currentAccountName = useSelector(accountLogicalName);
    const userId = useSelector(userGlobalId);
    const partitionGlobalId = useSelector(accountGlobalId);
    const { accountName: paramAccountName } = useParams() as { accountName?: string };
    const hostPath = useMemo(() => generatePath(RouteNames.OrganizationAdminHome, { accountName: 'host' }), []);
    const { logEvent } = useTelemetryHelper();
    const deviceInfo = useDeviceInfo();
    const isAdmin = useSelector(isAdminSelector);
    const accountCountry = useSelector(countrySelector);
    const productLanguage = useLocalization();

    const [ hasLoggedEvent, setHasLoggedEvent ] = useState(false);

    const getOrgPath = useCallback((accountName: string) => generatePath(RouteNames.Home, { accountName }), []);

    const path = useMemo(() => {
        if (paramAccountName) {
            return paramAccountName === 'host' ? hostPath : getOrgPath(paramAccountName);
        }
        return isHostMode ? hostPath : getOrgPath(currentAccountName);
    }, [ getOrgPath, currentAccountName, hostPath, isHostMode, paramAccountName ]);

    useEffect(() => {
        if (!hasLoggedEvent &&
            isAdmin !== undefined &&
            accountCountry !== undefined &&
            partitionGlobalId !== undefined &&
            userId !== undefined &&
            productLanguage !== undefined &&
            deviceInfo !== undefined
        ) {
            logEvent(ApplicationEvent.Start, {
                IsAdmin: isAdmin,
                CloudOrganizationCountry: accountCountry,
                CloudOrganizationId: partitionGlobalId,
                CloudUserId: userId,
                ProductLanguage: productLanguage,
                UserCountry: accountCountry,
                GAId: Cookies.get('_ga'),
                ...deviceInfo,
            });

            setHasLoggedEvent(true);
        }
    }, [ logEvent, isAdmin, accountCountry, deviceInfo, productLanguage, partitionGlobalId, userId, hasLoggedEvent ]);

    return <Navigate to={path} />;
};

export default (props: any) => (
    <ProtectedRoute>
        <AccountAdmin {...props} />
    </ProtectedRoute>
);
