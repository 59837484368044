import { portalTelemetry } from '@experiences/telemetry';
import {
    UiBackground,
    UiLogo,
} from '@experiences/ui-common';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Cookies from 'js-cookie';
import type { PropsWithChildren } from 'react';
import React, { useEffect } from 'react';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import { UserAuthPDPModelProvider } from '../container/UserAuthPDPModelProvider/UserAuthPDPModelProvider';
import useAuthorization from './hooks/Authorization';
import useInit from './hooks/Init';
import useUserInfo from './hooks/UserInfo';
import useValidateStore from './hooks/ValidateStore.default';

const useStyles = makeStyles(theme =>
    createStyles({
        paperDefault: {
            width: 440,
            height: 'fit-content',
            marginTop: '40px',
            backgroundColor: theme.palette.semantic.colorBackgroundRaised,
        },
        content: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            padding: '32px',
        },
    }),
);

const PortalInitializationProtector = ({ children }: PropsWithChildren) => {
    const location = useLocation();
    const classes = useStyles();
    // eslint-disable-next-line ban/ban
    const navigate = useNavigate();
    const { isAuthorizedOnCurrentRoute } = useAuthorization();
    const {
        token, identifier,
    } = useUserInfo();
    const isStoreInitialized = useValidateStore();
    const {
        init, initializing, ErrorComponent, renderError,
    } = useInit();

    useEffect(() => {
        if (!isStoreInitialized) {
            return;
        }
        if (!isAuthorizedOnCurrentRoute()) {
            // handle authorization
            navigate('/portal_/genericerror?errorCode=9017');
        }
    }, [ isAuthorizedOnCurrentRoute, navigate, isStoreInitialized ]);

    useEffect(() => {
        // initialize redux
        if (!isStoreInitialized && !initializing && !renderError) {
            init();
        }
    }, [ isStoreInitialized, initializing, init, renderError ]);

    if (!token) {
        return <></>;
    }

    if (renderError) {
        return (
            <UiBackground center>
                <Paper className={classes.paperDefault}>
                    <div className={classes.content}>
                        <UiLogo />
                        {ErrorComponent}
                    </div>
                </Paper>
            </UiBackground>
        );
    }

    if (!isStoreInitialized) {
        return <></>;
    }

    // handle authorization
    if (!isAuthorizedOnCurrentRoute()) {
        return <></>;
    }

    const _ga = Cookies.get('_ga');
    portalTelemetry.trackTrace(
        {
            message: 'Internal Redirect',
            severityLevel: SeverityLevel.Information,
        },
        {
            _ga,
            sub: identifier,
            pathname: location.pathname,
        }
    );

    return (
        <UserAuthPDPModelProvider>
            {children}
        </UserAuthPDPModelProvider>
    );
};

export default PortalInitializationProtector;
