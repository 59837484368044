import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiText } from '@experiences/ui-common';
import { useTheme } from '@mui/material/styles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import urljoin from 'url-join';

import { Saml2BindingType } from '../../../common/constants/ExternalIdentityProviderConstant';
import { useCheckAuthenticationSetting } from '../../../common/hooks/useCheckAuthenticationSetting';
import { AttributeType } from '../../../common/interfaces/cis/attribute';
import type { ISamlFormData } from '../../../common/interfaces/cis/saml';
import type { ISaml2ProviderSettings } from '../../../common/interfaces/externalIdentity';
import {
    BulkAuthenticationSettingKey,
    getTemplateData,
} from '../../../services/identity/AuthenticationSettingService';
import { accountGlobalId } from '../../../store/selectors';
import validateCertificate from '../helpers/ValidateSAMLCert';

const useSAMLConfigureIDPViewModel = () => {
    const { formatMessage: translate } = useIntl();
    const theme = useTheme();

    const EnableSAMLUXRefactor = useFeatureFlagValue(Features.EnableSAMLUXRefactor.name);

    const partitionGlobalId = useSelector(accountGlobalId);

    const [ authEntityId, setAuthEntityId ] = useState<string>('');
    const [ metadataStatus, setMetadataStatus ] = useState({
        loading: false,
        success: false,
        error: '',
    });

    const bulkAuthenticationSetting = useCheckAuthenticationSetting();
    const authenticationSetting = bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.SAML];

    const methods = useFormContext<ISamlFormData>();

    const {
        watch, setValue, reset, setError, formState: { errors },
    } = methods;

    const currentEntityId: string | undefined = watch('ServiceProviderEntityId');

    const createdOrgEntityId = urljoin(
        window.location.origin,
        partitionGlobalId,
        process.buildConfigs.identityBaseRoute,
    );

    useEffect(() => {
        const authSettings = authenticationSetting?.externalIdentityProviderDto?.settings;
        const ServiceProviderEntityId: string | undefined = authSettings ? JSON.parse(authSettings).ServiceProviderEntityId : undefined;
        if (!ServiceProviderEntityId) {
            setValue('ServiceProviderEntityId', createdOrgEntityId);
        }
        setAuthEntityId(ServiceProviderEntityId?.includes(partitionGlobalId)
            ? ServiceProviderEntityId : createdOrgEntityId);
    }
    , [ authenticationSetting, createdOrgEntityId, partitionGlobalId, setValue ]);

    const entityIds = {
        [authEntityId]: translate({ id: 'CLIENT_SAML_ORG_ENTITY_ID' }),
        [urljoin(
            window.location.origin,
            process.buildConfigs.identityBaseRoute
        )]: translate({ id: 'CLIENT_SAML_GLOBAL_ENTITY_ID' }),
    };

    const metadataUrls = {
        metadataUrl: urljoin(
            window.location.origin,
            process.buildConfigs.showMetadataLink ? partitionGlobalId : '',
            process.buildConfigs.identityBaseRoute,
            'Saml2',
        ),
        entityId: currentEntityId ?? '',
        assertionConsumerUrl: urljoin(
            window.location.origin,
            process.buildConfigs.showMetadataLink ? partitionGlobalId : '',
            process.buildConfigs.identityBaseRoute,
            '/Saml2/Acs',
            !process.buildConfigs.showMetadataLink ? `?PartitionId=${partitionGlobalId}` : '',
        ),
    };

    const fetchData = useCallback(async () => {
        setMetadataStatus({
            loading: true,
            success: false,
            error: '',
        });
        try {
            const fetchedMetadata = await getTemplateData({
                type: 'Saml2',
                displayName: 'Saml2 Provider',
                partitionGlobalId,
                settings: JSON.stringify({
                    ServiceProviderEntityId: metadataUrls.entityId,
                    IdentityProviderMetadataUrl: watch('IdentityProviderMetadataUrl'),
                }),
            });
            const settings: ISaml2ProviderSettings = JSON.parse(fetchedMetadata?.externalIdentityProviderDto?.settings ?? '');

            if (!settings) {
                throw new Error();
            }

            const formSettings = {
                ...settings,
                ServiceProviderEntityId: currentEntityId,
            } as any;
            if (formSettings.ProvisioningSetting) {
                formSettings.ProvisioningSetting.AllowedDomains =
                    settings.ProvisioningSetting?.AllowedDomains?.join(', ')
                        || watch('ProvisioningSetting.AllowedDomains')
                        || '';
                formSettings.ProvisioningSetting.AccountLinkConfirmation =
                    settings.ProvisioningSetting?.AccountLinkConfirmation
                        ?? watch('ProvisioningSetting.AccountLinkConfirmation')
                        ?? false;

                if (EnableSAMLUXRefactor) {
                    const attributeMapper = watch('attributes');
                    const attributesToArray = Object.entries(settings.ProvisioningSetting?.AttributeMapper ?? {})
                        .filter(([ key, value ]) => !!value && key !== 'ExtensionUserAttributeMappings')
                        .map(([ key, value ]) => ({
                            attributeType: key,
                            value,
                            disableRemove: key === AttributeType.DISPLAYNAME
                                || key === AttributeType.EMAIL
                                || key === AttributeType.IDENTIFIER,
                        }));
                    formSettings.attributes = attributesToArray.length
                        ? attributesToArray
                        : attributeMapper;
                } else {
                    const attributeMapper = watch('ProvisioningSetting.AttributeMapper');
                    formSettings.ProvisioningSetting.AttributeMapper =
                        (settings.ProvisioningSetting?.AttributeMapper
                            && Object.values(settings.ProvisioningSetting?.AttributeMapper).some(attribute => !!attribute)
                            && settings.ProvisioningSetting?.AttributeMapper)
                            || (attributeMapper
                                && Object.values(attributeMapper).some(attribute => !!attribute)
                                && attributeMapper);
                }

            }
            if (formSettings.Saml2BindingType === Saml2BindingType.Artifact) {
                formSettings.Saml2BindingType = Saml2BindingType.HttpRedirect;
            }
            formSettings.ServiceCertificateUsage =
                    settings.SigningCertificateLocation?.Usage || watch('ServiceCertificateUsage');
            reset(formSettings, {
                keepDirty: true,
                keepDirtyValues: true,
            });
            if (!(await validateCertificate(formSettings.SigningCertificateLocation.CertificateText))) {
                setError('SigningCertificateLocation.CertificateText', { type: 'invalid' });
            }

            setMetadataStatus({
                loading: false,
                success: true,
                error: '',
            });
        } catch (error) {
            setMetadataStatus({
                loading: false,
                success: false,
                error: translate({ id: 'CLIENT_FAILED_TO_FETCH_DATA' }),
            });
        }
    }, [
        setMetadataStatus,
        partitionGlobalId,
        metadataUrls.entityId,
        watch,
        currentEntityId,
        reset,
        EnableSAMLUXRefactor,
        setError,
        translate,
    ]);

    const getFetchHelperText = useMemo(() => {
        if (metadataStatus.success) {
            return <UiText color={theme.palette.semantic.colorSuccessText}>
                {translate({ id: 'CLIENT_FETCHED_METADATA_SUCCESSFULLY' })}
            </UiText>;
        } else if (metadataStatus.error) {
            return metadataStatus.error;
        } else if (errors.IdentityProviderMetadataUrl) {
            return errors.IdentityProviderMetadataUrl.message;
        }
        return undefined;

    }, [
        errors.IdentityProviderMetadataUrl,
        metadataStatus.error,
        metadataStatus.success,
        theme.palette.semantic.colorSuccessText,
        translate,
    ]);

    return {
        loading: !bulkAuthenticationSetting,
        partitionGlobalId,
        methods,
        metadataStatus,
        entityIds,
        currentEntityId,
        metadataUrls,
        fetchData,
        getFetchHelperText,
    };
};

export default useSAMLConfigureIDPViewModel;
