import type {
    ActionHandle,
    Card,
    WidgetContext,
} from '@experiences/interfaces';
import {
    HomePageEvent,
    logEvent,
} from '@experiences/telemetry';
import { useUserReadableTime } from '@experiences/util';
import { PortalCard } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useContext,
    useEffect,
} from 'react';
import { useIntl } from 'react-intl';

import { WidgetFrameContext } from '../context/WidgetFrameContext';

export interface UiCardWrapperProps<T> {
    id: string;
    cardIndex: number;
    card: Card<T>;
    actionHandles?: Array<ActionHandle<T>>;
    clickHandler?: (data: T, ctx: WidgetContext) => void;
    cardHeight?: number;
}

export const UiCardWrapper = <T extends object>({
    id, card, cardIndex, actionHandles, clickHandler, cardHeight,
}: UiCardWrapperProps<T>) => {
    const { formatMessage: translate } = useIntl();
    const { userReadableTime } = useUserReadableTime();
    const cardRef = React.createRef<HTMLPortalCardElement>();
    const subTitleLocalizedWithTime = `${card.subtitleTimePrefixKey ? translate({ id: card.subtitleTimePrefixKey }) : ''}: ${userReadableTime(card.subtitle)}`;
    const context = useContext(WidgetFrameContext);

    const actionClickedEventHandler = useCallback((event: any) => {
        const action = actionHandles?.find((a => a.id === event.detail.actionId));
        if (action) {
            logEvent('card action clicked', {
                name: HomePageEvent.CardActionClicked,
                code: '[IN-HP-06]',
            }, {
                Widget: id,
                CardPosition: cardIndex,
                ActionName: action.id,
                CardCategory: card.category,
                Persona: context.persona,
                CardData: card.data,
            });

            action.handle(card.data, context);
        }
    }, [ actionHandles, card.category, card.data, cardIndex, context, id ]);

    const cardClickedEventHandler = useCallback(() => {
        logEvent('card clicked', {
            name: HomePageEvent.CardClicked,
            code: '[IN-HP-07]',
        }, {
            Widget: id,
            CardPosition: cardIndex,
            CardName: card.header,
            CardCategory: card.category,
            Persona: context.persona,
            CardData: card.data,
        });

        if (clickHandler) {
            clickHandler(card.data, context);
        }
    }, [ card.category, card.data, card.header, cardIndex, clickHandler, context, id ]);

    useEffect(() => {
        const { current } = cardRef;
        if (!current) {
            return;
        }
        current.addEventListener('actionClicked', actionClickedEventHandler);
        current.addEventListener('cardClicked', cardClickedEventHandler);

        return () => {
            current.removeEventListener('actionClicked', actionClickedEventHandler);
            current.removeEventListener('cardClicked', cardClickedEventHandler);
        };
    }, [ actionClickedEventHandler, cardClickedEventHandler, cardRef ]);

    return <PortalCard
        ref={cardRef}
        cardId={`${id}-card-${cardIndex}`}
        data-cy={`${id}-card-${cardIndex}`}
        data-testid={`${id}-card-${cardIndex}`}
        width='auto'
        categoryChipText={card.category}
        descriptionOverflow='2'
        titleOverflow='1'
        fixedHeights={`${cardHeight}px`}
        header={card.header}
        icon={card.icon}
        icons={JSON.stringify(card.icons)}
        iconType={card.iconType}
        description={card.description}
        subtitle={card.isSubtitleTime ?
            subTitleLocalizedWithTime :
            card.subtitle}
        titleClickable={!card.clickable}
        clickable={card.clickable}
        actions={JSON.stringify(card.actions)} />;

};
