import {
    ContextTagKeys,
    type SeverityLevel,
} from '@microsoft/applicationinsights-common';
import type { ITelemetryItem } from '@microsoft/applicationinsights-core-js';
import { GlobalEventNames } from '@uipath/portal-shell-util';
import type { ApplicationInsightDriverProps } from '@uipath/telemetry-client-web';
import {
    AppInsightDriverBuilder,
    DeploymentType,
    SeverityLevel as TelemetryClientSeverity,
    Tracker as TrackerBase,
    WellKnownApplication,
} from '@uipath/telemetry-client-web';
import type {
    IPageViewTelemetry,
    TrackPlatformAll,
} from '@uipath/telemetry-client-web/models';

import type { TrackMap } from './events/TrackMap';

const emailPiiRegex = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+/g;
const emailPiiMask = '<PII_EMAIL>';

// For pageview events that has token, we don't want to log them as they have the token.
// calls to /unregistered are cluttering up app insights instances, so we don't log those either
const AI_FILTER_URI_LIST = [ 'token', 'unregistered' ];

function removeAuthHeader(data: any) {
    delete data?.error?.config?.headers?.Authorization;
}

export const getTelemetryClientSeverityLevel = (severityLevel: SeverityLevel | number) => [
    TelemetryClientSeverity.Info, // Debug doesn't exist in TelemetryClientSeverity, so we map it to Info
    TelemetryClientSeverity.Info,
    TelemetryClientSeverity.Normal,
    TelemetryClientSeverity.Major,
    TelemetryClientSeverity.Critical,
][severityLevel];

export const getAppInsightsConfig = () => {

    const disableTelemetry = !window.env?.APPLICATION_INSIGHTS_CONNECTION_STRING && !window.env?.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY;

    return {
        instrumentationKey: window.env?.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
        connectionString: window.env?.APPLICATION_INSIGHTS_CONNECTION_STRING,
        disableExceptionTracking: disableTelemetry,
    } satisfies ApplicationInsightDriverProps;
};

export const getDeploymentType = () => {
    const env = process.buildConfigs.name;
    switch (env) {
        case 'k8s':
            return DeploymentType.AutomationSuite;
        case 'msi':
            return DeploymentType.Standalone;
        default:
            return DeploymentType.AutomationCloud;
    }
};

const tagKeys = new ContextTagKeys();
const telemetryInitializer = (item: ITelemetryItem) => {
    try {

        if (item?.name?.endsWith('Pageview')) {
            const refUri = item?.baseData && item.baseData['refUri'];
            if (refUri && AI_FILTER_URI_LIST.some(uri => refUri.toString().indexOf(uri) > -1)) {
                return false;
            }
        }

        item.baseData = item.baseData ?? {};
        item.baseData.properties = item.baseData.properties || {};
        item.baseData.properties['Canary'] = window.env?.CANARY;
        item.baseData.properties['ScaleUnitIndex'] = window.env?.SCALE_UNIT_INDEX;
        item.baseData.properties['ScaleUnitId'] = window.env?.SCALE_UNIT_ID;
        item.baseData.properties['Region'] = window.env?.REGION;

        item.tags = item.tags ?? [];
        item.tags[tagKeys.cloudRole] = `portal-fe-${window.env.ENVIRONMENT}-${window.env.REGION}-${window.env.SCALE_UNIT_INDEX}`;

        removeAuthHeader(item.data);

        if (window.env?.APPLICATION_INSIGHTS_APPLICATION_NAME && item.baseData) {
            item.baseData.properties['ApplicationName'] = window.env?.APPLICATION_INSIGHTS_APPLICATION_NAME;
        }

        const itemJson = JSON.stringify(item);
        const isEmailPiiFound = itemJson.search(emailPiiRegex) > -1;
        if (isEmailPiiFound) {
            const sanitizedItemString = itemJson.replace(emailPiiRegex, emailPiiMask);
            const itemObj = JSON.parse(sanitizedItemString);
            Object.assign(item, itemObj);
        }
        return;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return false;
    }
};

export class Tracker extends TrackerBase<TrackMap> {
    override initialize(config: {
        isDevelopment: boolean;
        language: string;
        isPageViewTrackingEnabled: boolean;
        isPageViewPerformanceTrackingEnabled: boolean;
        pageViewTrackingOptions?: IPageViewTelemetry;
        initializer?: ((ev: any) => boolean | undefined);
    }) {
        super.initialize(config);

        const logger = (window.PortalShell?.Logger as any)?.appInsights;

        if (logger) {
            super.setPlatformInfo({
                ...logger.getPlatformInfo(),
                productVersion: window.env?.CLOUDRPA_VERSION,
            });
        }

        window.addEventListener(GlobalEventNames.platformInfo, (event: any) => {
            super.setPlatformInfo({
                ...event.detail,
                productVersion: window.env?.CLOUDRPA_VERSION,
            } satisfies TrackPlatformAll);
        });
    }
}

let tracker: any;

const getPrivateTracker = () => {
    if (tracker) {
        return tracker;
    }

    const driver = AppInsightDriverBuilder
        .withConfig(getAppInsightsConfig())
        .build();

    driver.addTelemetryInitializer(telemetryInitializer);

    return tracker = new Tracker(
        getDeploymentType(),
        process.buildConfigs.name === 'k8s' ? WellKnownApplication.AutomationSuite : WellKnownApplication.AutomationCloud,
        driver,
    );
};

export { getPrivateTracker as getTracker };
