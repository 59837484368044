import type { ResolveDirectoryEntityType } from './directory';

// Define as lowercase and use toLowerCase() when comparing
export enum ResourceGroup {
    AuthZ = 'authz',
    CentralizedAccess = 'centralizedaccess',
}

export enum ResourceType {
    AdministrationPage = 'administrationpage',
}

export enum CheckAccessTabState {
    RoleAssignments = 'ROLEASSIGNMENTS',
    Permissions = 'PERMISSIONS',
}

export interface IRoleAssignment {
    displayName: string;
    email: string;
    roleAssignmentDtos: IRoleAssignmentDto[];
    securityPrincipalId: string;
    source: string;
    type: ResolveDirectoryEntityType;
}

export interface IRoleAssignmentDto {
    createdBy: string;
    createdOn: Date | string;
    id: string;
    inherited: boolean;
    mutable: boolean;
    roleId: string;
    roleName: string;
    scope: string;
    securityPrincipalId: string;
    securityPrincipalType: string;
    type: string;
}

export interface IAuthModel {
    Id: string;
    Namespace: string;
    ResourceAction: string;
    ResourceGroup: string;
    ResourceType: string;
    Scope: string;
}

export interface IUserAuthPDPModel {
    actions: IAuthModel[];
}

export interface IEffectiveAccessDto {
    roleAssignments: { totalCount: number; results: IRoleAssignmentModel[] };
    grantedRoleNames: string[];
}

export interface IRoleAssignmentModel {
    roleId: string;
    roleName: string;
    securityPrincipalId: string;
    securityPrincipalType: string;
    roleType: string;
    serviceName: string;
    scope: string;
    organizationId: string;
    tenantId: string;
    createdOn: Date | string;
}

export interface IPermission {
    id: string;
    name: string;
    namespace: string;
    serviceDisplayName: string;
    resourceType: string;
    resourceAction: string;
    resourceGroup: string;
    description: string;
}

export interface IPermissionModel {
    id: string;
    name: string;
    description: string;
    type: string;
    tenantId: string;
    actionDetails: IPermission[];
}
