import './index.css';

import { initializeTelemetryPortal } from '@experiences/telemetry';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { getRouter } from './Router';
// @ts-ignore
import * as serviceWorker from './serviceWorker';

document.title = 'UiPath';

initializeTelemetryPortal();

async function initApp() {
    const router = await getRouter();
    const container = document.getElementById('apollo-shell') as HTMLElement;
    const root = createRoot(container);
    root.render(
        <App router={router} />,
    );
}

initApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
