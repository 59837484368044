/* eslint-disable max-len */
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiText } from '@experiences/ui-common';
import { getEnvVariableValue } from '@experiences/util';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import type { ChangeEvent } from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    ConnectionAffiliates,
    getAffiliatedConnection,
    updateUserNotificationSeveritySubscriptionMode,
    updateUserNotificationTopicSubscription,
    updateUserPublisherSubscriptionSubscription,
} from '../../../services/notification-preferences';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import { useTenantsContext } from '../../tenants/TenantsContextProvider';
import { SlackPublisherSettings } from '../Admin/SlackPublisherSettings';
import type {
    IEntityTypeDto,
    IFolderUpdatePayload,
    INotificationMode,
    INotificationSettingsReducer,
    INotificationTopic,
    INotificationTopicGroup,
    IProfileSubscriptionPayload,
    IPublisherProfileSubscriptionPayload,
    ISlackChannel,
} from '../interfaces/notificationSettings';
import {
    NotificationMode,
    NotificationSeverity,
} from '../interfaces/notificationSettings';
import { ActionTypes } from '../reducers/actionTypes';
import DynamicFilterComponent from './DynamicFilterComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        header: { fontWeight: Tokens.FontFamily.FontWeightSemibold },
        display: { display: 'inherit' },
        tableCell: {
            height: '50px',
            minWidth: '150px',
            width: '25%',
            padding: '0px',
            border: 'none',
        },
        tableVisibleCell: {
            height: '50px',
            minWidth: '80px',
            maxWidth: '90px',
            border: 'none',
        },
        overflowDiv: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        tableMainCell: { width: '75%' },
        tableHeaderCell: {
            backgroundColor: 'transparent',
            fontSize: Tokens.FontFamily.FontMSize,
            color: theme.palette.semantic.colorForegroundDeEmp,
            borderBottom: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
        },
        tableBodyRow: { '&:hover': { backgroundColor: `${theme.palette.semantic.colorHover}` } },
        tableHeaderMainCell: { paddingLeft: Tokens.Padding.PadXxxl },
        tableSubHeaderRow: { backgroundColor: theme.palette.semantic.colorBackgroundSecondary },
        tableSubHeaderCell: { borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}` },
        tableSubHeaderMainCell: {
            paddingLeft: Tokens.Padding.PadXxxl,
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
        },
        tableSubHeaderMainSeverityCell: { paddingLeft: Tokens.Padding.PadXxxl },
        tableBodyMainCell: { paddingLeft: Tokens.Padding.PadXxxl },
        loaderContainer: {
            minHeight: '42px',
            minWidth: '42px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        infoIcon: {
            margin: Tokens.Padding.PadM,
            height: Tokens.FontFamily.FontXsLineHeight,
            width: '16px',
        },
        recieveby: {
            fontSize: Tokens.FontFamily.FontMSize,
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
        },
        categoryText: {
            fontSize: Tokens.FontFamily.FontLinkSize,
            fontWeight: Tokens.FontFamily.FontWeightDefault,
        },
        radioMargin: { marginLeft: '18px' },
        tableCellDisabledColor: { color: theme.palette.semantic.colorForegroundDisable },
        successCategoryChip: {
            background: `${theme.palette.semantic.colorChipSuccessBackground} !important`,
            height: Tokens.Padding.PadXxxl,
            marginLeft: Tokens.Padding.PadS,
            color: theme.palette.semantic.colorSuccessText,
            fontSize: Tokens.FontFamily.FontXsSize,
            paddingLeft: 0,
            paddingRight: 0,
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
        },
        infoCategoryChip: {
            background: `${theme.palette.semantic.colorChipInfoBackground} !important`,
            height: Tokens.Padding.PadXxxl,
            marginLeft: Tokens.Padding.PadS,
            color: theme.palette.semantic.colorInfoText,
            fontSize: Tokens.FontFamily.FontXsSize,
            paddingLeft: 0,
            paddingRight: 0,
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
        },
        warnCategoryChip: {
            background: `${theme.palette.semantic.colorChipWarningBackground} !important`,
            height: Tokens.Padding.PadXxxl,
            marginLeft: Tokens.Padding.PadS,
            color: theme.palette.semantic.colorWarningText,
            fontSize: Tokens.FontFamily.FontXsSize,
            paddingLeft: 0,
            paddingRight: 0,
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
        },
        fatalCategoryChip: {
            background: `${theme.palette.semantic.colorChipErrorBackground} !important`,
            height: Tokens.Padding.PadXxxl,
            marginLeft: Tokens.Padding.PadS,
            color: theme.palette.semantic.colorErrorText,
            fontSize: Tokens.FontFamily.FontXsSize,
            paddingLeft: 0,
            paddingRight: 0,
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
        },
        slackSettings: { marginLeft: '-16px' },
    }),
);
const CustomSettingsIcon = styled(SettingsOutlinedIcon)`
    width: 16px;
    height: 16px;
    margin: 1px;
`;
const CustomVisibilityOutlinedIcon = styled(VisibilityOutlinedIcon)`
    width: 20px;
    height: 20px;
    margin: 1px;
`;
const CustomVisibilityOffOutlinedIcon = styled(VisibilityOffOutlinedIcon)`
    width: 20px;
    height: 20px;
    margin: 1px;
`;
const NotificationModePreferencesComponent: React.FC<{
    notificationSettings: INotificationSettingsReducer;
    dispatch: any;
    handleError(error: Error): void;
    isManagedMode: boolean;
    updateSelectedOption?: any;
    isUserGroup: boolean;
    isLocalGroup: boolean;
    hasEmail: boolean;
}> = ({
    notificationSettings, dispatch, handleError, isManagedMode, updateSelectedOption, isUserGroup, isLocalGroup, hasEmail,
}) => {
    const isAdmin = useSelector(isAdminSelector);
    const classes = useStyles();
    const { logEvent } = useTelemetryHelper();
    const { formatMessage: translate } = useIntl();
    const { selectedTenant: { id: tenantId } } = useTenantsContext();
    const accountId = useSelector(accountGlobalId);
    const [ futureUpdating, setfutureUpdating ] = useState(false);
    const {
        currentPublisher,
        notificationTopics,
        notificationTopicsSeverity,
        publisherIndex,
        hasUserOptin,
        urlTemplate,
    } = useMemo(
        () => {
            const publisher = notificationSettings.publishersWithGroupedTopics[notificationSettings.tabIndex];
            const publisherWithSeverity = notificationSettings.publishersWithSeverity[notificationSettings.tabIndex];
            const entities: IEntityTypeDto[] = publisher.entityTypes;
            const entityUrlTemplate = entities?.length > 0 ? entities[0].urlTemplate : '';
            return {
                currentPublisher: publisher,
                currentPublisherSeverity: publisherWithSeverity,
                notificationTopics: publisher.topicGroups,
                notificationTopicsSeverity: publisherWithSeverity.topicGroups,
                publisherIndex: publisher.publisherIndex,
                hasUserOptin: publisher.isUserOptIn,
                urlTemplate: entityUrlTemplate,
            };
        },
        [ notificationSettings.publishersWithGroupedTopics, notificationSettings.publishersWithSeverity, notificationSettings.tabIndex ],
    );

    const EnableSlackNotification = useFeatureFlagValue(
        Features.EnableSlackNotification.name,
    );

    const { data: configurationData } = useSWR(
        tenantId && EnableSlackNotification ? {
            requestUri: ConnectionAffiliates,
            selectedTenantId: tenantId,
            selectedAccontId: accountId,
        } : null,
        getAffiliatedConnection,
    );

    const modeResxMap = useMemo(() => new Map<NotificationMode, string>([
        [ NotificationMode.Email, translate({ id: 'CLIENT_EMAIL' }) ],
        [ NotificationMode.Slack, translate({ id: 'CLIENT_NOTIFICATION_SLACK_NOTIFICATION_MODE' }) ],
        [ NotificationMode.InApp, getEnvVariableValue('PRODUCT_NAME') || translate({ id: 'CLIENT_CLOUDRPA_TITLE' }) ],
    ]), [ translate ]) ;

    const [ severitymode, setMode ] = useState(false);
    const isPublisherModeVisible = (modeName: NotificationMode) => !!currentPublisher.modes.find(mode => mode.name === modeName);
    const isPublisherTopicsHasModeVisible = (modeName: NotificationMode) => {
        let hasMode = false;
        for (let i = 0; i < notificationTopics.length; i++) {
            const topicGroup = notificationTopics[i];
            if (topicGroup.topics.find(topic => topic.modes?.find(mode => mode.name === modeName)) !== undefined) {
                hasMode = true;
                break;
            }
        }
        return hasMode;
    };

    const filterUserGroupandEmail = (modeName: NotificationMode) => {
        // To Show Slack Mode only for My Preference and Default User Group and Slack FF enabled
        if ((isUserGroup || !EnableSlackNotification || !configurationData?.isEnabled) && modeName === NotificationMode.Slack) {
            return false;
        }
        if (!isUserGroup || isLocalGroup || hasEmail || modeName === NotificationMode.InApp) {
            return true;
        }
        return false;
    };
    const showOnlyIfHaveEmailModeForUserGroup = (topicGroup: INotificationTopicGroup) => {
        if (!isUserGroup) {
            return true;
        }
        let hasEmailMode = false;
        if (topicGroup.topics.find(topic => topic.modes?.find(mode => mode.name === NotificationMode.Email)) !== undefined) {
            hasEmailMode = true;
        }
        return hasEmailMode;
    };

    useEffect(() => {
        const severitymodeState = sessionStorage.getItem('severitymode');
        if (severitymodeState && notificationTopicsSeverity.length > 1 && !isManagedMode) {
            setMode(severitymodeState === 'true');
        } else {
            setMode(false);
        }
    }, [ isManagedMode, notificationTopicsSeverity ]);

    const changeSelectionMode = (state: boolean) => {
        setMode(state);
        sessionStorage.setItem('severitymode', state.toString());
    };
    const changeTopicSubscription = useCallback(
        async (groupIndex: number, topicIndex: number, mode: NotificationMode, isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION,
                data: {
                    publisherIndex,
                    groupIndex,
                    topicIndex,
                    mode,
                },
            });
            const topicId = currentPublisher.topicGroups[groupIndex].topics[topicIndex].topicId;
            const topicState = {
                topicId,
                isSubscribed,
                notificationMode: mode,
            };
            try {
                logEvent(`NotificationSettings.SubscriptionChange`, {
                    TopicName: currentPublisher.topicGroups[groupIndex].topics[topicIndex].displayName,
                    ServiceName: currentPublisher.displayName,
                    IsSubscribed: isSubscribed ? 'Subscribed' : 'UnSubscribed',
                    NotificationMode: mode,
                });
                await updateUserNotificationTopicSubscription([ topicState ], tenantId);
                dispatch({
                    type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        topicIndex,
                        isSubscribed,
                        mode,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        topicIndex,
                        mode,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, logEvent, tenantId, handleError ],
    );

    const changeTopicSubscriptionManageMode = useCallback(
        async (groupIndex: number, topicIndex: number, mode: NotificationMode, isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION,
                data: {
                    publisherIndex,
                    groupIndex,
                    topicIndex,
                    mode,
                },
            });
            const topic: INotificationTopic = currentPublisher.topicGroups[groupIndex].topics[topicIndex];
            const topicId = currentPublisher.topicGroups[groupIndex].topics[topicIndex].topicId;
            const payLoad: IProfileSubscriptionPayload[] = [];
            const topicState: IProfileSubscriptionPayload = {
                topicId,
                isSubscribed,
                notificationMode: mode,
                isMandatory: topic.isMandatory,
                isVisible: topic.isVisible,
            };

            payLoad.push(topicState);
            try {
                logEvent(`NotificationSettings.${isSubscribed ? 'Check' : 'Uncheck'}${mode}Preference`);
                await updateSelectedOption(payLoad);
                dispatch({
                    type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        topicIndex,
                        isSubscribed,
                        mode,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        topicIndex,
                        mode,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, logEvent, updateSelectedOption, handleError ],
    );

    const changeTopicSubscriptionIsMandatory = useCallback(
        async (groupIndex: number, topicIndex: number, isMandatory: boolean) => {
            dispatch({
                type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_MANDATORY,
                data: {
                    publisherIndex,
                    groupIndex,
                    topicIndex,
                },
            });
            const topic: INotificationTopic = currentPublisher.topicGroups[groupIndex].topics[topicIndex];
            const topicId = currentPublisher.topicGroups[groupIndex].topics[topicIndex].topicId;
            const payLoad: IProfileSubscriptionPayload[] = [];

            topic.modes?.forEach(mode => {
                const topicEmailState: IProfileSubscriptionPayload = {
                    topicId,
                    isSubscribed: mode.isSubscribed ?? false,
                    notificationMode: mode.name,
                    isMandatory,
                    isVisible: topic.isVisible,
                };
                payLoad.push(topicEmailState);
            });

            try {
                logEvent(`NotificationSettings.${isMandatory ? 'Check' : 'Uncheck'} IsMandatory Preference`);
                await updateSelectedOption(payLoad);
                dispatch({
                    type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_MANDATORY_UPDATED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        topicIndex,
                        isSubscribed: isMandatory,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        topicIndex,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, logEvent, updateSelectedOption, handleError ],
    );

    const changeTopicGroupSubscription = useCallback(
        async (groupIndex: number, mode: NotificationMode, isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION,
                data: {
                    publisherIndex,
                    groupIndex,
                    mode,
                },
            });
            const topicStatesList = currentPublisher.topicGroups[groupIndex].topics
                .filter(x => !x.isMandatory && x.isVisible).map((topic: INotificationTopic) => ({
                    topicId: topic.topicId,
                    isSubscribed,
                    notificationMode: mode,
                }));
            try {
                logEvent(`NotificationSettings.SubscriptionChange`, {
                    TopicGroupName: currentPublisher.topicGroups[groupIndex].groupName,
                    ServiceName: currentPublisher.displayName,
                    IsSubscribed: isSubscribed ? 'Subscribed' : 'UnSubscribed',
                    NotificationMode: mode,
                });
                await updateUserNotificationTopicSubscription(topicStatesList, tenantId);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        isSubscribed,
                        mode,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        mode,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, currentPublisher.displayName, logEvent, tenantId, handleError ],
    );

    const changeTopicGroupSubscriptionManageMode = useCallback(
        async (groupIndex: number, mode: NotificationMode, isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION,
                data: {
                    publisherIndex,
                    groupIndex,
                    mode,
                },
            });
            const topicGroup = currentPublisher.topicGroups[groupIndex];
            const payLoad: IProfileSubscriptionPayload[] = [];
            topicGroup.topics.filter(x => x.isVisible).forEach((topic) => {
                if (topic.modes?.find(x => x.name === mode) !== undefined) {
                    const topicState: IProfileSubscriptionPayload = {
                        topicId: topic.topicId,
                        isSubscribed,
                        notificationMode: mode,
                        isMandatory: topic.isMandatory,
                        isVisible: topic.isVisible,
                    };
                    payLoad.push(topicState);
                }
            });
            try {
                logEvent(`NotificationSettings.${isSubscribed ? 'Check' : 'Uncheck'}${mode}Preference`);
                await updateSelectedOption(payLoad);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        isSubscribed,
                        mode,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        mode,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, logEvent, updateSelectedOption, handleError ],
    );

    const changeTopicGroupSubscriptionIsMandatory = useCallback(
        async (groupIndex: number, isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_MANDATORY_SUBSCRIPTION,
                data: {
                    publisherIndex,
                    groupIndex,
                    severitymode,
                },
            });
            const topicGroup = currentPublisher.topicGroups[groupIndex];
            const payLoad: IProfileSubscriptionPayload[] = [];
            topicGroup.topics.filter(x => x.isVisible).forEach((topic) => {
                topic.modes?.forEach(mode => {
                    const topicEmailState: IProfileSubscriptionPayload = {
                        topicId: topic.topicId,
                        isSubscribed: mode.isSubscribed ?? topic.isSubscribed,
                        notificationMode: mode.name,
                        isMandatory: isSubscribed,
                        isVisible: topic.isVisible,
                    };
                    payLoad.push(topicEmailState);
                });
            });
            try {
                logEvent(`NotificationSettings.${isSubscribed ? 'Check' : 'Uncheck'} Preference`);
                await updateSelectedOption(payLoad);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_MANDATORY_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        isSubscribed,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_MANDATORY_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        groupIndex,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, severitymode, currentPublisher.topicGroups, logEvent, updateSelectedOption, handleError ],
    );

    const changePublisherSubscription = useCallback(
        async (mode: NotificationMode, isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION,
                data: {
                    publisherIndex,
                    mode,
                },
            });
            const topicStatesList = currentPublisher.topicGroups.flatMap(topicgroup => topicgroup.topics)
                .filter(x => !x.isMandatory && x.isVisible)
                .map((topic) => ({
                    topicId: topic.topicId,
                    isSubscribed,
                    notificationMode: mode,
                }));
            try {
                logEvent(`NotificationSettings.SubscriptionChange`, {
                    ServiceName: currentPublisher.displayName,
                    IsSubscribed: isSubscribed ? 'Subscribed' : 'UnSubscribed',
                    NotificationMode: mode,
                });
                await updateUserNotificationTopicSubscription(topicStatesList, tenantId);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        isSubscribed,
                        mode,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        mode,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, currentPublisher.displayName, logEvent, tenantId, handleError ],
    );
    const changePublisherSubscriptionManageMode = useCallback(
        async (mode: NotificationMode, isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION,
                data: {
                    publisherIndex,
                    mode,
                },
            });
            const payLoad: IProfileSubscriptionPayload[] = [];
            currentPublisher.topicGroups.filter(x => x.isVisible).forEach((topicGroup) => {
                topicGroup.topics.filter(x => x.isVisible).forEach((topic) => {
                    if (topic.modes?.find(x => x.name === mode) !== undefined) {
                        const topicEmailState: IProfileSubscriptionPayload = {
                            topicId: topic.topicId,
                            isSubscribed,
                            notificationMode: mode,
                            isMandatory: topic.isMandatory,
                            isVisible: topic.isVisible,
                        };
                        payLoad.push(topicEmailState);
                    }
                });
            });
            try {
                logEvent(`NotificationSettings.${isSubscribed ? 'Check' : 'Uncheck'}${mode}Preference`);
                await updateSelectedOption(payLoad);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        isSubscribed,
                        mode,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        mode,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, logEvent, updateSelectedOption, handleError ],
    );
    const changePublisherSubscriptionMandatory = useCallback(
        async (isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_TOGGLE_PUBLISHER_MANDATORY_SUBSCRIPTION,
                data: { publisherIndex },
            });

            const payLoad: IProfileSubscriptionPayload[] = [];
            currentPublisher.topicGroups.filter(x => x.isVisible).forEach((topicGroup) => {
                topicGroup.topics.filter(x => x.isVisible).forEach((topic) => {

                    topic.modes?.forEach(mode => {
                        const topicEmailState: IProfileSubscriptionPayload = {
                            topicId: topic.topicId,
                            isSubscribed: mode.isSubscribed ?? topic.isSubscribed,
                            notificationMode: mode.name,
                            isMandatory: isSubscribed,
                            isVisible: topic.isVisible,
                        };
                        payLoad.push(topicEmailState);
                    });
                });
            });

            try {
                logEvent(`NotificationSettings.${isSubscribed ? 'Check' : 'Uncheck'}$ Preference`);
                await updateSelectedOption(payLoad);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_PUBLISHER_MANDATORY_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        isSubscribed,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_PUBLISHER_MANDATORY_SUBSCRIPTION_UPDATE_FAILED,
                    data: { publisherIndex },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, logEvent, updateSelectedOption, handleError ],
    );
    const changeTopicSeverityMode = useCallback(
        async (groupIndex: number, severity: string, mode: NotificationMode, isSubscribed: boolean) => {

            dispatch({
                type: ActionTypes.NS_TOGGLE_TOPIC_SUBSCRIPTION_SEVERITY,
                data: {
                    publisherIndex,
                    groupIndex,
                    mode,
                },
            });
            try {
                logEvent(`NotificationSettings.SubscriptionChange`, {
                    ServiceName: currentPublisher.displayName,
                    IsSubscribed: isSubscribed ? 'Subscribed' : 'UnSubscribed',
                    NotificationMode: mode,
                    Severity: severity,
                });
                const publisherid = currentPublisher.publisherId;
                const topicState = {
                    publisherId: publisherid,
                    notificationMode: mode,
                    isSubscribed,
                    category: severity,
                };
                await updateUserNotificationSeveritySubscriptionMode([ topicState ], tenantId);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_SUBSCRIPTION_SEVERITY_UPDATED,
                    data: {
                        publisherid,
                        severity,
                        isSubscribed,
                        mode,
                        groupIndex,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_SUBSCRIPTION_SEVERITY_UPDATED_FAILED,
                    data: {
                        publisherIndex,
                        mode,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, logEvent, currentPublisher.displayName, currentPublisher.publisherId, tenantId, handleError ],
    );
    const changeTopicGroupSubscriptionIsVisible = useCallback(
        async (groupIndex: number, isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_VISIBLE_SUBSCRIPTION,
                data: {
                    publisherIndex,
                    groupIndex,
                },
            });
            const topicGroup = currentPublisher.topicGroups[groupIndex];
            const payLoad: IProfileSubscriptionPayload[] = [];
            topicGroup.topics.forEach((topic) => {

                topic.modes?.forEach(mode => {
                    const topicEmailState: IProfileSubscriptionPayload = {
                        topicId: topic.topicId,
                        isSubscribed: false,
                        notificationMode: mode.name,
                        isMandatory: false,
                        isVisible: isSubscribed,
                    };
                    payLoad.push(topicEmailState);
                });
            });
            try {
                logEvent(`NotificationSettings.${isSubscribed ? 'Check' : 'Uncheck'} Preference`);
                await updateSelectedOption(payLoad);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_VISIBLE_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        isSubscribed,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_VISIBLE_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        groupIndex,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, logEvent, updateSelectedOption, handleError ],
    );
    const changeTopicSubscriptionIsVisible = useCallback(
        async (groupIndex: number, topicIndex: number, isVisible: boolean) => {
            dispatch({
                type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_VISIBLE,
                data: {
                    publisherIndex,
                    groupIndex,
                    topicIndex,
                },
            });
            const selectedtopic: INotificationTopic = currentPublisher.topicGroups[groupIndex].topics[topicIndex];
            const selectedtopicId = currentPublisher.topicGroups[groupIndex].topics[topicIndex].topicId;
            const payLoad: IProfileSubscriptionPayload[] = [];

            selectedtopic.modes?.forEach(mode => {
                const topicEmailState: IProfileSubscriptionPayload = {
                    topicId: selectedtopicId,
                    isSubscribed: false,
                    notificationMode: mode.name,
                    isMandatory: false,
                    isVisible,
                };
                payLoad.push(topicEmailState);
            });

            try {
                logEvent(`NotificationSettings.${isVisible ? 'Check' : 'Uncheck'} isVisible Preference`);
                await updateSelectedOption(payLoad);
                dispatch({
                    type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_VISIBLE_UPDATED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        topicIndex,
                        isSubscribed: isVisible,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_VISIBLE_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        topicIndex,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, logEvent, updateSelectedOption, handleError ],
    );

    const isTopicModeDisabled = useCallback((topicGroup: INotificationTopicGroup, topic: INotificationTopic, mode: INotificationMode) =>
        !!topicGroup.modes.find(m => m.name === mode.name)?.isUpdating ||
                                                !!currentPublisher.modes.find(m => m.name === mode.name)?.isUpdating ||
                                                topic.isMandatory || !topic.isVisible, [ currentPublisher?.modes ]);

    const isSlackDefaultDisabled = useCallback((topic: INotificationTopic, mode: INotificationMode) =>
        !isManagedMode && mode.name === NotificationMode.Slack &&
    !topic.modes?.find(x => x.name === mode.name)?.isSubscribedByDefault, [ isManagedMode ]);

    const isSlackTopicGroupDefaultDisabled = useCallback((topicGroup: INotificationTopicGroup, mode: INotificationMode) =>
        !isManagedMode && mode.name === NotificationMode.Slack &&
    !topicGroup.topics.find(x => x.modes?.find(y => y.name === NotificationMode.Slack && y.isSubscribedByDefault) !== undefined), [ isManagedMode ]);

    const getCategoryClass = useCallback((category: string) => {
        switch (category) {
            case NotificationSeverity.Error:
                return classes.fatalCategoryChip;

            case NotificationSeverity.Fatal:
                return classes.fatalCategoryChip;

            case NotificationSeverity.Info:
                return classes.infoCategoryChip;

            case NotificationSeverity.Success:
                return classes.successCategoryChip;

            case NotificationSeverity.Warn:
                return classes.warnCategoryChip;
        }
    }, [ classes.fatalCategoryChip, classes.infoCategoryChip, classes.successCategoryChip, classes.warnCategoryChip ]);

    const [ showSlackSettingsDialog, setShowSlackSettingsDialog ] = useState(false);

    const getTopicContent = (topicGroup: INotificationTopicGroup) => topicGroup.topics.map((topic, index) => (
        <>
            {(topic.isVisible || isManagedMode) && <TableRow
                key={index}
                className={classes.tableBodyRow}>
                {isManagedMode && isAdmin && !isUserGroup && <TableCell
                    className={clsx(
                        classes.tableVisibleCell,
                        classes.tableHeaderMainCell)}
                    align='left'>
                    {topic.isVisible && <Tooltip
                        arrow
                        title={translate({ id: 'CLIENT_NS_Visible_Tooltip' })}>
                        <IconButton onClick={() =>
                            changeTopicSubscriptionIsVisible(topicGroup.groupIndex, topic.topicIndex, false)}>
                            <CustomVisibilityOutlinedIcon
                                data-cy="topicvisibleicon"
                            />
                        </IconButton>
                    </Tooltip>}
                    {!topic.isVisible && <Tooltip
                        arrow
                        title={translate({ id: 'CLIENT_NS_NotVisible_Tooltip' })}>
                        <IconButton onClick={() =>
                            changeTopicSubscriptionIsVisible(topicGroup.groupIndex, topic.topicIndex, true)}>
                            <CustomVisibilityOffOutlinedIcon
                            />
                        </IconButton>
                    </Tooltip>}
                </TableCell>}
                <TableCell className={clsx(
                    classes.tableCell,
                    classes.tableMainCell,
                    classes.tableBodyMainCell,
                    topic.isVisible ? '' : classes.tableCellDisabledColor)}>
                    <div className={classes.overflowDiv}>
                        {topic.displayName}
                    </div>
                    {topic.description && topic.description.toLowerCase() !== topic.displayName.toLowerCase() && <Tooltip
                        arrow
                        title={topic.description}>
                        <InfoOutlined
                            className={classes.infoIcon}
                            tabIndex={0}
                            aria-label={topic.description}
                        />
                    </Tooltip>}
                </TableCell>
                {!severitymode && <TableCell
                    className={clsx(
                        classes.tableCell)}
                    align='left'>

                    <Chip
                        size="small"
                        style={{ marginRight: '8px' }}
                        label={topic.category}
                        className={getCategoryClass(topic.category)}
                    />
                </TableCell>}
                {isManagedMode && !isUserGroup && <TableCell
                    key={3}
                    className={clsx(
                        classes.tableCell)}
                    align='left'>

                    <Checkbox
                        data-testid="topic_MANDATORY"
                        aria-label={translate({ id: 'CLIENT_NS_Mandatory' })}
                        inputProps={{ 'aria-label': `${translate({ id: 'CLIENT_NS_Mandatory' })} ${topic.name}` }}
                        checked={topic.isMandatory}
                        disabled={!topic.isVisible}
                        onChange={(e) => changeTopicSubscriptionIsMandatory(topicGroup.groupIndex, topic.topicIndex, e.target.checked)}
                    />
                </TableCell>}
                {
                    Object.values(NotificationMode)
                        .filter(modeName => isPublisherModeVisible(modeName))
                        .filter(mode => filterUserGroupandEmail(mode))
                        .map(modeName => topic.modes!.find(mode => mode.name === modeName))
                        .map((mode, cellIndex) => (
                            <TableCell
                                key={cellIndex}
                                className={clsx(
                                    classes.tableCell)}
                                align='left'>
                                {!!mode && !mode.isUpdating ?
                                    <Checkbox
                                        data-cy="topic-checkbox"
                                        inputProps={{ 'aria-label': `${translate({ id: 'CLIENT_NOTIFICATION_CHK_ARIA_TOPIC' })} ${topic.displayName} ${modeResxMap.get(mode.name)}` }}
                                        data-testid={`topic_${topic.name}_${mode.name}`}
                                        aria-label={modeResxMap.get(mode.name)}
                                        checked={(mode.isSubscribed || topic.isMandatory) && !isSlackDefaultDisabled(topic, mode)}
                                        disabled={isTopicModeDisabled(topicGroup, topic, mode) || isSlackDefaultDisabled(topic, mode)}
                                        onChange={(e) => isManagedMode ?
                                            changeTopicSubscriptionManageMode(topicGroup.groupIndex, topic.topicIndex, mode.name, e.target.checked)
                                            : changeTopicSubscription(topicGroup.groupIndex, topic.topicIndex, mode.name, e.target.checked)}
                                    />
                                    : (!!mode && <div className={classes.loaderContainer}>
                                        <CircularProgress
                                            size={16}
                                            thickness={4} />
                                    </div>)}
                                {
                                    !mode && <Checkbox
                                        checked={false}
                                        disabled />
                                }
                            </TableCell>
                        ))
                }
            </TableRow>}
        </>
    ));

    const getTopicGroupsHtml = () => {
        const topicGroups = severitymode ? notificationTopicsSeverity : notificationTopics;

        return (topicGroups?.filter(topicGroup => topicGroup.groupName !== 'un-grouped')
            .map((topicGroup: INotificationTopicGroup, index: number) => (
                <>
                    {(topicGroup.isVisible || isManagedMode) && showOnlyIfHaveEmailModeForUserGroup(topicGroup) && <TableRow
                        key={index}
                        className={clsx(classes.tableBodyRow, !severitymode ? classes.tableSubHeaderRow : '')}>
                        {isManagedMode && isAdmin && !isUserGroup && <TableCell
                            className={clsx(
                                classes.tableVisibleCell,
                                severitymode ? '' : classes.tableSubHeaderCell,
                                classes.tableHeaderMainCell)}
                            align='left'>
                            {topicGroup.isVisible && <Tooltip
                                arrow
                                title={translate({ id: 'CLIENT_NS_Visible_Tooltip' })}>
                                <IconButton onClick={() =>
                                    changeTopicGroupSubscriptionIsVisible(topicGroup.groupIndex, false)}>
                                    <CustomVisibilityOutlinedIcon
                                        data-cy="topicgroupvisibleicon"
                                        tabIndex={0}
                                        aria-label={translate({ id: 'CLIENT_NS_Visible' })}
                                    />
                                </IconButton>
                            </Tooltip>}
                            {!topicGroup.isVisible &&
                            <Tooltip
                                arrow
                                title={translate({ id: 'CLIENT_NS_NotVisible_Tooltip' })}>
                                <IconButton onClick={() =>
                                    changeTopicGroupSubscriptionIsVisible(topicGroup.groupIndex, true)}>
                                    <CustomVisibilityOffOutlinedIcon
                                        tabIndex={0}
                                        aria-label={translate({ id: 'CLIENT_NS_Visible' })}
                                    />
                                </IconButton>
                            </Tooltip>}
                        </TableCell>}
                        <TableCell
                            className={clsx(
                                classes.tableCell,
                                classes.tableMainCell,
                                severitymode ? '' : classes.tableSubHeaderCell,
                                severitymode ? classes.tableSubHeaderMainSeverityCell : classes.tableSubHeaderMainCell)}>
                            <div className={classes.overflowDiv}>
                                {topicGroup.groupName}
                            </div>
                        </TableCell>
                        { !severitymode && <TableCell
                            className={clsx(
                                classes.tableCell,
                                classes.tableSubHeaderCell)}
                            align='left' /> }
                        {isManagedMode && !isUserGroup && <TableCell
                            key={3}
                            className={clsx(
                                classes.tableCell,
                                severitymode ? '' : classes.tableSubHeaderCell)}
                            align='left'>

                            <Checkbox
                                data-testid="topicgroup_MANDATORY"
                                aria-label={translate({ id: 'CLIENT_NS_Mandatory' })}
                                inputProps={{ 'aria-label': `${translate({ id: 'CLIENT_NS_Mandatory' })} ${topicGroup.groupName}` }}
                                checked={topicGroup.isMandatoryChecked}
                                disabled={!topicGroup.isVisible}
                                indeterminate={topicGroup.isMandatoryIndeterminate}
                                onChange={(e) => changeTopicGroupSubscriptionIsMandatory(topicGroup.groupIndex, e.target.checked)}
                            />
                        </TableCell>}
                        {
                            Object.values(NotificationMode)
                                .filter(mode => isPublisherModeVisible(mode))
                                .filter(mode => filterUserGroupandEmail(mode))
                                .map(modeName => topicGroup.modes.find(mode => mode.name === modeName))
                                .map((mode, cellIndex) => (
                                    <TableCell
                                        key={cellIndex}
                                        className={clsx(
                                            classes.tableCell,
                                            severitymode ? '' : classes.tableSubHeaderCell)}
                                        align='left'>
                                        {!!mode && !mode.isUpdating
                                                && topicGroup.topics.find(x => x.modes?.find(y => y.name === mode.name)) ?
                                            !severitymode ?
                                                <Checkbox
                                                    inputProps={{ 'aria-label': `${translate({ id: 'CLIENT_NOTIFICATION_CHK_ARIA_TOPICGROUP' })} ${topicGroup.groupName} ${modeResxMap.get(mode.name)}` }}
                                                    data-cy="topic-groupcheckbox"
                                                    aria-label={modeResxMap.get(mode.name)}
                                                    checked={mode.isSubscribed || topicGroup.isMandatoryChecked}
                                                    indeterminate={mode.isIndeterminate && !topicGroup.isMandatoryChecked}
                                                    disabled={currentPublisher.modes.find(m => m.name === mode.name)?.isUpdating
                                                        || topicGroup.isMandatoryChecked
                                                        || !topicGroup.isVisible}
                                                    onChange={(e) => isManagedMode ?
                                                        changeTopicGroupSubscriptionManageMode(topicGroup.groupIndex,
                                                            mode.name, e.target.checked)
                                                        : changeTopicGroupSubscription(topicGroup.groupIndex, mode.name, e.target.checked)}
                                                /> : <Checkbox
                                                    aria-label={modeResxMap.get(mode.name)}
                                                    inputProps={{ 'aria-label': `${translate({ id: 'CLIENT_NOTIFICATION_CHK_ARIA_TOPICGROUP' })} ${topicGroup.groupName} ${modeResxMap.get(mode.name)}` }}
                                                    checked={(mode.isSubscribed || topicGroup.isMandatoryChecked)
                                                        && !isSlackTopicGroupDefaultDisabled(topicGroup, mode)}
                                                    indeterminate={mode.isIndeterminate}
                                                    disabled={currentPublisher.modes.find(m => m.name === mode.name)?.isUpdating ||
                                                        topicGroup.isMandatoryChecked ||
                                                        isSlackTopicGroupDefaultDisabled(topicGroup, mode)}
                                                    onChange={(e) =>
                                                        changeTopicSeverityMode(topicGroup.groupIndex,
                                                            topicGroup.groupName, mode.name, e.target.checked)}
                                                />
                                            : (!!mode && topicGroup.topics.find(x => x.modes?.find(y => y.name === mode.name)) &&
                                                    <div className={classes.loaderContainer}>
                                                        <CircularProgress
                                                            size={16}
                                                            thickness={4} />
                                                    </div>)}
                                        {
                                            (!mode || !topicGroup.topics.find(x => x.modes?.find(y => y.name === mode.name))) && <Checkbox
                                                checked={false}
                                                disabled />
                                        }
                                    </TableCell>
                                ))
                        }
                    </TableRow>}
                    {!severitymode && showOnlyIfHaveEmailModeForUserGroup(topicGroup) && getTopicContent(topicGroup)}
                </>
            ))
        );
    };
    const handleUserOptInChange = useCallback(
        async (event: ChangeEvent<HTMLInputElement>) => {
            const { target: { checked } } = event;
            setfutureUpdating(true);

            const payload = {
                publisherID: currentPublisher.publisherId,
                isUserOptIn: checked,
                entities: [],
            } as IFolderUpdatePayload;

            await updateUserPublisherSubscriptionSubscription(payload, tenantId);
            currentPublisher.isUserOptIn = checked;
            setfutureUpdating(false);
        },
        [ currentPublisher?.publisherId, publisherIndex, tenantId ],
    );

    return (
        <>
            <TableContainer>
                {!!currentPublisher && urlTemplate !== '' && <div>
                    <span className={classes.recieveby}>
                        {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_NOTIFICATION_RECEIVED_FOLDERS' })}
                    </span>
                    <DynamicFilterComponent {...{
                        handleError,
                        urlTemplate,
                        publisherName: currentPublisher.name,
                        hasUserOptin,
                    }} />

                    <FormControlLabel
                        sx={{ marginBottom: 1 }}
                        style={{ marginLeft: '0px' }}
                        control={futureUpdating === false ? <Checkbox
                            checked={hasUserOptin}
                            onChange={handleUserOptInChange} /> : <CircularProgress
                            size={16}
                            style={{
                                float: 'left',
                                margin: '13px',
                            }}
                            thickness={4} />}
                        label={
                            <UiText className={classes.recieveby}>
                                {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_NOTIFICATION_FUTUREOPTION' })}
                            </UiText>
                        }
                    />
                </div>}
                {notificationTopicsSeverity.length > 1 && !isManagedMode &&
                        <div>
                            <span className={classes.recieveby}>
                                {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_NOTIFICATION_RECEIVEDBY' })}
                            </span>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                className={classes.display}>
                                <FormControlLabel
                                    checked={!severitymode}
                                    control={<Radio />}
                                    className={classes.categoryText}
                                    label={
                                        <UiText>
                                            {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_NOTIFICATION_RECEIVEDBY_CATEGORY_EVENTS' })}
                                        </UiText>
                                    }
                                    onChange={(e) => changeSelectionMode(false)} />
                                <FormControlLabel
                                    control={<Radio />}
                                    checked={severitymode}
                                    className={clsx(
                                        classes.categoryText,
                                        classes.radioMargin)}
                                    label={
                                        <UiText>
                                            {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_NOTIFICATION_RECEIVEDBY_CATEGORY_SEVERITY' })}
                                        </UiText>
                                    }
                                    onChange={(e) => changeSelectionMode(true)} />
                            </RadioGroup>
                        </div>}
                <Table aria-label={translate({ id: severitymode ? 'CLIENT_NOTIFICATION_PREFERENCES_NOTIFICATION_RECEIVEDBY_CATEGORY_SEVERITY' : 'CLIENT_NOTIFICATION_PREFERENCES_NOTIFICATION_EVENTS' })}>
                    <TableHead>
                        <TableRow>
                            {isManagedMode && isAdmin && !isUserGroup && <TableCell
                                className={clsx(
                                    classes.tableVisibleCell,
                                    classes.tableHeaderCell,
                                    classes.tableHeaderMainCell)}
                                align='left'>
                                <div className={classes.overflowDiv}>
                                    {translate({ id: 'CLIENT_NS_Visible' })}
                                </div>
                            </TableCell>}
                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.tableMainCell,
                                    classes.tableHeaderCell,
                                    classes.tableHeaderMainCell)}
                                align='left'>
                                <div className={classes.overflowDiv}>
                                    {translate({ id: severitymode ? 'CLIENT_NOTIFICATION_PREFERENCES_NOTIFICATION_RECEIVEDBY_CATEGORY_SEVERITY' : 'CLIENT_NOTIFICATION_PREFERENCES_NOTIFICATION_EVENTS' })}
                                </div>
                            </TableCell>
                            { !severitymode && <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.tableHeaderCell)}
                                align='left'>
                                <div className={classes.overflowDiv}>
                                    {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_NOTIFICATION_RECEIVEDBY_CATEGORY_SEVERITY' })}
                                </div>
                            </TableCell> }
                            {isManagedMode && !isUserGroup && <TableCell
                                key={3}
                                className={clsx(classes.tableCell, classes.tableHeaderCell, classes.overflowDiv)}
                                align='left'>

                                <Checkbox
                                    name='publisher_MANDATORY'
                                    inputProps={{ 'aria-label': 'publisher_MANDATORY' }}
                                    data-cy="publisher-groupcheckbox"
                                    data-testid="publisher_MANDATORY"
                                    style={{ marginLeft: '0px' }}
                                    disabled={!currentPublisher.isVisible}
                                    checked={currentPublisher.isMandatory}
                                    indeterminate={currentPublisher.isMandatoryInDeterminate}
                                    onChange={(e) => changePublisherSubscriptionMandatory(e.target.checked)}
                                />
                                <div className={classes.overflowDiv}>
                                    {translate({ id: 'CLIENT_NS_Mandatory' })}
                                </div>
                                <Tooltip
                                    arrow
                                    title={translate({ id: 'CLIENT_NS_Mandatory_TOOLTIP' })}>
                                    <InfoOutlined
                                        className={classes.infoIcon}
                                        tabIndex={0}
                                        aria-label={translate({ id: 'CLIENT_NS_Mandatory_TOOLTIP' })}
                                    />
                                </Tooltip>
                            </TableCell>}
                            {
                                Object.values(NotificationMode)
                                    .filter(mode => isPublisherTopicsHasModeVisible(mode))
                                    .filter(mode => filterUserGroupandEmail(mode))
                                    .map(modeName => currentPublisher.modes.find(mode => mode.name === modeName))
                                    .map((mode, cellIndex) => (!!mode &&
                                            <TableCell
                                                key={cellIndex}
                                                className={clsx(classes.tableCell, classes.tableHeaderCell)}
                                                align='left'>
                                                <FormControlLabel
                                                    style={{ marginLeft: '0px' }}
                                                    className={classes.overflowDiv}
                                                    control={!mode.isUpdating ?
                                                        <Checkbox
                                                            checked={mode.isSubscribed ?? currentPublisher.isMandatory}
                                                            disabled={currentPublisher.isMandatory || !currentPublisher.isVisible}
                                                            indeterminate={mode.isIndeterminate && !currentPublisher.isMandatory}
                                                            onChange={(e) => isManagedMode ? changePublisherSubscriptionManageMode(mode.name, e.target.checked) : changePublisherSubscription(mode.name, e.target.checked)} />
                                                        : (<div className={classes.loaderContainer}>
                                                            <CircularProgress
                                                                size={16}
                                                                thickness={4} />
                                                        </div>)}
                                                    label={
                                                        <UiText className={clsx(classes.header, classes.overflowDiv)}>
                                                            {modeResxMap.get(mode.name)}
                                                        </UiText>
                                                    } />
                                                {isManagedMode && mode.name === NotificationMode.Slack && <IconButton
                                                    className={classes.slackSettings}
                                                    onClick={() => {
                                                        setShowSlackSettingsDialog(true);
                                                    }} >
                                                    <CustomSettingsIcon
                                                        className={clsx(classes.header, classes.overflowDiv)} />
                                                </IconButton>}
                                            </TableCell>
                                    ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!severitymode && notificationTopics[0].topics
                            .map((topic, index) => (
                                <TableRow
                                    key={index}
                                    className={classes.tableBodyRow}>
                                    <TableCell className={clsx(
                                        classes.tableCell,
                                        classes.tableMainCell,
                                        classes.tableBodyMainCell)}>
                                        <div className={classes.overflowDiv}>
                                            {topic.displayName}
                                        </div>
                                    </TableCell>
                                    {isManagedMode && <TableCell
                                        key={index}
                                        className={clsx(
                                            classes.tableCell)}
                                        align='left'>

                                        <Checkbox
                                            data-testid="topic_MANDATORY"
                                            aria-label={translate({ id: 'CLIENT_NS_Mandatory' })}
                                            inputProps={{ 'aria-label': `${translate({ id: 'CLIENT_NS_Mandatory' })} ${topic.name}` }}
                                            checked={topic.isMandatory}
                                        />
                                        {' '}
                                        {translate({ id: 'CLIENT_NS_Mandatory' })}
                                    </TableCell>}
                                    {
                                        Object.values(NotificationMode)
                                            .filter(mode => isPublisherModeVisible(mode))
                                            .filter(mode => filterUserGroupandEmail(mode))
                                            .map(modeName => topic.modes?.find(mode => mode.name === modeName))
                                            .map((mode, cellIndex) => (
                                                <TableCell
                                                    key={cellIndex}
                                                    className={clsx(
                                                        classes.tableCell)}
                                                    align='left'>
                                                    {!!mode && !mode.isUpdating ?
                                                        <Checkbox
                                                            aria-label={modeResxMap.get(mode.name)}
                                                            inputProps={{ 'aria-label': `${translate({ id: 'CLIENT_NOTIFICATION_CHK_ARIA_PUBLISHER' })} ${currentPublisher.name} ${modeResxMap.get(mode.name)}` }}
                                                            checked={mode.isSubscribed ?? topic.isMandatory}
                                                            disabled={currentPublisher.modes.find(m => m.name === mode.name)?.isUpdating}
                                                            onChange={(e) => isManagedMode ? changeTopicSubscriptionManageMode(0, topic.topicIndex, mode.name, e.target.checked) :
                                                                changeTopicSubscription(0, topic.topicIndex, mode.name, e.target.checked)}
                                                        /> :
                                                        (!!mode && <div className={classes.loaderContainer}>
                                                            <CircularProgress
                                                                size={16}
                                                                thickness={4} />
                                                        </div>)}
                                                </TableCell>
                                            ))
                                    }
                                </TableRow>
                            ))}
                        {getTopicGroupsHtml()}
                    </TableBody>
                </Table>
            </TableContainer>
            {showSlackSettingsDialog && <SlackPublisherSettings
                connectionId={configurationData?.supplementConnectionId ?? ''}
                defaultChannelName={configurationData?.channelName ?? ''}
                defaultChannelId={configurationData?.channelKey ?? ''}
                publisherChannelId={currentPublisher.publisherSubscriptionProfiles[0]?.channelKey}
                publisherChannelName={currentPublisher.publisherSubscriptionProfiles[0]?.channelName}
                publisherName={currentPublisher.name}
                onChange={async (slackChannel: ISlackChannel) => {

                    const slackState: IPublisherProfileSubscriptionPayload = {
                        publisherId: currentPublisher.publisherId,
                        notificationMode: NotificationMode.Slack,
                        channelKey: slackChannel.id,
                        channelName: slackChannel.name,
                    };
                    await updateSelectedOption([], [ slackState ]);
                }}
                close={() => setShowSlackSettingsDialog(false)} />}
        </>
    );
};

export default NotificationModePreferencesComponent;
