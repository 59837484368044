import { UiText } from '@experiences/ui-common';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'space-between',
            marginTop: '40px',
        },
        box: {
            display: 'flex',
            flexDirection: 'column',
        },
        header: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '16px',
            color: theme.palette.semantic.colorForeground,
        },
        list: { background: 'none' },
        listItem: {
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        resourceHeader: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            marginBottom: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        resourceLink: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '16px',
        },
    }),
);

const SAMLInfoPageComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    return <Box className={classes.container}>
        <Box className={classes.box}>
            <UiText className={classes.header}>
                {translate({ id: 'CLIENT_BEFORE_STARTING_SAML' })}
            </UiText>
            <List
                sx={{
                    listStyleType: 'disc',
                    pl: 4,
                }}
                className={classes.list}>
                <ListItem
                    sx={{ display: 'list-item' }}
                    className={classes.listItem}>
                    {translate({ id: 'CLIENT_INFO_POINT_1' })}
                </ListItem>
                <ListItem
                    sx={{ display: 'list-item' }}
                    className={classes.listItem}>
                    {translate({ id: 'CLIENT_INFO_POINT_2' })}
                </ListItem>
                <ListItem
                    sx={{ display: 'list-item' }}
                    className={classes.listItem}>
                    {translate({ id: 'CLIENT_INFO_POINT_3' })}
                </ListItem>
            </List>
        </Box>
        <Box className={classes.box}>
            <UiText className={classes.resourceHeader}>
                {translate({ id: 'CLIENT_RESOURCES' })}
            </UiText>
            <Link className={classes.resourceLink}>
                {translate({ id: 'CLIENT_SAML_SSO_SETUP_GUIDE' })}
            </Link>
            <Link className={classes.resourceLink}>
                {translate({ id: 'CLIENT_SAML_IDP_CONFIGURATION_GUIDE' })}
            </Link>
        </Box>
    </Box>;
};

export default SAMLInfoPageComponent;
