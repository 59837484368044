import { portalTelemetry } from '@experiences/telemetry';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import Cookies from 'js-cookie';

import { getSub } from '../auth/utils/Token';

export const StudioDownload = {
    RightNav: 'RightNav widget',
    RightNavLink: 'RightNav link',
    FREBanner: 'FRE banner',
    ResourceCenter: 'Resource center',
};

export async function logStudioDownload(
    studioEdition: 'Enterprise Cloud' | 'Enterprise Trial' | 'Community Edition' | 'Enterprise',
    location: string,
    fre = false,
) {
    const sub = await getSub();
    const _ga = Cookies.get('_ga');
    portalTelemetry.trackTrace(
        {
            message: 'Studio Download',
            severityLevel: SeverityLevel.Information,
        },
        {
            _ga,
            sub,
            studioEdition,
            location,
            fre,
        },
    );
}

export async function logFREEnabled() {
    const sub = await getSub();
    const _ga = Cookies.get('_ga');
    portalTelemetry.trackTrace(
        {
            message: 'FRE Enabled',
            severityLevel: SeverityLevel.Information,
        },
        {
            _ga,
            sub,
        },
    );
}

