import { UiPathAuthProvider } from '@uipath/auth-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { getAuthConfiguration } from './configuration/AuthConfiguration.default';
import useAuthCallback from './hooks/Callback';

export const PortalAuthenticationProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    // eslint-disable-next-line ban/ban
    const navigate = useNavigate();
    const onSigninCallback = useAuthCallback();

    return (
        <UiPathAuthProvider
            configuration={getAuthConfiguration()}
            onSigninCallback={onSigninCallback}
            onError={(error) => navigate('/portal_/autherror', { state: error })}
        >
            {children}
        </UiPathAuthProvider>
    );
};
